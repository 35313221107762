<!-- 直播课程 -->
<template>
    <div class="content">
        <div class="titlelabel" style="justify-content:flex-start ">
            <div class="title">本月课表</div>
            <div class="block">
                <el-date-picker v-model="liveYearMouth" @change="changeMouth" type="month" placeholder="选择月">
                </el-date-picker>
            </div>
        </div>
        <div class="videocont">
            <div class="liveitem" v-for="(item, index) in nowmonthlive" :key="index" @click="jumpLiveDetail(item)">
                <div class="themeimg">
                    <img class="viodeimgitem default" :src="imgSrc + item.img">
                    <div class="absolutbox">
                        <div class="livetime">开始时间: {{ item.starttime.slice(11, 16) }}</div>
                        <i class="el-icon-caret-right"></i>
                    </div>
                    <div class="livepricebox">
                        <span class="forfree" v-if="item.text.pricetext == '免费'">{{item.text.pricetext}}</span>
                        <span class="vipprice" v-else>{{item.text.vipprice}}</span>
                    </div>
                </div>
                <div class="videoinfo">
                    <div class="name">{{ item.name }}</div>
                    <div class="teacher" v-show="item.theteacher">讲师：{{ item.theteacher }}</div>
                    <div class="livetime">
                        <el-statistic
                            v-if="item.state == '未开始' && item.countDown > 0"
                            ref="statistic"
                            format="HH:mm:ss"
                           	@finish="reloadWeb()"
                            :value="item.countDown "
                            time-indices
                            :value-style="{
                                'color': '#ff0000',
                                'font-size': '14px',
                                'text-alig': 'left',}">
                            <template slot="prefix">
                                <span style="color: #f00;">倒计时</span>
                            </template>
                        </el-statistic>
                        <span v-else-if="item.state == '未开始' && item.countDown == 0">{{ item.starttime.slice(0,16) }}</span>
                        <span v-else :style="item.state=='进行中'?'color: #f00;':''">{{ item.state }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 最新直播列表
            nowmonthlive: [],
            // 选中月份
            liveYearMouth: '',
        }
    },
    created() {
        const that = this
        // 设置默认值为当月日期
        that.liveYearMouth = new Date()
        that.getWebSiteOnlineLiveList(Math.floor(new Date().getTime()/1000))
    },
    methods: {
        // 跳转直播详情
        jumpLiveDetail(item){
            const that = this
            console.log(item)
            var data = {
                No: item.No,
                saleType: item.saleType,
            }
            // that.$router.push({
            //     path: '/liveDetail/' + that.encrypt(JSON.stringify(data)),
            // })
            that.$router.push({
                path: '/liveDetail/' + item.No,
            })
        },
        // 刷新按钮
        reloadWeb(){
            const that = this
            window.location.reload()
        },
        // 获取本月课表
        getWebSiteOnlineLiveList(YearMouth) {
            const that = this
            var data = {
                type: 'getWebSiteOnlineLiveList',
                version: 1,
                liveYearMouth: YearMouth,
                mouthShowLiveCount: 150,
                mouthShowLiveIndex: 0
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
					var time = that.customDateStringToTimestamp(that.getCurrentDate(2))
					for (let i = 0; i < result.length; i++) {
                        var starttime = that.customDateStringToTimestamp(result[i].starttime)
                        var endtime = that.customDateStringToTimestamp(result[i].endtime)
						if(starttime > time){
                            result[i].state = "未开始"
                            // 还有一天以上才开始
                            if((starttime - time)/1000 > 86400){
                                result[i].countDown = 0
                            }else {
                                result[i].countDown = that.customDateStringToTimestamp(result[i].starttime)
                            }
						}else if(starttime < time && endtime > time){
                            result[i].state = "进行中"
						}else if(starttime < time && endtime < time){
                            result[i].state = "已结束"
						}
						result[i].text = that.videoprice(result[i].salePrice,result[i].saleVipPrice ,result[i].saleIntegral ,result[i].saleVipIntegral)
					}
                    that.nowmonthlive = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 切换月份
        changeMouth(e) {
            const that = this
            console.log(e)
            that.getWebSiteOnlineLiveList(e.getTime() / 1000)
        },
    }
}
</script>

<style lang="scss" scoped>
.videocont {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;

    .liveitem {
        width: 215px;
        margin-right: 23px;
        margin: 0 23px 25px 0;
        border-radius: 5px;
        box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);

        &:nth-child(5n) {
            margin-right: 0;
        }

        &:hover {
            box-shadow: 2px 7px 11px rgb(217, 232, 247);
        }

        .themeimg {
            position: relative;
            border-radius: 5px;
            width: 215px;
            height: 160px;
            overflow: hidden;

            .viodeimgitem {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                object-fit: cover;
            }

            .absolutbox {
                position: absolute;
                display: flex;
                justify-content: space-between;
                align-items: center;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 3px 10px;
                box-sizing: border-box;
                color: #fff;
                font-size: 14px;
                border-radius: 5px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
                cursor: pointer;

                i {
                    font-size: 18px;
                }
            }

            .livepricebox {
                position: absolute;
                right: 0;
                top: -2px;
                font-size: 14px;

                .forfree {
                    padding: 0 3px;
                    color: #6aac29;
                    border-radius: 2px;
                    background: rgba(231, 249, 213, 0.9);
                }

                .vipprice {
                    padding: 0 3px;
                    border-radius: 2px;
                    color: #e09119;
                    background: rgba(255, 243, 226, 0.7);
                }
            }
        }

        .videoinfo {
            padding: 10px;

            .name {
                color: rgba(68, 68, 68, 1);
                font-weight: 600;
                font-size: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .teacher {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #444444;
                font-size: 14px;
                font-weight: 400;
            }

            .livetime {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}
</style>