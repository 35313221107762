<template>
    <div class="content">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/trainVet' }">执兽课程</el-breadcrumb-item>
            <el-breadcrumb-item>学科练习</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="subjectsTitle">
            <div class="title">学科练习</div>
        </div>
        <div class="subjectsList">
            <div class="subjectsItem" v-for="(item, index) in subjectsListData" :key="index">
                <div class="subjectsItemTitle">
                    <span class="title">{{ item.moduleName }}</span>
                    <span class="addBtn" @click="open(item)">展开考点列表<i :class="item.off?'el-icon-arrow-down':'el-icon-arrow-up'"></i></span>
                </div>
                <div class="accuracyBox">
                    <div class="accuracyLeft">
                        <div class="accuracyItem">
                            <div class="number">{{ item.count }}<span>题</span></div>
                            <div class="Value">题目总数</div>
                        </div>
                        <div class="accuracyItem">
                            <div class="number">{{ item.sure_count }}<span>题</span></div>
                            <div class="Value">答对题数</div>
                        </div>
                        <div class="accuracyItem">
                            <div class="number">{{ Math.floor(item.sure_count/item.count*10000)/100 }}<span>%</span></div>
                            <div class="Value">正确率</div>
                        </div>
                    </div>

                    <div class="returnBtn">
                        <div class="btnItem" @click="jumpAnswer(item)">
                            开始练习
                        </div>
                        <div class="btnItem btnItem2">
                            重新开始
                        </div>
                    </div>
                </div>
                <div class="second" v-show="item.off">
                    <div class="secondItem" v-for="(item1, index1) in item.subaggregate" :key="index1">
                        <div class="infoBox">
                            <div class="title">{{ item1.examinationPoint }}</div>
                            <div class="rightBox">
                                正确率: <span>{{ item1.rate}}%</span> | 题目总数: <span>{{ item1.number }}</span>
                            </div>
                        </div>
                        <div class="returnBtn">
                            <div class="btnItem btnItem2" style="margin:0;" @click="jumpTestinCentre(item, item1)">
                                重新开始
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 学科数据
            subjectsListData: []
            
        }
    },
    created(){
        const that = this
        that.getSubjectList()
    },
    methods: {
        // 展开收起子题库
        open(item){
            const that = this
            item.off = !item.off
            if(item.subaggregate.length == 0){
                that.myAnswerReport(item)
            }
        },
        // 跳转答题
        jumpAnswer(item){
            const that = this
            var data = {
                module: '2',
                subjectId: item.moduleNo,
                moduleNo: item.moduleNo,
                moduleName: item.moduleName
            }
            this.$router.push({
                path: "/answer",
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
        // 跳转考点
        jumpTestinCentre(item, item1){
            const that = this
            var data = {
                examinationPoint: item1.examinationPoint,
                record_no: item.record_no,
                number: item1.number
            }
            this.$router.push({
                path: "/testinCentre",
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
        // 获取执兽学科练习科目
        getSubjectList() {
            const that = this
            var data = {
                type: 'getSubjectList',
                module: 2,
            }
            this.get("/3/veterinaryYear", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].off = false
                        result[i].subaggregate = []
                    }
                    that.subjectsListData = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取展开内容
        myAnswerReport(item){
            const that = this
            var data = {
                type: 'myAnswerReport',
                recordNo: item.record_no,
            }
            that.get("/3/veterinaryYear", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    console.log(result)
                    item.subaggregate = result.dataList
                } else {
                    console.log(res.data.message)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .subjectsTitle {
        display: flex;
        align-items: center;
        width: 1170px;
        height: 100px;
        padding-left: 40px;
        margin-top: 10px;
        box-sizing: border-box;
        background-image: url("../../assets/images/subjectsTitle.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .title {
            font-size: 20px;
            color: #884A11;
        }
    }

    .subjectsList {
        margin: 20px 0;
        .subjectsItem {
            overflow: hidden;
            border-radius: 10px;
            margin-bottom: 20px;
            border: 1px solid #D8D8D8;
            .subjectsItemTitle {
                position: relative;
                display: flex;
                align-items: center;
                font-size: 20px;
                padding-left: 20px;
                height: 48px;
                color: #fff;
                background-color: #5c99e5;
                user-select: none;
                .addBtn {
                    position: absolute;
                    right: 40px;
                    font-size: 14px;
                    cursor: pointer;
                    i {
                        font-size: 18px;
                        padding-left: 10px;
                    }
                }
            }
            .accuracyBox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 40px;
                .accuracyLeft {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 30px 0;
                    width: 900px;
                    box-sizing: border-box;
                    .accuracyItem {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        flex: 1;
                        padding: 10px 0;
                        &:nth-child(n+2){
                            &::before {
                                content: "";
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                width: 1px;
                                height: 80px;
                                background-color: #D8D8D8;
                            }
                        }
                        .number {
                            font-size: 32px;
                            span {
                                font-size: 18px;
                            }
                        }
                        .accuracyNum {
                            padding: 5px 0;
                            font-size: 18px;
                            color: #999;
                        }
                        .Value {
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }
            }
            .second {
                padding: 0 40px;
                .secondItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 0;
                    border-top: 1px solid #ccc;
                    .infoBox {
                        font-size: 18px;
                        color: #333;
                        .title {
                            padding-bottom: 8px;
                        }
                        .rightBox {
                            span {
                                color: #5C99E5;
                            }
                        }
                    }
                }
            }
            
            .returnBtn {
                .btnItem {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 140px;
                    height: 40px;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 5px;
                    background-color: #00ce64;
                    cursor: pointer;
                }
                .btnItem2 {
                    margin-top: 15px;
                    color: #000;
                    border: 1px solid #ccc;
                    background-color: #fff;
                }
            }
        }
    }
</style>