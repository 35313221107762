<!-- 宠医医讯 -->
<template>
    <div class="content">
        <div class="title">
           <div class="text">
                {{ title }}
           </div>
           <div class="time">
                <div class="times">
                    {{ Newsdate.slice(0, 10) }}
                </div>
                <div class="number">
                    <i class="el-icon-view"></i>
                    {{ Remarks_dec1 }}
                </div>
           </div>
        </div>
        <div class="details" v-html="details"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 标题
            title: "",
            // 富文本内容
            details: "",
            // 时间
            Newsdate: "",
            // 浏览人数
            Remarks_dec1: 0, 
            // 富文本no
            dataNo: "",
        }
    },
    created(){
        const that = this
        that.dataNo = this.$route.params.id
        that.getDataInfo()
    },
    methods: {
        getDataInfo(){
            const that = this
            var data = {
                type: 'getDataInfo',
                dataNo: that.dataNo,
                dataType: 3,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.title = result.NewsTitle
                    that.Newsdate = result.Newsdate
                    that.Remarks_dec1 = result.Remarks_dec1
                    that.details = result.NewstInfo.replace(
							/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
								return `<img src='${ p1.indexOf('http') > -1 ? p1 : that.imgSrc + p1}'  style='max-width: 100%;'/>`
							})
                            
                } 
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0;
    background-color: #f5f5f5;
}
.title {
    padding: 1rem 10px;
    margin: 0 15%;
    color: #444;
    background: #fff;
    .text {
        font-weight: bold;
        font-size: 2rem;
    }
    .time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #999;
        padding: 10px 10px 0;
    }
}
.details {
    margin: 0 15%;
    background-color: #fff;
}
</style>