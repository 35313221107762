<template>
	<div id="app">
		<el-container class="container">
			<el-backtop :bottom="200"></el-backtop>
			<el-header class="headerBox" v-show="showAi">
				<headBox @loginBoxShow="loginBoxShow" @loginOut="loginOut" :loginInfo="loginInfo"></headBox>
			</el-header>
			<el-main class="mainBox" @loginOut="loginOut" >
				<router-view ref="mainBox"></router-view>
			</el-main>
			<el-footer class="footerBox" v-show="showAi">
				<footerBox></footerBox>
			</el-footer>
		</el-container>

		<el-dialog :visible.sync="dialogVisible" 
			width="400px" 
			:before-close="handleClose" 
			:close-on-click-modal="false"
			title="欢迎登录宠医客"
			:destroy-on-close="true"
			:show-close="showClose"
			>
			<el-form ref="form" :model="form" label-width="0">
				<el-form-item >
					<el-input type="number" v-model="form.phone" placeholder="请输入账号" :clearable="true"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input type="password" v-model="form.password" placeholder="请输入密码" :show-password="true"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button style="width: 100%;" type="primary" @click="onSubmit">登录</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import wxlogin from './views/wxlogin.vue'
import headBox from './views/header.vue'
import footerBox from './views/footer.vue'
export default {
	name: 'App',
	components: {
		wxlogin,
		headBox,
		footerBox
	},
	// 监听,当路由发生变化的时候执行
	data() {
		return {
			// 登录框显示
			dialogVisible: false,
			// 登录框关闭按钮
			showClose: true,
			// 用户信息
			loginInfo: {
				name: "请登录",
            	HeadPortrait: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				isLogin: false,
				isVIP: false
			},
			// 登录参数
			form: {
				// phone: "15551297670",
				// password: "xc001120",
				phone: "",
				password: ""
			},
			// 在Ai页隐藏头部底部
			showAi: true
		}
	},
	created() {
		const that = this
		that.forLoginShow()
		// 已经有登录信息则直接取
		if(that.Cookies.get("loginInfo")){
			that.loginInfo = JSON.parse(that.Cookies.get("loginInfo"))
		}
		// AI页面隐藏头部底部
		if(this.$route.fullPath.indexOf('/webAi') > -1){
			this.showAi = false
		}else {
			this.showAi = true
		}
		if (this.$route.query.code) {
			this.loginForWX()
		}
	},
	watch:{
		'$route'(to, from) {
			// 路由变化时执行的代码
			if(to.path.indexOf('/webAi') > -1){
				this.showAi = false
			}else {
				this.showAi = true
			}
		}
	},
	methods: {
		forLoginShow(){
			if(this.Cookies.get('loginShow')){
				this.showClose = false
				this.loginBoxShow(1)
            	this.Cookies.remove("loginShow")
			}
			setTimeout(() => {
				this.forLoginShow()
			}, 500);
		},
		// 点击登录
		onSubmit() {
			const that = this
			if(that.form.phone.trim() == "" || that.form.password.trim() == ""){
				that.$message({
					showClose: true,
					message: "请输入账号及密码",
					type: 'error'
				});
				return false
			}
            var data = { 
                type: 'loginForPassword',
                phone: that.form.phone,
                password: this.hex_md5(that.form.password)
            }
            this.get("/4/login",data).then((res)=>{
                if(res.data.code == "success"){
                    var result = res.data.result
					var data = {
						HeadPortrait: "https://case.chongyike.com" + result.HeadPortrait,
						name: result.name,
						No: result.No,
						isLogin: true,
						IMID: result.IMID,
						isVIP: that.timePK(result.vipTime),
					}
					that.loginInfo = data
					that.Cookies.set('loginInfo', JSON.stringify(data), {  expires: 7 });
					that.Cookies.set('token', that.decrypt(res.data.message), {  expires: 7 });
					that.loginBoxShow(0)
					that.$message({
						showClose: true,
						message: "登录成功",
						type: 'success'
					});
					setTimeout(() => {
						// that.$router.go(0)
                		window.location.reload()
					}, 500);
                }else {
					this.$message({
						showClose: true,
						message: res.data.message,
						type: 'warning'
					});
                }
            })
		},
        // 登出
        loginOut(){
            const that = this
            // 重置默认信息
            that.loginInfo = {
                HeadPortrait: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                name: "请登录",
                isLogin: false
            }
            that.Cookies.remove("loginInfo")
            that.Cookies.remove("token")
            that.$message({
                message: "退出成功",
                type: 'success'
            });
            // 跳回首页
            if(this.$route.path != "/home"){
                that.$router.push({
                    path: "/home",
                })
            }
        },
		// 控制登录框显示
		loginBoxShow(type){
			const that = this
			if(type == 0){
				that.dialogVisible = false
			}else if(type == 1){
				that.dialogVisible = true
			}
		},
		// 关闭登录框
		handleClose(done) {
			done();
		},
		// 日期对比
		timePK(time){
			const that = this
			const date1 = new Date().getTime();
			const date2 = new Date(time).getTime();
			// true日期未到  false日期已过
			return date1 < date2
		},

		// ============================================== 暂时不用的方法 ==============================================
		// 微信登录
		getQueryString(name) {
			this.$router.push({ path: '/wxlogin', query: { href: window.document.location.href } })
		},
		//获取用户信息
		loginForWX() {
			const that = this
			var data = {
				params: {
					type: "loginForWX",
					wxCode: this.$route.query.code
				}
			}
			that.service.get('/3/login', data).then(res => {
				console.log(res)
			})
		},
		// 绑定/注册用户
		senderShortMessageCode() {
			const that = this
			var data = {
				params: {
					type: "senderShortMessageCode",
					shortMsgType: 1,
					phone: "15544553311"
				}
			}
			that.service.get('', data).then(res => {
				console.log(res)
			})
		},
	}
}
</script>

<style lang="scss">
* {
}
#app {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin: 0;
	overflow-x: hidden;
	// overflow-y: scroll;

	.container {
		width: 100vw;
	}

	.headerBox {
		width: 1170px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		height: 80px !important;
	}

	.footerBox {
		width: 100%;
		height: auto !important;
		background: #F9FAFF;

	}

	.mainBox {
		padding: 0;
	}
	.content {
		width: 1170px;
		margin: 0 auto;
	}
 
	// 输入框不显示加减按钮
	input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	input[type="number"]{
		-moz-appearance: textfield;
	}
}

// 修改滚动条样式
::-webkit-scrollbar {
	width: 15px; /* 垂直滚动条的宽度 */
	height: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的背景颜色 */
  border-radius: 10px; /* 滚动条轨道的圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条滑块的颜色 */
  border-radius: 10px; /* 滚动条滑块的圆角 */
}

/* 鼠标悬停在滑块上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 鼠标悬停时的颜色 */
}


// 倒计时模块
.con {
	justify-content: start !important;
	text-align: left !important;
}

.default {
	background-image: url(./assets/images/default.jpg);
	background-size: 130%;
	background-position: center center;
	background-repeat: no-repeat;
}

.content {
    border-top: 1px solid #ccc;
    padding: 20px 0;
}
.titlelabel {
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;

	.title {
		color: #444;
		font-weight: 600;
		font-size: 1.6rem;
		margin-right: 20px;
	}

	.moreclass {
		font-size: 14px;
		color: #666666;
		cursor: pointer;
	}
}



.el-carousel__container {
	height: 17vw !important;

}

.prism-player {
	// height: 500px !important;
}


li {
	padding-left: 20px;
	list-style: none;
	line-height: 25px;
}
</style>
