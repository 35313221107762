<!-- 线上讲师库 -->
<template>
    <div class="content">
        <el-input
            style="width: 300px; margin-bottom:30px;"
            placeholder="请输入搜索内容"
            v-model="lecturerName">
            <el-button slot="append" icon="el-icon-search" @click="getWebSiteLectureList"></el-button>
        </el-input>
        <div class="teacherlist">
            <div class="teacheritem" v-for="(item, index) in lectureList" :key="index" @click="jumplecturerDetail(item)">
                <div class="teacherimg">
                    <img class="headimg" :src="item.head_portrait? imgSrc + item.head_portrait:require('../../assets/images/user.png')">
                </div>
                <div class="teacherinfo">
                    <div class="name">{{ item.name }}<span v-show="item.inauguration_hospital">-{{ item.inauguration_hospital }}</span> </div>
                    <div class="description">
                        <p v-if="item.expertise">{{ '擅长' + item.expertise }}</p>
                    </div>
                    <p class="more">查看更多
                    <i class="el-icon-caret-right"></i></p>
                </div>
                <div class="introduce">{{ item.introduce_text }}</div>
            </div>
        </div>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="16"
            style="margin: 20px 0;"
            @current-change="changeBtn">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 讲师列表
            lectureList: [],
            // 总条数
            total: 0,
            // 页码
            index: 0,
            // 搜索框值
            lecturerName: "",
        }
    },
    created(){
        const that = this
        that.getWebSiteLectureList()
    },
    methods: {
        // 跳转讲师详情
        jumplecturerDetail(item){
            const that = this
            that.$router.push({
                path: '/lecturerDetail/' + item.lecturer_id,
            })
        },
        // 获取讲师
        getWebSiteLectureList(){
            const that = this
            var data = {
                type: 'getWebSiteLectureList',
                index: that.index,
                count: 16,
                lecturerName: that.lecturerName,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.total = res.data.count
                    that.lectureList = []
                    that.lectureList = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 翻页
        changeBtn(e){
            const that = this
            that.index = e-1
            that.getWebSiteLectureList()
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    .teacherlist {
        display: flex;
        flex-wrap: wrap;
        .teacheritem {
            position: relative;
            width: 23%;
            margin-right: 2.666%;
            background: #fff;
            display: flex;
            flex-direction: column;
            -ms-align-items: center;
            align-items: center;
            text-align: center;
            box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
            cursor: pointer;
            border: 1px solid #fff;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
            border-radius: 4px;
            box-sizing: border-box;
            &:nth-child(4n) {
                margin-right: 0;
            }
            &:hover {
                border: 1px solid #1f6eaf;
            }
            .teacherimg {
                width: 5.2rem;
                height: 5.2rem;
                border-radius: 50%;
                margin: 1rem auto 0.5rem;
                .headimg {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .teacherinfo {
                width: 100%;
                color: #444;
                padding: 0 10px;
                box-sizing: border-box;
                .name {
                    width: 100%;
                    font-weight: bold;
                    font-size: 1.2rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 0.25rem;
                }
                .description {
                    text-align: left;
                    font-size: 0.875rem;
                    margin: 0 0 0.2rem 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                .more {
                    display: flex;
                    -ms-align-items: center;
                    align-items: center;
                    justify-content: flex-end;
                    margin-bottom: 0.5rem;
                    text-align: right;
                    font-size: 14px;
                }
            }
            .introduce {
                position: absolute;
                background: #fff;
                left: 0;
                top: 0;
                width: 100%;
                z-index: 9;
                padding: 15px;
                border-radius: 4px;
                height: 100%;
                overflow: hidden;
                display: none;
            }
        }
    }
}

</style>