<!-- 公共底部组件 -->
<template>
    <div class="btmBox">
        <div class="leftBox">
            <div class="phonename">联系电话</div>
            <div class="mailval">+86 21 60515516</div>
            <div class="mailname">联系邮箱</div>
            <div class="mailval">service@ivetsmart.com</div>
            <div class="mailname">公司地址</div>
            <div class="mailval">上海市徐汇区漕宝路66号光大会展中心B座2702室</div>
            <div class="wechat"><span class="icon iconfont wechaticon"></span>
                <a style="font-size: 18px;" target="_black"
                    href="https://android.myapp.com/myapp/detail.htm?apkName=com.janlent.ytb&amp;ADTAG=mobile"><span
                        class="icon iconfont"></span>下载宠医客APP</a><img class="code" style="display: none"
                    src="/images/erw.jpg" alt="宠医客" width="100px">
            </div>
            <div class="beian" style="font-size: 16px;margin-top: 15px">
                <!-- <a target="_black" href="http://beian.miit.gov.cn">
                    沪ICP备15043213号-1</a> -->
                <a target="_black" href="http://beian.miit.gov.cn"> 沪ICP备15043213号-1</a>
            </div>
        </div>
        <div class="rightBox">
            <div class="footmenuitem">
                <div class="bigmenu">关于我们</div>
                <div class="smallmenu"><a target="_black" href="https://mp.weixin.qq.com/s/8R5yfBFAWj41BuhVo9fs2w">关于宠医客</a>
                </div>
                <div class="smallmenu"><a target="_black" href="https://www.chongyike.com/staticpage/copyright/052617223503.html">版权申明</a></div>
                <div class="smallmenu"><a target="_black"
                        href="https://www.chongyike.com/staticpage/service/service.html">服务条款</a>
                </div>
            </div>
            <div class="footmenuitem">
                <div class="bigmenu">帮助中心</div>
                <div class="smallmenu"><a target="_black" href="https://www.chongyike.com//staticpage/guide/052617125912.html">新手指南</a>
                </div>
                <div class="smallmenu"><a target="_black" href="https://www.chongyike.com//staticpage/question/052617232506.html">常见问题</a></div>
            </div>
            <div class="footmenuitem">
                <div class="bigmenu">申请成为讲师</div>
                <div class="smallmenu"><a target="_black" href="https://www.chongyike.com//staticpage/applyfor/052617234253.html">申请流程</a></div>
            </div>
            <div class="footmenuitem">
                <div class="bigmenu">友情链接</div>
                <div class="smallmenu"><a target="_black" href="https://wsava.org/">WSAVA</a></div>
                <div class="smallmenu"><a target="_black" href="http://www.cvma.org.cn/syxh/index.html">中国兽医协会</a></div>
            </div>
            <div class="tuiguanghezuo">
                <a target="_black" href="https://mp.weixin.qq.com/s/s9MZARRARxMj4XkqZHf2Yw">
                    <p class="hezuomingcheng">商务合作：</p>
                    <p class="smaslltext">品牌与产品推广</p>
                    <p class="smaslltext">定制化营销活动</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.beian,
.wechat {
    color: #333;
}

.btmBox {
    display: flex;
    align-items: center;
    width: 1140px;
    height: 302px;
    margin: 0 auto;
    padding: 60px 0;
    font-size: 19px;
    color: #71777B;

    .leftBox {
        width: 25%;
        padding-right: 32px;
        border-right: 1px solid #dedede;
        box-sizing: border-box;

        div {
            line-height: 25px;
        }

        .mailval {
            margin-bottom: 25px;
        }
    }

    .rightBox {
        position: relative;
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 100px;
        box-sizing: border-box;

        .footmenuitem {
            height: 302px;

            .bigmenu {
                margin-bottom: 30px;
            }

            .smallmenu {
                font-size: 14px;
            }
        }

        .tuiguanghezuo {
            position: absolute;
            bottom: 20px;

            .hezuomingcheng {
                margin-bottom: 10px;
            }

            .smaslltext {
                font-size: 14px;
            }
        }
    }
}
</style>