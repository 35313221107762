<template>
    <div class="content">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/trainVet' }">执兽课程</el-breadcrumb-item>
            <el-breadcrumb-item>我的错题</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="WebTitle">
            <div class="title">我的错题</div>
        </div>
        <div class="topicTitle">
            按科目做题
            <span class="addBtn" @click="subjectOff = !subjectOff"><i :class="subjectOff?'el-icon-arrow-down':'el-icon-arrow-up'"></i></span>
        </div>
        <div class="topicListBox">            
            <div class="topicListItem" @click="jumpMyMistakeAnswer(item)" v-for="(item, index) in subjectList" :key="index" v-show="subjectOff || index < 4">
                <div class="topBox">
                    <img class="rightIcon" src="../../assets/images/error.png" alt="" />
                    {{ item.subject_name }}
                </div>
                <div class="number">
                    共{{ item.count }}道题目
                </div>
            </div>
        </div>
        <div class="topicTitle">
            按年份做题
            <span class="addBtn" @click="yearOff = !yearOff"><i :class="yearOff?'el-icon-arrow-down':'el-icon-arrow-up'"></i></span>
        </div>
        <div class="topicListBox">
            <div class="topicListItem" @click="jumpMyMistakeAnswer(item)" v-for="(item, index) in yearList" :key="index" v-show="yearOff || index < 4">
                <div class="topBox">
                    <img class="rightIcon" src="../../assets/images/error.png" alt="" />
                    {{ item.source }}
                </div>
                <div class="number">
                    共{{ item.count }}道题目
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 科目列表
            subjectList: [],
            // 展开收起科目列表
            subjectOff: false,
            // 年份列表
            yearList: [],
            // 展开收起年份列表
            yearOff: false
        }
    },
    created(){
        const that = this
        that.mySubjectWrongRecord()
    },
    methods: {
        // 获取我的错题
        mySubjectWrongRecord(){
            const that = this
            var data = {
                type: 'mySubjectWrongRecord',
            }
            this.get("/3/veterinaryYear", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.subjectList = result.subjectList
                    that.yearList = result.yearList
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 跳转答题
        jumpMyMistakeAnswer(item){
            const that = this
            console.log(item)
            var data = {
                count: item.count,
                subjectId: item.subject_id,
                subjectName: item.subject_name,
            }
            this.$router.push({
                path: "/myMistakeAnswer",
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .content {
        .WebTitle {
            color: #1F6EAF;
            font-size: 24px;
            padding-top: 10px;
        }
        .topicTitle {
            position: relative;
            font-size: 18px;
            color: #444;
            margin: 15px 0;
            display: flex;
            align-items: center;
            .addBtn {
                position: absolute;
                right: 20px;
                font-size: 24px;
                cursor: pointer;
            }
        }
        .topicListBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .topicListItem {
                width: 570px;
                height: 116px;
                padding: 10px 30px;
                border: 1px solid #ccc;
                border-radius: 10px;
                margin-bottom: 25px;
                box-sizing: border-box;
                box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                .topBox {
                    display: flex;
                    align-items: center;
                    padding: 10px 0 14px;
                    border-bottom: 1px solid #ccc;
                    .rightIcon {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
                .number {
                    padding-top: 14px;
                    font-size: 14px;
                    color: #9E9E9E;
                }
            }
        }
    }
</style>