import axios from "axios";
import hex_md5 from "../assets/js/md5"
import Cookies from 'js-cookie'
import router from "../router";
import { Loading } from 'element-ui';
import ElementUI from 'element-ui';
    var baseURL = "https://appinterface.chongyike.com"
    //  baseURL = "http://192.168.18.5:8080"
    // baseURL = "http://192.168.18.223:83"


// get请求
export function get (url,params,LoadingOff) {
    // LoadingOff==1为不显示loading
    if(LoadingOff != 1){
        Loading.service();
    }
    var data = {...params, ...getConfigText()}
	return new Promise((resolve, reject) => {
        const http = axios.create({
            baseURL: baseURL,
            timeout: 10000,
            headers: {
                Authorization: Cookies.get('token')!='undefined'? 'Bearer ' + Cookies.get('token'):''
            },
        })
        http.get(url, {params: data}).then((res) => {
                if(res.data.code == "expires"){
                    var result = res.data.result
                    // router.push({
                    //     path: '/home',
                    // })
                    Cookies.set('loginShow', 1)
                }else if(res.data.code == "error"){
                    var result = res.data.result
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'error',
                        duration: 2000
                    });
                }else {
                    resolve(res)
                }
                if(LoadingOff != 1){
                    Loading.service().close();
                }
            }
        ) .catch(error => {
            if(LoadingOff != 1){
                Loading.service().close();
            }
            if (error.response) {
              // 服务器返回错误响应
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // 没有收到服务器响应
              console.log(error.request);
            } else {
              // 其他错误
              console.log('Error', error.message);
            }
            console.log(error.config);
            // 显示提示信息
            this.$message({
                showClose: true,
                message: '网络错误，请稍后再试！',
                type: 'error',
                duration: 2000
            });
          });
	})
}
// post请求
export function post (url,params, data2) {
    Loading.service();
    var data = {...params, ...getConfigText()}
    var texts = ""
    var obj = data
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            texts +=  key + "=" + obj[key]  + "&"
        }
    }
    var jumpUrl = url + '?' + texts
	return new Promise((resolve, reject) => {
        const http = axios.create({
            baseURL: baseURL,
            timeout: 10000,
            headers: {
                Authorization: Cookies.get('token')!='undefined'? 'Bearer ' + Cookies.get('token'):''
            },
        })
        http.post(jumpUrl, data2).then((res) => {
                if(res.data.code == "expires"){
                    var result = res.data.result
                    Cookies.set('loginShow', 1)
                }else {
                    Loading.service().close();
                    resolve(res)
                }
            }
        ) .catch(error => {
            if (error.response) {
              // 服务器返回错误响应
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // 没有收到服务器响应
              console.log(error.request);
            } else {
              // 其他错误
              console.log('Error', error.message);
            }
            console.log(error.config);
            // 显示提示信息
            this.$message({
                showClose: true,
                message: '网络错误，请稍后再试！',
                type: 'error',
                duration: 2000
            });
          });
	})
}


// 生成随机数
function num() {
	let mm = Math.random();
	let six = '';
	if (mm < 0.1) {
		mm += 0.1;
	}
	six = Math.round(mm * 1000000);
	return Number(six.toString().padStart(6, '0'));
}

// 官网公共参数
function getConfigText(){
	// 获取当前时间
	var time = (new Date()).getTime();
    var data = {
        // 接口来源 安卓/IOS/WEB
        client: "WEB",
        // 版本号
        device: "",
        // 时间戳
        requestTime: time,
        // 版本号
        versionCode: 251,
    }
    if(Cookies.get('device')){
        data.device = Cookies.get('device')
    }else {
        var lsnms = num();
        var strs = time + "" + lsnms;
        var device = hex_md5(strs).toUpperCase();
        Cookies.set('device', device, {  expires: 7 })
        data.device = device
    }
    data.sign = serSign(data)
    return data
}

// 创建sign参数
function serSign(data){
    var text = ""
    for (let key in data) {
        text = text + key + '=' + data[key] + "&"
    }
    return hex_md5(text + 'key=huiyichongyike')
}
