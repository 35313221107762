<template>
    <div class="content">
        <div class="WebTitle">
            <div class="title">图文题型</div>
        </div>
        <div class="topicListBox">
            <div class="topicListItem" v-for="(item, index) in subjectList" :key="index" @click="jumpPictureQuestionAnswer(item)">
                <div class="topBox">
                    <img class="rightIcon" src="../../assets/images/setGradeStudyIcon.png" alt="" />
                    {{ item.subject_name }}
                </div>
                <div class="number">
                    已完成{{ item.mycount }}/{{ item.count }} ｜ 正确率{{ Math.ceil(item.sure_rate*10000)/100 }}%
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 科目列表
            subjectList: [],
        }
    },
    created(){
        const that = this
        that.getImgQuestionBankSubject()
    },
    methods: {
        getImgQuestionBankSubject(){
            const that = this
            var data = {
                type: 'getImgQuestionBankSubject',
                questionType: 0
            }
            this.get("/veterinary", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    console.log(result)
                    that.subjectList = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 跳转答题
        jumpPictureQuestionAnswer(item){
            const that = this
            console.log(item)
            var data = {
                subjectId: item.subject_id,
                subjectName: item.subject_name,
            }
            this.$router.push({
                path: "/pictureQuestionAnswer",
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .content {
        .WebTitle {
            color: #1F6EAF;
            font-size: 24px;
        }
        .topicTitle {
            font-size: 18px;
            color: #444;
            margin: 15px 0;
        }
        .topicListBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 20px;
            .topicListItem {
                width: 570px;
                height: 116px;
                padding: 10px 30px;
                border: 1px solid #ccc;
                border-radius: 10px;
                margin-bottom: 25px;
                box-sizing: border-box;
                box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                .topBox {
                    display: flex;
                    align-items: center;
                    padding: 10px 0 14px;
                    border-bottom: 1px solid #ccc;
                    .rightIcon {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
                .number {
                    padding-top: 14px;
                    font-size: 14px;
                    color: #9E9E9E;
                }
            }
        }
    }
</style>