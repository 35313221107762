<!-- 首页 -->
<template>
	<div class="box">
		<!-- <el-carousel class="lunbo" trigger="click" :autoplay="true" :interval="6000">
			<el-carousel-item class="bannerItem" v-for="item in 1" :key="item">
				<div class="bannerBox">
					<img class="bannerImg" :src="require('../assets/images/banner0' + (item) + '.png')" alt="">
					<img class="pos" @click="jumpWeb(item)" src="https://case.chongyike.com/upload/detailImages/2024-01/96B2469AC9F0B6D764C7D5A4D23E952D_compress.png" alt="">
				</div>
			</el-carousel-item>
		</el-carousel> -->

		<!-- <div class="publicityList">
			<div class="publicityItem">
				<img class="icon" src="../assets/images/icon001.png" alt="">
				<div class="publicityInfo">
					<div class="title">
						多
					</div>
					<div class="text">
						1400+小时线上课;<br>
						8000+兽医文献;<br>
						16个医学计算器<br>
					</div>
				</div>
			</div>
			<div class="publicityItem">
				<img class="icon" src="../assets/images/icon002.png" alt="">
				<div class="publicityInfo">
					<div class="title">
						快
					</div>
					<div class="text">
						每月18节以上;<br>
						海外专家新课;<br>
						前沿知识传播快<br>
					</div>
				</div>
			</div>
			<div class="publicityItem">
				<img class="icon" src="../assets/images/icon003.png" alt="">
				<div class="publicityInfo">
					<div class="title">
						好
					</div>
					<div class="text">
						手机电脑均可学习;<br>
						与大咖讨论病例;<br>
						即学即用<br>
					</div>
				</div>
			</div>
			<div class="publicityItem">
				<img class="icon" src="../assets/images/icon004.png" alt="">
				<div class="publicityInfo">
					<div class="title">
						省
					</div>
					<div class="text">
						加入会员;<br>
						海量好课任性学;<br>
						省时间,省差旅<br>
					</div>
				</div>
			</div>
			<div class="publicityItem">
				<img class="icon" src="../assets/images/icon005.png" alt="">
				<div class="publicityInfo">
					<div class="title">
						体系化
					</div>
					<div class="text">
						专家设计课程体系;<br>
						线上学习证书课;<br>
						ISVPS认证国际专科证书课<br>
					</div>
				</div>
			</div>
		</div> -->
		<div class="videolivenews" style="padding-top: 20px;border-top: 1px solid #ccc;">
			<div class="videoandlive">
				<div class="AIbannerBox">
					<el-carousel class="lunbo" trigger="click" :autoplay="true" :interval="6000">
						<el-carousel-item class="bannerItem" v-for="(item, index) in adDataList" :key="index">
							<img class="posImg" @click="jumpWeb(item)" :src="imgSrc + item.wx_ad_img" alt="">
						</el-carousel-item>
					</el-carousel>
					<!-- <img class="bannerImg" src="../assets/images/homeBanner.png" alt=""> -->
				</div>
				<!-- 最新直播 -->
				<div class="title"><span class="name">最新直播</span>
					<div class="more morevideo" @click="jumpRouter('/livelist')">更多直播</div>
				</div>
				<div class="livecont" id="newlive">
					<div class="liveitem" v-for="(item, index) in liveList" :key="index" @click="jumpLiveDetail(item)">
						<div class="themeimg">
							<img class="viodeimgitem" :src="imgSrc + item.img">
							<div class="absolutbox">
								<div class="livetime">开始时间: {{ item.starttime.slice(11, 16) }}</div>
								<i class="el-icon-caret-right"></i>
							</div>
							<div class="livepricebox">
								<span class="forfree" v-if="item.text.pricetext == '免费'">{{item.text.pricetext}}</span>
								<span class="vipprice" v-else>{{item.text.vipprice}}</span>
							</div>
						</div>
						<div class="videoinfo">
							<div class="name">{{ item.name }}</div>
							<div class="teacher" v-show="item.theteacher">讲师：{{ item.theteacher }}</div>
							<div class="livetime">
								<el-statistic
									v-if="item.state == '未开始' && item.countDown > 0"
									ref="statistic"
									format="HH:mm:ss"
									@finish="reloadWeb()"
									:value="item.countDown "
									time-indices
									:value-style="{
										'color': '#ff0000',
										'font-size': '14px',
										'text-alig': 'left',}">
									<template slot="prefix">
										<span style="color: #f00;">倒计时</span>
									</template>
								</el-statistic>
								<span v-else-if="item.state == '未开始' && item.countDown == 0">{{ item.starttime.slice(0,16) }}</span>
								<span v-else :style="item.state=='进行中'?'color: #f00;':''">{{ item.state }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="newsbox">
				<div class="AiBox">
					<img class="AiTitle" src="../assets/images/homeAiTitle.png" alt="">
					<div class="inputBox">
						<input type="text" placeholder="专业问题，问AI" v-model="question">
						<img class="searchBtn" src="../assets/images/sendBtn.png" alt="" @click="jumpWebAi({question:question})">
					</div>
					<div class="hotQuestionList">
						<div class="hotQuestionListTitle">
							试试这些问题
							<div class="refreshBtn"  @click="getHotQuestion()">
								<img class="refreshIcon" src="../assets/images/refreshIcon.png" alt="">
								换一换
							</div>
						</div>
						<div class="hotQuestionItem" v-for="(item, index) in hotQuestionDataList" :key="index" @click="jumpWebAi(item)">
							{{ item.question }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="videolivenews">
			<div class="videoandlive">
				<!-- 最新视频 -->
				<div class="title"><span class="name">最新视频</span>
					<div class="more morevideo" @click="jumpRouter('/videolist')">更多视频</div>
				</div>
				<div class="livecont videocont">
					<div class="liveitem" v-for="(item, index) in videoList" :key="index" @click="jumpNewVideoDetail(item)">
						<div class="themeimg">
							<img class="viodeimgitem" :src="imgSrc + item.img">
							<div class="absolutbox">
								<div class="livetime">{{ item.playCount }}次</div>
								<i class="el-icon-caret-right"></i>
							</div>
							<div class="livepricebox">
								<span class="forfree" v-if="item.text.pricetext == '免费'">{{item.text.pricetext}}</span>
								<span class="vipprice" v-else>{{item.text.vipprice}}</span>
							</div>
						</div>
						<div class="videoinfo">
							<div class="name">{{ item.name }}</div>
							<div class="price">
								<span>{{ item.text.pricetext }}</span>
								<span class="vipprice" v-show="item.text.vipprice">{{ item.text.vipprice }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="newsbox">
				<div class="title"><span class="name">宠医资讯</span>
					<div class="more morevideo" @click="jumpMedicalNews()">更多医讯</div>
				</div>
				<div class="newslabel">
					<div class="newsitem" v-for="(item, index) in newsList" :key="index"
						v-show="index < 2" @click="jumpPostDetail(item)">
						<div class="newstime">
							<div class="day">{{ item.date }}</div>
							<div class="yearandmounth">{{ item.year + '-' + item.month }}</div>
						</div>
						<div class="newsinfo">
							<div class="newstitle">{{ item.NewsTitle }}</div>
							<div class="preview">
								<i class="el-icon-view"></i>
								<span class="num">{{ item.Remarks_dec1 }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="GradeStudyBox">
			<div class="title"><span class="name">分级学习</span></div>
			<div class="GradeStudyList1">
				<div class="GradeStudyItem" v-for="(item, index) in VipList" :key="index"
					@click="jumpRouter('/gradeStudy/' + (index + 1))">
					<div class="topBox">
						<img :src="imgSrc + item.vip_img" alt="" class="gradeIcon">
					</div>
					<div class="btmBox">
						<div class="vip_name">
							{{ item.vip_name }}
						</div>
						<div class="texts">
							{{ item.vip_lab_name }} 会员免费 {{ item.vip_money }}元/年
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="container-fluid" style="background: #fafbfc">
			<div class="container">
				<div class="row">
					<div class="specialcousebox">
						<!-- 线上证书课 -->
						<div class="specialcouselabel">
							<div class="couseimg couse02">
								<div class="couseTitle">线上证书课</div>
								<div class="more" @click="jumpOnlineTraining()">
									<div class="button">更多证书课</div>
								</div>
								<div class="couseicon">
									<img src="../assets/images/couse02.png" alt="线上证书课">
								</div>
							</div>
							<div class="couseinfo" id="onlinecouse">
								<div class="couseitem" data-no="93" v-for="(item, index) in onlinecouse" :key="index" @click="jumpOnlineTrainingDetail(item)">
									<div class="themeimg">
										<img class="viodeimgitem" :src="imgSrc + item.image">
									</div>
									<div class="videoinfo">
										<div class="name">{{ item.title }}</div>
										<div class="teacher">讲师：{{ item.teachers }}</div>
										<div class="zsprice" v-if="item.standard_price > 0">
											<!-- <span class="icon iconfont"></span> -->
											<span>金额：{{ item.standard_price }}</span>
											<span class="vipprice" v-if="item.member_price > 0">会员价：{{ item.member_price
											}}</span>
											<span class="vipprice" v-else>会员免费</span>
										</div>
										<div class="zsprice" v-else>
											<!-- <span class="icon iconfont"></span> -->
											<span>{{ item.standard_points }}积分</span>
											<span class="vipprice" v-if="item.member_points > 0">会员价：{{ item.member_points
											}}积分</span>
											<span class="vipprice" v-else>会员免费</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 国际证书课 -->
						<div class="specialcouselabel">
							<div class="couseimg couse03">
								<div class="couseTitle">国际证书课</div>
								<div class="more" @click="jumpOfflineTraining()">
									<div class="button">更多证书课</div>
								</div>
								<div class="couseicon">
									<img src="../assets/images/couse03.png" alt="线下证书课">
								</div>
							</div>
							<div class="couseinfo onlinecouselabel" id="couseinfoList">
								<div class="couseitem" v-for="(item, index) in couseinfoList"
									:key="index" v-show="index < 4" @click="jumpDetail(item)">
									<div class="themeimg">
										<img class="viodeimgitem" :src="imgSrc + item.trainingImage">
									</div>
									<div class="videoinfo">
										<div class="name">{{ item.trainingName }}</div>
										<div class="teacher">讲师：{{ item.teachers }}</div>
										<div class="livetime">{{ item.trainingStartTime.slice(0, 10) }}-{{
											item.trainingEndTime.slice(0, 10) }}</div>
										<div class="price">价格：{{ item.saleVipPrice }}/人（线上）</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 线下专题课 -->
						<!-- <div class="specialcouselabel">
							<div class="couseimg couse04">
								<div class="title">线下专题课</div>
								<div class="couseicon">
									<img src="../assets/images/couse04.png" alt="线下专题课">
								</div>
							</div>
							<div class="couseinfo onlinecouselabel">
								<div class="couseitem" data-no="TL0000398">
									<div class="themeimg">
									</div>
									<div class="videoinfo">
										<div class="name">宠医客软组织手术超值课程：首个线上学理论，线上纯实操课程</div>
										<div class="teacher">讲师：凌凤俊</div>
										<div class="livetime">2020/11/16-2020/11/17</div>
										<div class="price">价格：3500元/人</div>
									</div>
								</div>
							</div>
						</div> -->
						<!-- 执兽视频 -->
						<div class="specialcouselabel">
							<div class="couseimg couse01">
								<div class="couseTitle">执兽视频</div>
								<div class="more" @click="jumpTrainList()">
									<div class="button">更多视频</div>
								</div>
								<div class="couseicon">
									<img src="../assets/images/couse01.png" alt="执兽视频">
								</div>
							</div>
							<div class="couseinfo" id="holdingbeast">
								<div class="couseitem" v-for="(item, index) in holdingbeast" :key="index" @click="jumpVideoDetail(item)">
									<div class="themeimg">
										<img class="viodeimgitem" :src="imgSrc + item.img">
									</div>
									<div class="videoinfo">
										<div class="name">{{ item.name }}</div>
										<div class="teacher">讲师：{{ item.theteacher }}</div>
										<div class="livetime">
											<span class="forfree">{{ item.text.pricetext }}</span>
											<span class="vipfree" v-show="item.text.vipprice">{{ item.text.vipprice }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bottomBox">
			<div class="studentsSpeak">
				<div class="title"><span class="name">学员们说</span></div>
				<div class="students">
					<div class="studentsItem videoBox">
						<!-- <video width="100%" muted playsinline loop autoplay controlslist="nodownload nofullscreen noremoteplayback">
							<source class="video" src="http://videolist.wenchongyi.com/043d4005927e49b08f97ffebc3c1cedb/3d188dec04844486b7fcb5e165d1a42d-6b8c0168f1a8eb460d4e08384049acee-ld.mp4" type="video/mp4">
						</video> -->
						<!-- <source class="video" src="http://videolist.wenchongyi.com/043d4005927e49b08f97ffebc3c1cedb/3d188dec04844486b7fcb5e165d1a42d-6b8c0168f1a8eb460d4e08384049acee-ld.mp4" type="video/mp4"> -->
						<video class="video" :controls="true" muted playsinline loop src="http://videolist.wenchongyi.com/043d4005927e49b08f97ffebc3c1cedb/3d188dec04844486b7fcb5e165d1a42d-6b8c0168f1a8eb460d4e08384049acee-ld.mp4"></video>
					</div>
					<div class="studentsItem videoBox">
						<video class="video" :controls="true" muted playsinline loop src="https://videolist.wenchongyi.com/3fd78418ce81455ea370f93e02f777f5/767ad232901e4b238b1c7f02304a820e-340b78975a9dba876c8729b0719e1eec-fd.mp4"></video>
					</div>
					<div class="studentsItem">
						<div class="studentText">
							<p>猫科专科国际证书课程让更多学员能够在这个领域得到一个比较系统的培训，学到更多基本知识，从而在临床上能够进行鉴别诊断，通过这个培训能够使自己在遇到问题时有一个比较全面诊断思路，为后续的发展奠定基础。</p>
							<p>同样对于未来，肿瘤专科也是必走的一部，肿瘤专科在建设过程中同样是需要基础、系统的培训，使更多的医生达到专科医生的基本素质、这样才能在条件允许的情况下把中国的专科建设做的更好、更标准！</p>
						</div>
						<div class="studentInfo">
							<div class="studentImg">
								<img src="https://www.chongyike.com/images/teacher01.png" alt="">
							</div>
							<div class="studentNameText">
								<div class="zhName">董军</div>
								<div class="enName">中国农大肿瘤专家</div>
							</div>
						</div>
						<div class="num">3</div>
					</div>
					<div class="studentsItem">
						<div class="studentText">
							<p>专科培训对于提高和引领行业专科技术的发展是很重要的，目前很多医生都致力于兽医专科的提高和发展，国内专科培训目前正在兴起，宠医客作为含金量最高的一个培训具有以下优点：</p>
							<p>同样对于未来，肿瘤专科也是必走的一部，肿瘤专科在建设过程中同样是需要基础、系统的培训，使更多的医生达到专科医生的基本素质、这样才能在条件允许的情况下把中国的专科建设做的更好、更标准！</p>
						</div>
						<div class="studentInfo">
							<div class="studentImg">
								<img src="https://www.chongyike.com/images/teacher02.png" alt="">
							</div>
							<div class="studentNameText">
								<div class="zhName">张拥军</div>
								<div class="enName">瑞派医院专科建设带头人</div>
							</div>
						</div>
						<div class="num">4</div>
					</div>
					<div class="studentsItem">
						<div class="studentText">
							<p>ISVPS最打动我的一点就是它可以帮助我们构建关于整个内科体系的全面完整的一个知识框架、可以对自己的知识进行梳理、查漏补缺，而且在一起学习的学员中，有很多高水平的学员，可以互助交流共同提高。此外，课程具有配套的考试、课前课后测试、最终考试。只有过五关斩六将方可通过拿到证书。考试的机制可以鞭策我们更加努力的学习，同时也说明证书的含金量更高。</p>
						</div>
						<div class="studentInfo">
							<div class="studentImg">
								<img src="https://www.chongyike.com/images/teacher03.png" alt="">
							</div>
							<div class="studentNameText">
								<div class="zhName">董公麟</div>
								<div class="enName">怡嘉动物医院院长</div>
							</div>
						</div>
						<div class="num">5</div>
					</div>
					<div class="studentsItem">
						<div class="studentText">
							<p>一个医院的专科建设是一个比较长期的工程，在开始应该注重医生知识基础框架的构建，为后面的专科建设之路打下基础。宠医客专科证书课程是一个全面系统，可以帮助医生建立鉴别诊断的思路的一个课程，在课前课后都有配套的练习，可以让上课更有目的性，课后更有针对性的进行复习，有益于知识的消化吸收！</p>
						</div>
						<div class="studentInfo">
							<div class="studentImg">
								<img src="https://www.chongyike.com/images/teacher04.png" alt="">
							</div>
							<div class="studentNameText">
								<div class="zhName">刘长轩</div>
								<div class="enName">上海领华动物医院医疗标准负责人</div>
							</div>
						</div>
						<div class="num">6</div>
					</div>
				</div>
			</div>
			<div class="bannerBox">
				<img width="100%" src="../assets/images/bannerhome.png" alt="">
				<div class="missionTextBox">
					<div class="missionTextTitle">我们的使命</div>
					<div class="missionText">帮助每一位希望通过提高诊疗技术给小动物带来健康生活的兽医实现职业飞跃</div>
				</div>
			</div>
		</div>
		<div class="partnerBox" id="qyhz">
			<div class="title"><span class="name">企业合作</span></div>
			<div class="partnerList">
				<HomeSwiper></HomeSwiper>
			</div>
		</div>
	</div>
</template>

<script>
import HomeSwiper from "../components/swiper";
export default {
	components: {
		HomeSwiper,
	},
	data() {
		return {
			imgSrc: "https://case.chongyike.com",
			// 最新直播列表
			liveList: [],
			// 最新视频列表
			videoList: [],
			// 宠医资讯列表
			newsList: [],
			// 分级列表
			VipList: [],
			// 线上证书课
			onlinecouse: [],
			// 国际证书课
			couseinfoList: [],
			// 执兽视频
			holdingbeast: [],
			// ai问题列表
			hotQuestionDataList: [],
			// ai输入框内容
			question: "",
			// 广告列表
			adDataList: []
		}
	},
	created() {
		const that = this
		window.addEventListener("keydown", this.keydownClick)
		that.getWebSiteUpToDateOnlineLiveList()
		that.getWebSiteUpToDateVideoList()
		that.getDoctorMedicalInfo()
		that.getGradeStudyVipList()
		that.getWebSiteOnlineCertificateCourseList()
		that.getRecommendCourse()
		that.getWebSiteUpToDateOccupationVeterinaryList()
		that.getHotQuestion()
		that.getWxAdInfoList()
	},
	mounted() {
		var id = this.$route.query.text
		if(id){
			const position = document.getElementById(id);
			if (position) {
				position.scrollIntoView();
			}
		}
  	},
	methods: {
		// 跳转链接
		jumpWeb(item){
			window.open(item.wx_ad_url)
			// window.open("https://appinterface.chongyike.com/appPage/sharePage/detail/AdvertInfo.html?text=aEhXaxY0+BpPvuypdf+0mIwvzaPD37aXKGWrpyXKNaFAgSf5swq2l93fasESqkjtysH7wKNzHVPGnq1U+HbzOxhps/O0frwqoImSqsSBTAEujdDtsPs5yW01HONNTUE+")
		},
		// 跳转路由
		jumpRouter(router) {
			this.$router.push({
				path: router,
			})
		},
        // 执兽跳转视频详情
        jumpVideoDetail(item){
            const that = this
            var data = {
                dataType: 9,
                No: item.No,
				name: item.name,
                classType: 1,
            }
            that.$router.push({
                path: '/trainVetVideoDetail',
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
        // 跳转直播详情
        jumpLiveDetail(item){
            const that = this
            var data = {
                No: item.No,
                saleType: item.saleType,
            }
            // that.$router.push({
            //     path: '/liveDetail/' + that.encrypt(JSON.stringify(data)),
            // })
            that.$router.push({
                path: '/liveDetail/' + item.No,
            })
        },
		// 跳转ai
		jumpWebAi(item){
			const that = this
			if(item.question.trim() == ''){
				that.$message({
					showClose: true,
					message: "请输入问题",
				});
				return false
			}
            var data = {
                question: item.question,
            }
			sessionStorage.setItem("AiQuestion", JSON.stringify(data))
            that.$router.push({
                path: '/webAi',
            })
		},
		// 最新视频跳转
		jumpNewVideoDetail(item){
            const that = this
            var data = {
                dataType: 0,
                No: item.No,
				name: item.name,
                classType: 1,
            }
            that.$router.push({
                path: '/videoDetail',
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
		},
		
        // 跳转证书课详情页
        jumpDetail(item){
            this.$router.push({
                path: "/detail/" + item.trainingNo,
            })
        },
		// 跳转宠医资讯
        jumpPostDetail(item){
            this.$router.push({
                path: "/postDetail/" + item.No,
            })
        },
		// 跳转宠医资讯
        jumpMedicalNews(){
            this.$router.push({
                path: "/medicalNews",
            })
        },
		// 跳转更多线上证书课
		jumpOnlineTraining(){
            this.$router.push({
                path: "/onlineTraining",
            })
		},
		jumpOnlineTrainingDetail(item){
            this.$router.push({
                path: "/onlineTrainingDetail/" + item.certificate_id,
            })
		},
		// 跳转线下证书课
		jumpOfflineTraining(){
            this.$router.push({
                path: "/offlineTraining",
            })
		},
		// 跳转执兽视频
		jumpTrainList(){
            this.$router.push({
                path: "/trainList",
            })
		},
		// 刷新按钮
		reloadWeb(){
			const that = this
			window.location.reload()
		},
		// 键盘事件
		keydownClick(e){
			const that = this 
			var keyCode = e.keyCode
			if(keyCode == "13" && that.question.trim().length > 0){
				that.jumpWebAi({question:that.question})
			}
		},
		// 获取广告
		getWxAdInfoList(){
			const that = this
			var data = {
				type: 'getWxAdInfoList',
				adTypes: 1,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					console.log(result)
					that.adDataList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 最新直播
		getWebSiteUpToDateOnlineLiveList() {
			const that = this
			var data = {
				type: 'getWebSiteUpToDateOnlineLiveList',
				index: 0,
				showOnlineLiveCount: 3,
				version: 1
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					var time = that.customDateStringToTimestamp(that.getCurrentDate(2))
					for (let i = 0; i < result.length; i++) {
                        var starttime = that.customDateStringToTimestamp(result[i].starttime)
                        var endtime = that.customDateStringToTimestamp(result[i].endtime)
						if(starttime > time){
                            result[i].state = "未开始"
                            // 还有一天以上才开始
                            if((starttime - time)/1000 > 86400){
                                result[i].countDown = 0
                            }else {
                                result[i].countDown = that.customDateStringToTimestamp(result[i].starttime)
                            }
						}else if(starttime < time && endtime > time){
                            result[i].state = "进行中"
						}else if(starttime < time && endtime < time){
                            result[i].state = "已结束"
						}
						result[i].text = that.videoprice(result[i].salePrice,result[i].saleVipPrice ,result[i].saleIntegral ,result[i].saleVipIntegral)
					}
					that.liveList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 获取Ai更多问题
		getHotQuestion(){
			const that = this
			var data = {
				type: 'getHotQuestion',
				questionType: 2
			}
			this.get("/ai/handler", data, 1).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					that.hotQuestionDataList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 最新视频
		getWebSiteUpToDateVideoList() {
			const that = this
			var data = {
				type: 'getWebSiteUpToDateVideoList',
				index: 0,
				showVideoCount: 3,
				version: 1
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					for (let i = 0; i < result.length; i++) {
						result[i].text = that.videoprice(result[i].salePrice,result[i].saleVipPrice ,result[i].saleIntegral ,result[i].saleVipIntegral)
					}
					that.videoList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 获取宠医资讯
		getDoctorMedicalInfo() {
			const that = this
			var data = {
				type: 'getDoctorMedicalInfo',
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				// this.AES(res.data.message)
				if (res.data.code == "success") {
					var result = res.data.result
					for (let i = 0; i < result.length; i++) {
						var s = new Date(result[i].Newsdate.replace(/-/g, '/'));
						result[i].month = s.getMonth() + 1;
						result[i].date = s.getDate();
						result[i].year = s.getUTCFullYear();
					}
					that.newsList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 分级学习
		getGradeStudyVipList() {
			const that = this
			var data = {
				type: 'getGradeStudyVipList',
			}
			this.get("/3/gradeStudy", data).then((res) => {
				// this.AES(res.data.message)
				if (res.data.code == "success") {
					var result = res.data.result
					that.VipList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 获取专科清单
		getSpectialtyList(){
			const that = this
			var data = {
				type: 'getSpectialtyList',
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					console.log(result)
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 获取线上证书课列表
		getWebSiteOnlineCertificateCourseList() {
			const that = this
			var data = {
				type: 'getWebSiteOnlineCertificateCourseList',
				showOnlineCertificateCourseCount: 4,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					that.onlinecouse = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 国际证书课
		getRecommendCourse() {
			const that = this
			var data = {
				type: 'getRecommendCourse',
				index: 0,
				courseType: 29,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					that.couseinfoList = result
				} else {
					console.log(res.data.message)
				}
			})
		},
		// 执兽视频
		getWebSiteUpToDateOccupationVeterinaryList(){
			const that = this
			var data = {
				type: 'getWebSiteUpToDateOccupationVeterinaryList',
				index: 0,
				showOccupationVeterinaryCount: 4,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
					for (let i = 0; i < result.length; i++) {
						result[i].text = that.videoprice(result[i].salePrice,result[i].saleVipPrice ,result[i].saleIntegral ,result[i].saleVipIntegral)
					}
					that.holdingbeast = result
				} else {
					console.log(res.data.message)
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

// 宣传板块
.publicityList {
	display: flex;
	align-items: center;
	width: 1170px;
	margin: 40px 0;

	.publicityItem {
		width: 20%;
		display: flex;
		align-items: center;

		.icon {
			width: 75px;
			border-radius: 50%;
		}

		.publicityInfo {
			.title {
				font-size: 24px;
				color: #444;
			}

			.text {
				font-size: 13px;
				color: #999;
			}
		}
	}
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0 20px;

	.name {
		color: #444;
		font-size: 26px;
		font-weight: 600;
	}

	.more {
		color: #666;
		font-size: 14px;
		cursor: pointer;
	}
}
// 最新直播
.videolivenews {
	display: flex;
	justify-content: space-between;
	width: 1170px;


	.videoandlive {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 670px;


		.AIbannerBox {
			width: 670px;
			height: 200px;
			border-radius: 10px;
			.posImg {
				width: 670px;
				height: 200px;
				cursor: pointer;
			}
		}
		.videocont {
			margin-bottom: 10px;
		}

		.livecont {
			display: flex;
			justify-content: space-between;
			cursor: pointer;

			.liveitem {
				width: 215px;
				border-radius: 5px;
				box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);

				&:hover {
					box-shadow: 2px 7px 11px rgb(217, 232, 247);
				}

				.price {
					color: #00AA80;
					.vipprice {
						margin-left: 0.875rem;
						color: #C3911C;
					}
				}
				.themeimg {
					position: relative;
					border-radius: 5px;
					width: 215px;
					height: 160px;
					overflow: hidden;

					.viodeimgitem {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						border-radius: 5px;
						object-fit: cover;
					}

					.absolutbox {
						position: absolute;
						display: flex;
						justify-content: space-between;
						align-items: center;
						left: 0;
						bottom: 0;
						width: 100%;
						padding: 3px 10px;
						box-sizing: border-box;
						color: #fff;
						font-size: 14px;
						border-radius: 5px;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
						cursor: pointer;

						i {
							font-size: 18px;
						}
					}

					.livepricebox {
						position: absolute;
						right: 0;
						top: -2px;
						font-size: 14px;

						.forfree {
							padding: 0 3px;
							color: #6aac29;
							border-radius: 2px;
							background: rgba(231, 249, 213, 0.9);
						}

						.vipprice {
							padding: 0 3px;
							border-radius: 2px;
							color: #e09119;
							background: rgba(255, 243, 226, 0.7);
						}
					}
				}

				.videoinfo {
					padding: 10px;

					.name {
						color: rgba(68, 68, 68, 1);
						font-weight: 600;
						font-size: 18px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					.teacher {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						color: #444444;
						font-size: 14px;
						font-weight: 400;
					}

					.livetime {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}
	}

	.newsbox {
		width: 450px;
		.AiBox {
			height: 540px;
			padding: 30px 20px;
			box-sizing: border-box;
			box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
			border-radius: 10px;
			.AiTitle {
				width: 85px;
			}
			.inputBox {
				position: relative;
				margin-top: 15px;
				overflow: hidden;
				width: 400px;
				height: 45px;
				border: 1px solid #333;
				border-radius: 10px;
				input {
					width: 375px;
					height: 45px;
					font-size: 18px;
					padding: 0 15px;
					border: none;
					outline: none;
				}
				.searchBtn {
					position: absolute;
					width: 20px;
					right: 16px;
					top: 14px;
					cursor: pointer;
				}
			}
			.hotQuestionList {
				padding-top: 30px;
				.hotQuestionListTitle {
					position: relative;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 18px;
					font-weight: 600;
					padding-bottom: 10px;
					.refreshBtn {
						position: absolute;
						right: 0;
						display: flex;
						align-items: center;
						color: #222;
						font-size: 16px;
						user-select: none;
						font-weight: 500;
						cursor: pointer;
						&:hover {
							color: #2885BF;
						}
						.refreshIcon {
							width: 14px;
							padding-right: 10px;
						}
					}
				}
				.hotQuestionItem {
					height: 25px;
					line-height: 25px;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					padding-top: 15px;
					font-size: 18px;
					color: #444;
					cursor: pointer;
					&:hover {
						color: #2885BF;
					}
				}
			}
		}
		.newslabel {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			box-sizing: border-box;
			height: 249px;

			.newsitem {
				display: flex;
				justify-content: space-between;
				border-radius: 2px;
				padding: 6px 8px;
				height: 110px;
				box-sizing: border-box;
				cursor: pointer;
				box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
				&:nth-child(1){
					margin-top: 0;
				}
				&:hover {
					box-shadow: 2px 7px 11px rgb(217, 232, 247);
				}

				.newstime {
					padding: 16px;
					text-align: center;

					.day {
						font-weight: bold;
						color: rgba(153, 153, 153, 1);
						font-size: 3rem;
					}

					.yearandmounth {
						font-family: Helvetica;
						color: rgba(153, 153, 153, 1);
						font-size: 0.875rem;
						white-space: nowrap;
					}
				}

				.newsinfo {
					width: 320px;
					padding: 1rem;

					.newstitle {
						color: #444444;
						font-weight: 600;
						text-overflow: ellipsis;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						font-size: 1.05rem;
						margin: 0.5rem 0;
					}

					.preview {
						color: #999999;
						font-size: 0.875rem;
						font-weight: 400;
					}
				}
			}
		}
	}
}

.GradeStudyBox {
	margin-bottom: 2rem;
	width: 1170px;

	.title {
		display: flex;
		justify-content: space-between;
		-ms-align-items: center;
		align-items: center;
		margin-bottom: 1.25rem;
		margin-top: 2.5rem;

		.name {
			color: #444;
			font-weight: 600;
			font-size: 1.6rem;
		}
	}

	.GradeStudyList1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		.GradeStudyItem {
			display: flex;
			align-items: center;
			width: 375px;
			height: 80px;
			margin-bottom: 20px;
			border-radius: 5px;
			box-shadow: 1px 2px 10px #ccc;
			box-sizing: border-box;
			cursor: pointer;

			&:hover {
				box-shadow: 2px 7px 11px rgb(217, 232, 247);
			}

			.topBox {
				display: flex;
				align-items: center;
				padding-bottom: 10px;
				margin-right: 20px;

				.gradeIcon {
					width: 40px;
					height: 40px;
					padding-left: 40px;
					margin-top: 10px;
				}

				.texts {
					font-size: 16px;
					font-weight: 600;
					color: #444;
				}
			}

			.btmBox {
				.vip_name {
					font-size: 16px;
					font-weight: 600;
					color: #444;
				}
				.texts {
					overflow: hidden;
					white-space: nowrap;
					font-size: 14px;
					color: #444;
				}
			}
		}
	}
}

.specialcousebox {
	width: 1170px;
	margin: 3.75rem auto;

	.specialcouselabel {
		display: flex;
		// align-items: center;
		// justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 2.5rem;

		.couseimg {
			width: 20%;
			text-align: center;
			color: #fff;
			position: relative;
			z-index: 2;
			// height: 280px;
			min-height: 167px;
			background: linear-gradient(315deg, rgba(112, 226, 196, 1) 0%, rgba(21, 196, 125, 1) 100%);

			.couseTitle {
				font-weight: 600;
				font-size: 1.5rem;
				margin-top: 2rem;
				margin-bottom: 1rem;
				position: relative;
				z-index: 2;
			}

			.more {
				font-size: 0.875rem;
				border-radius: 1rem;
				display: inline-block;
				cursor: pointer;
				position: relative;
				z-index: 2;
				user-select: none;

				.button {
					color: #fff;
					display: block;
					font-size: 0.875rem;
					border-radius: 1rem;
					border: 1px solid #fff;
					padding: 3px 14px;
					display: inline-block;
					cursor: pointer;
					position: relative;
					z-index: 2;
				}
			}

			.couseicon {
				position: absolute;
				bottom: 0;
				text-align: center;
				margin: 0 auto;
				z-index: 0;
				width: 100%;
			}
		}

		.couseinfo {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 80%;

			.couseitem {
				width: 23.5%;
				// height: 280px;
				margin-left: 1.5%;
				position: relative;
				cursor: pointer;
				background: #fff;
				border-radius: 4px;
				box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				flex-direction: column;

				&:hover {
					box-shadow: 2px 7px 11px rgb(217, 232, 247);
				}

				.themeimg {
					border-radius: 4px;
					position: relative;
					width: 100%;
					height: 0;
					padding-bottom: 75%;
					overflow: hidden;

					.viodeimgitem {
						border-radius: 4px 4px 0 0;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.videoinfo {
					padding: 10px;

					.name {
						color: rgba(68, 68, 68, 1);
						font-size: 1.05rem;
						font-weight: 600;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					.teacher {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #444444;
						font-size: 0.875rem;
						font-weight: 400;
					}

					.zsprice {
						color: #00AA80;
						margin-top: 4px;
						font-size: 14px;

						.vipprice {
							margin-left: 0.875rem;
							color: #C3911C;
						}
					}

					.livetime {
						display: flex;
						align-items: center;
						font-size: 14px;
						.forfree {
							color: #00AA80;
						}

						.vipfree {
    						color: #C3911C;
							margin-left: 0.875rem;
						}
					}

					.price {
						font-size: 14px;
					}
				}
			}
		}

		.couse03 {
			background: linear-gradient(316deg, rgba(137, 149, 255, 1) 0%, rgba(65, 75, 240, 1) 100%);
		}

		.couse04 {
			background: linear-gradient(315deg, rgba(242, 151, 194, 1) 0%, rgba(226, 93, 143, 1) 100%);
		}

		.couse01 {
			background: linear-gradient(315deg, rgba(255, 199, 48, 1) 0%, rgba(240, 160, 45, 1) 100%);
		}
	}
}

.bottomBox {
	width: 100%;
	overflow: hidden;
	// padding-bottom: 40px;
	.studentsSpeak {
		width: 1170px;
		margin: 0 auto;
		.students {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			color: #444;
			.studentsItem {
				position: relative;
				width: 550px;
				background-color: #fff;
				border-radius: 0 40px 0 40px;
				margin-bottom: 30px;
				box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
				.studentText {
					margin: 30px 40px;
					font-size: 14px;
					height: 130px;
					p {
						margin-bottom: 10px;
					}
				}
				.studentInfo {
					display: flex;
					align-items: center;
					margin: 0 40px 30px;
					.studentImg {
						width: 45px;
						height: 45px;
						margin-right: 10px;
						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}
					}
					.studentNameText {
						.zhName {
							color: #444;
							font-weight: 600;
							font-size: 20px;
						}
						.enName {
							color: #666;
							font-style: italic;
							font-weight: normal;
						}
					}
				}
				.num {
					position: absolute;
					font-weight: normal;
					color: rgba(118, 170, 233, 0.14);
					font-style: italic;
					font-size: 7rem;
					right: 60px;
					bottom: -2.2rem;
				}
			}
			.videoBox {
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				height: 300px;
				.video {
					width: 100%;
				}
			}
		}
	}
	.bannerBox {
		position: relative;
		top: -80px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: -1;
		.missionTextBox {
			position: absolute;
			top: 0;
			width: 1170px;
			color: #fff;
			.missionTextTitle {
				font-weight: 600;
				font-size: 3rem;
				margin-top: 8%;
				margin-bottom: 14px;
			}
			.missionText {
				font-size: 3rem;
				margin-bottom: 10%;
				font-weight: 300;
			}
		}
	}
}


.partnerBox {
	position: relative;
	top: -80px;
	overflow: hidden;
	width: 1170px;
	margin: auto;
}

</style>