<template>
	<div>
		<swiper :options="swiperOption" ref="mySwiper">
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner01.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner02.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner04.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner05.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner06.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner07.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner08.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner09.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner10.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner11.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner12.png" title="百美达" alt="百美达">
            </swiper-slide>
			<swiper-slide>
                <img class="viodeimgitem" src="../assets/images/partner/partner13.png" title="百美达" alt="百美达">
            </swiper-slide>
		</swiper>
        <!-- <div class="swiper-button-next" slot="button-next" @click="next"></div>
        <div class="swiper-button-prev" slot="button-prev" @click="prev"></div> -->
	</div>
</template>

<script>
export default {
	name: 'HomeSwiper',
	data () {
		return {
			swiperOption: {
				loop: true,
                slidesPerView: 5,
				autoplay: {
					delay: 3000,
					stopOnLastSlide: false,
          			disableOnInteraction: false
				},
				pagination: {
					el: '.swiper-pagination',
                    type: 'fraction',
					clickable: true
				},
                // navigation: {
                //     prevEl: ".swiper-button-prev2",
                //     nextEl: ".swiper-button-next2"
                // },
			}
		}
	},
}
</script>

<style lang="scss" scoped>
    .viodeimgitem{
        width: 180px;
    }
</style>