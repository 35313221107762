<!-- 发帖 -->
<template>
    <div class="content">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="科室" prop="Department">
                <el-select v-model="form.Department" placeholder="请选择科室">
                    <el-option :label="item.department_name" :value="item.department_no"
                        v-for="(item, index) in DepartmentListData" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题" prop="title">
                <el-input type="input" v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="病例描述" prop="description">
                <el-input type="textarea" v-model="form.description"></el-input>
            </el-form-item>
            <el-form-item label="上传图片">
                <div class="imgList">
                    <div class="imgItem" v-for="(item, index) in upImgList" :key="index">
                        <img class="imgU" :src="item.url" alt="" @click="openBigImg(item)">
                        <img class="closeIcon" src="../../assets/images/closeBtn.png" alt="" @click="deleteImg(item,index)">
                    </div>
                    <div class="addImg" v-show="upImgList.length < 8">
                        选择图片
                        <input class="updataInput" type="file" @change="uploadImage"/>
                    </div>
                </div>
                <div class="toast">
                    (最多上传8张,图片大小不超过1MB)
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">立即创建</el-button>
            </el-form-item>
        </el-form>
        
        <div class="bigImg" v-show="bigImgShow" @click="bigImgShow = false">
            <img class="imgUrl" :src="bigImgUrl" alt="">
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie'
import hex_md5 from "../../assets/js/md5"
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            upImgList: [],
            // 表单数据
            form: {
                Department: '',
                title: '',
                description: '',
            },
            rules: {
                Department: [
                    { required: true, message: '请选择科室' },
                ],
                title: [
                    { required: true, message: '请输入标题' }
                ],
                description: [
                    { required: true, message: '请输入病例描述并且不少于20字', min: 20, max: 500 }
                ],
            },
            // 科室列表
            DepartmentListData: [],
            // 帖子ID
            postId: '',
            // 3为闲趣
            pub_areacategory: 0,
            // 放大图片
            bigImgUrl: "",
            // 大图开关
            bigImgShow: false
        }
    },
    created() {
        this.getPostDepartmentList()
        this.insertPostsDraft()
    },
    methods: {
        updataBtn(){
            this.$refs.uploadInput.click()
        },
        uploadImage(event) {
            const that = this
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                const formData = new FormData();
                formData.append('text', reader.result);
                var url = "http://appinterface.chongyike.com/3/postsForWeb?type=inputPostsImage&imgTag=c20&postId=" + that.postId  + that.getConfigText()
                    // url = "http://192.168.18.221:83/3/postsForWeb?type=inputPostsImage&imgTag=c20&postId=" + that.postId  + that.getConfigText()
                axios.post(url, formData, {
                    headers: {
                        'Authorization': Cookies.get('token')!='undefined'? 'Bearer ' + Cookies.get('token'):''
                    }
                })
                .then(response => {
                    // 处理响应
                    if(response.data.code == "success"){
                        var result = response.data.result
                        var item = {
                            url:reader.result,
                            value: result.data
                        }
                        that.upImgList.push(item)
                    }else if(response.data.code == "error"){
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: 'error',
                            duration: 2000
                        });
                    }else {
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: 'error',
                            duration: 2000
                        });
                    }
                })
                .catch(error => {
                    // 处理错误
                    console.error(error);
                });
            };
            reader.readAsDataURL(file);
        },
        // 删除图片
        deleteImg(item,index){
            const that = this
            var data = {
                type: "deletePostsImage",
                postId: that.postId,
                imgTag: 'c20',
                imagePath: item.value,
            }
            this.get("/3/posts", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.upImgList.splice(index, 1)
					that.$message({
						showClose: true,
						message: "删除成功",
						type: 'success',
                        duration: 1500
					});
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 查看大图
        openBigImg(item){
            const that = this
            that.bigImgUrl = item.url
            that.bigImgShow = true
        },
        // 提交表单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.insertPosts()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取科室
        getPostDepartmentList() {
            const that = this
            var data = {
                type: "getPostDepartmentList",
                index: 0,
                count: 20
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.DepartmentListData = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取帖子ID
        insertPostsDraft() {
            const that = this
            var data = {
                type: "insertPostsDraft",
            }
            this.get("/3/posts", data).then(res => {
                if (res.data.code == 'success') {
                    var result = res.data.result
                    that.postId = result.id
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 发布帖子
        insertPosts(index) {
            const that = this
            // var text = {
            //     Department: that.DepartmentID, // 科室
            //     title: that.title, // 标题
            //     description: that.description, // 病例描述
            //     ID: that.postId, // 帖子id
            //     pub_areacategory: that.pub_areacategory, // 3是闲趣
            //     Remarks5: that.pub_areacategory == 3 ? '' : 2
            // }
            var text = that.form
            text.ID = that.postId
            text.pub_areacategory = that.pub_areacategory
            text.Remarks5 = 2
            console.log(text)
            var data = {
                type: "insertPosts",
                text: this.encrypt(JSON.stringify(text))
            }
            this.get("/3/posts", data).then(res => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    console.log(result)
                    setTimeout(() => {
                        that.$router.push({
                            path: '/postList',
                        })
                    }, 1500);
					that.$message({
						showClose: true,
						message: "发布成功,即将返回",
						type: 'success',
                        duration: 1500
					});
                }
            })
        },
        // 官网公共参数
        getConfigText(){
            const that = this
            // 获取当前时间
            var time = (new Date()).getTime();
            var data = {
                // 接口来源 安卓/IOS/WEB
                client: "WEB",
                // 版本号
                device: "",
                // 时间戳
                requestTime: time,
                // 版本号
                versionCode: 251,
            }
            if(Cookies.get('device')){
                data.device = Cookies.get('device')
            }else {
                var lsnms = that.num();
                var strs = time + "" + lsnms;
                var device = hex_md5(strs).toUpperCase();
                Cookies.set('device', device, {  expires: 7 })
                data.device = device
            }
            data.sign = that.serSign(data)
            var text = ""
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    text +="&" + key + "=" + data[key] 
                }
            }
            return text
        },

        num() {
            let mm = Math.random();
            let six = '';
            if (mm < 0.1) {
                mm += 0.1;
            }
            six = Math.round(mm * 1000000);
            return Number(six.toString().padStart(6, '0'));
        },

        // 创建sign参数
        serSign(data){
            var text = ""
            for (let key in data) {
                text = text + key + '=' + data[key] + "&"
            }
            return hex_md5(text + 'key=huiyichongyike')
        },
    }
}
</script>

<style lang="scss" scoped>
.imgList {
    display: flex;
    flex-wrap: wrap;
    .imgItem {
        position: relative;
        width: 200px;
        height: 200px;
        margin-right: 20px;
        border: 1px solid #ccc;
        .imgU {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
        .closeIcon {
            position: absolute;
            right: -10px;
            top: -10px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
        }
    }
    .addImg {
        position: relative;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #ccc;
        background-color: #fff;
        .updataInput {
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            height: 200px;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.toast {
    color: #666;
    font-size: 14px;
}

.bigImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    .imgUrl {
        height: 100vh;
    }
}
</style>