<!-- 执兽课程 -->
<template>
    <div class="content">
        <div class="lecturerInfoBox">
            <div class="leftBox">
                <div class="hearImg">
                    <img class="img" :src="lecturerInfo.head_portrait?imgSrc + lecturerInfo.head_portrait:require('../../assets/images/user.png')" alt="">
                </div>
                <div class="lecturerName">
                    {{ lecturerInfo.name }}
                </div>
            </div>
            <div class="rightBox">
                <div class="lecturerDetails">
                    {{ lecturerInfo.introduce_text }}
                </div>
                <div class="details" v-if="lecturerInfo.introduce_h5" v-html="lecturerInfo.introduce_h5"></div>
            </div>
        </div>
        <div class="classListBox" v-for="(item, index) in videoclass" :key="index">
            <div class="titlelabel">
                <div class="title">{{ item.className }}</div>
                <div class="moreclass" @click="jumpVideoListInner(item)">
                    更多{{ item.className }}
                    <i :class="item.openOff?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                </div>
            </div>
            <div class="videocont">
                <div class="videoitem" @click="jumpVideoDetail(item1)" v-for="(item1, index1) in item.videoList" :key="index1" v-show="item.openOff || index1 < 5">
                    <div class="themeimg">
                        <img class="videoimgitem default" :src="item1.img?imgSrc + item1.img:require('../../assets/images/default.jpg')"></div>
                    <div class="videoinfo">
                        <div class="name">{{ item1.name }}</div>
                        <div class="teacher">讲师:{{ item1.lecturer_names }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 数据
            videoclass: [],
            // 讲师id
            lecturerId: "",
            // 讲师信息
            lecturerInfo: {},
        }
    },
    created() {
        const that = this
        that.lecturerId = this.$route.params.id
        that.getWebSiteVideoSeriesListByLectureId()
    },
    methods: {
        // 跳转视频详情
        jumpVideoDetail(item){
            const that = this
            console.log(item)
            var data = {
                dataType: 0,
                No: item.No,
				name: item.name,
                classType: 1,
                classId: item.three_class_ids
            }
            that.$router.push({
                path: '/videoDetail',
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
        // 跳转视频详情
        jumpVideoListInner(item){
            const that = this
            that.$router.push({
                path: '/videolistInner/' + item.classId,
            })
        },
        // 获取讲师数据
        getWebSiteVideoSeriesListByLectureId(){
            const that = this
            var data = {
                type: 'getWebSiteVideoSeriesListByLectureId',
                version: 1,
                lecturerId: that.lecturerId
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    result.lecturerInfo.introduce_h5 = result.lecturerInfo .introduce_h5.replace(
							/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
								return `<img src='${ p1.indexOf('http') > -1 ? p1 : that.imgSrc + p1}'  style='max-width: 100%;'/>`
							})
                    that.lecturerInfo = result.lecturerInfo
                    that.videoclass = result.seriesList
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取视频系列列表
        getWebSiteClassList() {
            const that = this
            var data = {
                type: 'getWebSiteClassList',
                oneLevelClassId: 1
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].openOff = false
                    }
                    that.videoclass = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 展开收起
        changeOff(item){
            const that = this
            item.openOff = !item.openOff
        },
    }
}
</script>

<style lang="scss" scoped>
    .lecturerInfoBox {
        display: flex;
        padding: 20px 10px;
        background-color: #f5f5f5;
        .leftBox {
            width: 5.2rem;
            height: 5.2rem;
            padding: 0rem 4rem;
            .hearImg {
                width: 5.2rem;
                height: 5.2rem;
                margin: 1rem auto 0.5rem;
                .img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .lecturerName {
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 1.2rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .classListBox {
        padding-top: 20px;
    }
    .videocont {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        justify-content: flex-start;
        .videoitem {
            width: 18.8%;
            margin-right: 1.5%;
            position: relative;
            cursor: pointer;
            background: #fff;
            border-radius: 4px;
            box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
            display: flex;
            flex-direction: column;
            margin-bottom: 0.875rem;
            &:nth-child(5n){
                margin-right: 0;
            }
            &:hover {
                box-shadow: 2px 7px 11px rgb(217, 232, 247);
            }
            .themeimg {
                border-radius: 4px;
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 75%;
                overflow: hidden;
                .videoimgitem {
                    border-radius: 4px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .videoinfo {
                padding: 10px;
                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: rgba(68, 68, 68, 1);
                    font-size: 1.05rem;
                    font-weight: 600;
                }
                .teacher {
                    padding-top: 5px;
                    color: #444444;
                    font-size: 0.875rem;
                    font-weight: 400;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
</style>