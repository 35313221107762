<!-- 执兽课程列表 -->
<template>
    <div class="content">
        <div class="bannercouse">
            <img class="bannerImg" src="../../assets/images/banner-couse.png" alt="">
            <div class="textBox">
                <div class="text">
                    {{ classInfo.class_name }}
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="cousecontent">
                <div class="couseitem" v-for="(item, index) in videoList" :key="index" @click="jumpVideoDetail(item)">
                    <div class="couseimg">
                        <img class="viodeimgitem default" :src="imgSrc + item.img" >
                    </div>
                    <div class="couseinfo">
                        <div class="title">{{ item.name }}</div>
                        <div class="teacher" v-show="item.theteacher">讲师：{{ item.theteacher }}</div>
                        <div class="times">{{ item.starttime.slice(0,10) }}</div>
                        <div class="price" v-if="item.flag == 1">
                            <span>已购买</span>
                        </div>
                        <div class="price" v-else>
                            <span>{{ item.text.pricetext }}</span>
                            <span class="vipprice" v-show="item.text.vipprice">{{ item.text.vipprice }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination
                background
                layout="prev, pager, next"
                :total="classInfo.threeLevelTotalCount"
                :page-size="12"
                @current-change="changeBtn">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 请求ID
            threeLevelVideoClassId: "",
            // 页面数据
            classInfo: {},
            // 视频列表
            videoList: [],
            // 页码
            index: 0
        }
    },
    created(){
        const that = this
        that.threeLevelVideoClassId = this.$route.params.id
        that.getWebSiteVeterinaryExaminationDetailListByThreeLevelClassId()
    },
    methods: {
        // 跳转视频详情
        jumpVideoDetail(item){
            const that = this
            var data = {
                dataType: 9,
                No: item.No,
				name: item.name,
                classType: 1,
                classId: that.threeLevelVideoClassId
            }
            that.$router.push({
                path: '/trainVetVideoDetail',
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
        // 翻页
        changeBtn(e){
            const that = this
            that.index = e-1
            that.getWebSiteOnlineCertificateCourseList()
        },
        // 获取列表数据
        getWebSiteVeterinaryExaminationDetailListByThreeLevelClassId() {
            const that = this
            var data = {
                type: 'getWebSiteVeterinaryExaminationDetailListByThreeLevelClassId',
                version: 1,
                threeLevelClassId: that.threeLevelVideoClassId,
                index: that.index,
                showCount: 12,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.classInfo = result
                    var dataList = result.infoList
					for (let i = 0; i < dataList.length; i++) {
						dataList[i].text = that.videoprice(dataList[i].salePrice,dataList[i].saleVipPrice ,dataList[i].saleIntegral ,dataList[i].saleVipIntegral)
					}
                    console.log(dataList)
                    that.videoList = []
                    that.videoList = dataList
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100% !important;
    padding: 0 0 30px !important;
    .bannercouse{
        position: relative;
        .bannerImg {
            width: 100%;
        }
        .textBox {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            .text {
                width: 1170px;
                margin: 0 auto;
                padding-left: 20px;
                font-size: 3.25rem;
                font-weight: normal;
                color: #fff;
                box-sizing: border-box;
            }
        }
    }

    .container {
        width: 1170px !important;
        margin: 0 auto;
        .cousecontent {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            .couseitem {
                position: relative;
                width: 50%;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                -ms-align-items: center;
                align-items: center;
                cursor: pointer;
                font-size: 0.875rem;
                padding-bottom: 2rem;
                padding-top: 2rem;
                border-bottom: 1px solid #eee;
                .couseimg {
                    position: relative;
                    flex: 1;
                    margin-right: 1.25rem;
                    height: 0;
                    padding-bottom: 26%;
                    .viodeimgitem {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }
                .couseinfo {
                    flex: 1.8;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .title {
                        color: #444444;
                        font-size: 1.05rem;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .teacher {
                        color: #444444;
                    }
                    .price {
                        color: #00AA80;
                        .vipprice {
                            margin-left: 0.875rem;
                            color: #C3911C;
                        }
                    }
                }
            }
        }
    }
}

</style>