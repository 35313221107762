<!-- 执兽课程 -->
<template>
    <div class="content">

        <div class="countDownBox" v-if="dayNum > 0">
            距25年考试还有
            <span>{{ count.hundreds }}</span>
            <span>{{ count.decade }}</span>
            <span>{{ count.day }}</span>
            天
        </div>
        <div class="countDownBox" v-else>
            24年考试已结束
        </div>

        <div class="accuracyBox">
            <div class="accuracyItem">
                <div class="number">{{ rankData.totalCount }}<span>题</span></div>
                <div class="accuracyNum">正确率{{ rankData.totalCountRate }}%</div>
                <div class="Value">累积做题次数</div>
            </div>
            <div class="accuracyItem">
                <div class="number">{{ rankData.totalNumber }}<span>题</span></div>
                <div class="accuracyNum">正确率{{ rankData.totalNumberRate }}%</div>
                <div class="Value">累积做题量</div>
            </div>
            <div class="accuracyItem">
                <div class="number">{{ rankData.todayCount }}<span>题</span></div>
                <div class="accuracyNum">正确率{{ rankData.todayCountRate }}%</div>
                <div class="Value">今日做题量</div>
            </div>
        </div>

        <div class="toastTitle">
            <img class="toastIcon" src="../../assets/images/trainVetIcon.png" alt="">
            <div class="text" v-show="isZtVIP == 0">您还未购买真题题库会员</div>
            <div class="text" v-show="isZtVIP == 1">您真题题库会员已到期<span>到期时间{{ msg.slice(0, 10) }}</span></div>
            <div class="text" v-show="isZtVIP == 2">您当前为真题题库会员 <span>有效期至{{ msg.slice(0, 10) }}</span></div>
        </div>

        <div class="jumpItemList">
            <div class="jumpItem" v-for="(item, index) in jumpListData" :key="index" @click="jumpWeb(item)">
                <div class="itemTitle">{{ item.icontitle }}</div>
                <img class="itemIcon" :src="item.iconSrc" alt="">
            </div>
        </div>

        <!-- <div class="titlelabel">
            <div class="title">执兽模拟</div>
            <div class="more moreclass" @click="jumppracticeTest()">
                前往执兽模拟
                <i class="el-icon-caret-right"></i>
            </div>
        </div>
        <div class="titlelabel">
            <div class="title">考前模拟</div>
            <div class="more moreclass" @click="jumppracticeTest2()">
                前往考前模拟
                <i class="el-icon-caret-right"></i>
            </div>
        </div> -->
        <div class="classListBox" v-for="(item, index) in videoclass" :key="index">
            <div class="titlelabel">
                <div class="title">{{ item.class_name }}</div>
                <div class="moreclass" @click="changeOff(item)">
                    更多{{ item.class_name }}
                    <i :class="item.openOff ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"></i>
                </div>
            </div>
            <div class="videocont">
                <div class="videoitem" v-for="(item1, index1) in item.threeLevelClassRecord" :key="index1"
                    v-show="item.openOff || index1 < 5" @click="jumpTrainVetInner(item1)">
                    <div class="themeimg">
                        <img class="videoimgitem default"
                            :src="item1.class_image ? imgSrc + item1.class_image : require('../../assets/images/default.jpg')">
                    </div>
                    <div class="videoinfo">
                        <div class="name">{{ item1.class_name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 数据
            videoclass: [],
            // 跳转列表
            jumpListData: [
                {
                    icontitle: '学科练习',
                    iconSrc: require("../../assets/images/indexIcon1.png"),
                    path: "/subjects"
                },
                {
                    icontitle: '科目/年份练习',
                    iconSrc: require("../../assets/images/indexIcon2.png"),
                    path: "/previousExamPapers"
                },
                {
                    icontitle: '全真模拟',
                    iconSrc: require("../../assets/images/indexIcon3.png"),
                },
                {
                    icontitle: '我的错题',
                    iconSrc: require("../../assets/images/indexIcon4.png"),
                    path: "/myMistake"
                },
                {
                    icontitle: '我的收藏',
                    iconSrc: require("../../assets/images/indexIcon5.png"),
                    path: "/myCollection"
                },
                {
                    icontitle: '图视题型',
                    iconSrc: require("../../assets/images/indexIcon6.png"),
                    path: "/pictureQuestion"
                },
                // {
                //     icontitle: '我的纠错',
                //     iconSrc: require("../../assets/images/indexIcon7.png"),
                //     id: 13
                // },
                {
                    icontitle: '考前模拟',
                    iconSrc: require("../../assets/images/indexIcon9.png"),
                },
            ],
            // 倒计时天数
            dayNum: 0,
            // 当前年份
            yearStr: "",
            // 距离考试倒数天数
            count: {
                // 百位
                hundreds: 0,
                // 十位
                decade: 0,
                // 个位
                day: 0
            },
            // 执兽题库做题数据
            rankData: {
                todayCount: 0,
                todayCountRate: 0,
                totalCount: 0,
                totalCountRate:"0",
                totalNumber:0,
                totalNumberRate: "0",
            },
            // 判断是否是真题题库会员 0未购买 1到期 2未过期
            isZtVIP: 0,
            // 到期时间
            msg: ""
        }
    },
    created() {
        const that = this
        that.getWebSiteClassList()
        that.userAnswerStatistics()
        that.isBuyMember()
        that.getUserData()
    },
    methods: {
        // 跳转执兽模拟考试
        jumppracticeTest() {
            window.open("https://www.chongyike.com/practiceTest/index.html#/")
        },
        // 跳转考前模拟
        jumppracticeTest2() {
            window.open("https://www.chongyike.com/practiceTest/veterinaryPredict/index.html#/")
        },
        // 执兽跳转视频详情
        jumpTrainVetInner(item) {
            const that = this
            that.$router.push({
                path: '/trainVetInner/' + item.class_id,
            })
        },
        // 跳转题库方法
        jumpWeb(item) {
            const that = this
            if (item.path) {
                that.$router.push({
                    path: item.path,
                })
            } else if (item.icontitle == "全真模拟") {
                that.jumppracticeTest()
            } else if (item.icontitle == "考前模拟") {
                that.jumppracticeTest2()
            }
        },
        // 获取执兽公开培训课
        getWebSiteClassList() {
            const that = this
            var data = {
                type: 'getWebSiteClassList',
                oneLevelClassId: 418,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].openOff = false
                    }
                    that.videoclass = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 展开收起
        changeOff(item) {
            const that = this
            item.openOff = !item.openOff
        },
        // 获取执兽倒计时
        userAnswerStatistics() {
            const that = this
            var data = {
                type: 'userAnswerStatistics',
            }
            this.get('/3/veterinaryYear', data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    if (result) {
                        that.dayNum = result.day
                        that.yearStr = result.year
                        that.count.hundreds = (result.day - result.day % 100) / 100
                        that.count.decade = ((result.day % 100) - (result.day % 100) % 10) / 10
                        that.count.day = (result.day % 100) % 10
                    }
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 是否是会员
        isBuyMember() {
            const that = this
            var data = {
                type: 'isBuyMember',
            }
            this.get('/3/veterinaryYear', data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.isZtVIP = result.state
                    that.msg = result.msg
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取做题数据
        getUserData() {
            const that = this
            var data = {
                type: 'getUserData',
            }
            this.get('/3/veterinaryYear', data).then((res) => {
                if (res.data.code == "success") {
                    console.log(res.data.result)
                    var result = res.data.result
                    that.rankData = result.tabOne
                } else {
                    console.log(res.data.message)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    margin: 20px 0 10px;
}

.countDownBox {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 115px;
    background-color: #1d73bb;
    border-radius: 10px;
    font-size: 22px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        font-size: 26px;
        border-radius: 3px;
        margin-right: 5px;
        color: #1d73bb;
        background-color: #fff;

        &:nth-child(1) {
            margin-left: 5px;
        }
    }
}

.accuracyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 220px;
    height: 160px;
    margin: 20px 0;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    box-sizing: border-box;

    .accuracyItem {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 240px;

        &:nth-child(n+2) {
            &::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 8px;
                width: 1px;
                height: 80px;
                background-color: #D8D8D8;
            }
        }

        .number {
            font-size: 32px;

            span {
                font-size: 18px;
            }
        }

        .accuracyNum {
            padding: 5px 0;
            font-size: 18px;
            color: #999;
        }

        .Value {
            font-size: 18px;
            color: #666;
        }
    }
}

.toastTitle {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 30px;
    height: 98px;
    border-radius: 10px;
    box-sizing: border-box;
    background-image: url("../../assets/images/trainVetToast.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .toastIcon {
        width: 58px;
        margin-right: 30px;
        color: #000000;
    }

    span {
        padding-top: 6px;
        padding-left: 10px;
        font-size: 16px;
        color: #966D51;
    }
}

.jumpItemList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;

    &::after {
        content: "";
        width: 264px;
    }
    .jumpItem {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        width: 264px;
        height: 106px;
        border-radius: 5px;
        box-shadow: 0 0 15px 4px #e8e8e8;
        padding-left: 20px;
        box-sizing: border-box;
        cursor: pointer;

        .itemTitle {
            text-align: center;
            width: 120px;
            font-size: 18px;
            color: #333;
            padding-right: 20px;
        }

        .itemIcon {
            width: 65px;
        }
    }
}

.videocont {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    justify-content: flex-start;

    .videoitem {
        width: 18.8%;
        margin-right: 1.5%;
        position: relative;
        cursor: pointer;
        background: #fff;
        border-radius: 4px;
        box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
        display: flex;
        flex-direction: column;
        margin-bottom: 0.875rem;

        &:nth-child(5n) {
            margin-right: 0;
        }

        &:hover {
            box-shadow: 2px 7px 11px rgb(217, 232, 247);
        }

        .themeimg {
            border-radius: 4px;
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 75%;
            overflow: hidden;

            .videoimgitem {
                border-radius: 4px;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .videoinfo {
            padding: 10px;

            .name {
                color: rgba(68, 68, 68, 1);
                font-size: 1.05rem;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}
</style>