<!-- 宠医医讯列表 -->
<template>
    <div class="content">
        <div class="dataList">
            <div class="dataItem" v-for="(item, index) in dataList" :key="index" @click="jumpPostDetail(item)">
                <img class="leftImg" :src="imgSrc + item.data_img" alt="">
                <div class="rightBox">
                    <div class="title">{{ item.data_name }}</div>
                    <div class="dataInfo">
                        <div class="iconList">
                            <i class="el-icon-view"></i>
                            {{ item.look_count }}
                            <i class="el-icon-chat-dot-square"></i>
                            {{ item.fabulous_count }}
                        </div>
                        <div class="times">
                            {{ item.time.slice(0,10) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="count"
            @current-change="changeBtn">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 页面数据
            total: 0,
            // 视频列表
            dataList: [],
            // 页码
            index: 0,
            // 每页数量
            count: 10
        }
    },
    created(){
        const that = this
        that.dataNo = this.$route.params.id
        that.getNews()
    },
    methods: {
		// 跳转宠医资讯
        jumpPostDetail(item){
            this.$router.push({
                path: "/postDetail/" + item.data_no,
            })
        },
        // 翻页
        changeBtn(e){
            const that = this
            that.index = e-1
            that.getNews()
        },
        // 获取宠医资讯列表
        getNews(){
            const that = this
            var data = {
                type: 'getNews',
                orderByType: 1,
                count: that.count,
                index: that.index
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.total = res.data.count
                    console.log(result)
                    that.dataList = result
                } 
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    .dataList {
        padding-bottom: 20px;
        .dataItem {
            display: flex;
            align-items: center;
            padding: 10px;
            margin-bottom: 20px;
            border-radius: 5px;
            height: 150px;
            cursor: pointer;
            box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
            &:hover {
                box-shadow: 2px 7px 11px rgb(217, 232, 247);
            }
            .leftImg {
                width: 150px;
                margin-right: 20px;
            }
            .rightBox {
                .title {
                    width: 980px;
                    height: 110px;
                    padding: 15px 0;
                    box-sizing: border-box;
                    font-size: 24px;
                    font-weight: 600;
                }
                .dataInfo {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    color: #999;
                    .iconList {
                        display: flex;
                        align-items: center;
                        .el-icon-chat-dot-square {
                            margin-left: 15px;
                        }
                    }
                    .times {
                        padding: 20px;
                    }
                }
            }
        }
    }
}
</style>