<!-- 宠医圈 -->
<template>
    <div>
        <div class="departmentListBox">
            <div class="departmentList">
                <div :class="departmentActive==-1?'departmentItem active':'departmentItem'" @click="changeDepartment({department_no: ''},-1)">
                    全部
                </div>
                <div :class="departmentActive==index?'departmentItem active':'departmentItem'" v-for="(item, index) in departmentListData" :key="index" @click="changeDepartment(item,index)">
                    {{ item.department_name }}
                </div>
            </div>
        </div>
        <div class="changePostListBtn">
            <div class="leftBox">
                <div :class="CategoryActive==index?'btnItem active':'btnItem'" v-for="(item, index) in CategoryListData" :key="index" @click="changeCategory(item, index)">
                    {{ item.pub_areacategory_name }}
                </div>
            </div>
            <div class="rightBox">
                <div :class="timeActive==1?'btnItem active':'btnItem'" @click="changeTimeActive(item, 1)">
                    按发帖时间
                </div>
                <div :class="timeActive==0?'btnItem active':'btnItem'" @click="changeTimeActive(item, 0)">
                    按回复时间
                </div>
            </div>
        </div>
        <div class="hotPostListBox">
            <div class="title">
                <div class="text">精选热门</div>
                <div class="postBtn" @click="jumpPost()">我要发帖</div>
            </div>
            <div class="postList">
                <div class="left">
                    <div class="postItem" v-for="(item, index) in topListData" :key="index" @click="jumpPostDetail(item)">
                        <img class="postImg" :src="imgSrc + item.Imgurl1" alt="" v-show="item.Imgurl1">
                        <div class="postInfo">
                            <div class="postTitle">{{ item.Title }}</div>
                            <div class="postText">{{ item.Description }}</div>
                            <div class="iconBox">
                                <div class="nameBox">
                                    <div class="name">{{ item.userName }}</div>
                                    <div class="time">{{ item.marder_time.slice(0,10) }}</div>
                                </div>
                                <div class="icon">
								    <i class="el-icon-view">{{ item.looknumber }}</i>
								    <i class="el-icon-thumb">{{ item.pub_clickalike }}</i>
								    <i class="el-icon-chat-dot-square">{{ item.replynumber }}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="right">
                    <div class="information" v-for="(item, index) in informationListData" :key="index" @click="jumpMedicalInformation(item)">
                        {{ item.title }}
                    </div>
                </div> -->
            </div>
        </div>
        <div class="allPostListBox">
            <div class="title">
                <div class="text">全部病例帖子</div>
                <div class="addBtn" @click="jumpAllPost()">更多帖子</div>
            </div>
            <div class="postItem" v-for="(item, index) in allPostListData" :key="index" @click="jumpPostDetail(item)">
                <img class="postImg" :src="imgSrc + item.Imgurl1" alt="" v-show="item.Imgurl1">
                <div class="postInfo" :style="item.Imgurl1?'':'width:100%;'">
                    <div class="postTitle">{{ item.Title }}</div>
                    <div class="postText" :style="item.Imgurl1?'height:76px;':''">{{ item.Description }}</div>
                    <div class="iconBox">
                        <div class="nameBox">
                            <div class="name">{{ item.userName }}</div>
                            <div class="time">{{ item.marder_time.slice(0,10) }}</div>
                        </div>
                        <div class="icon">
                            <i class="el-icon-view">{{ item.looknumber }}</i>
                            <i class="el-icon-thumb">{{ item.pub_clickalike }}</i>
                            <i class="el-icon-chat-dot-square">{{ item.replynumber }}</i>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination
                style="margin: 20px 0;"
                background
                layout="prev, pager, next"
                :total="allCount"
                :page-size="count"
                @current-change="changeBtn">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 当前选中科室
            departmentActive: -1,
            // 选中科室ID
            departmentId: "",
            // 科室列表
            departmentListData: [],
            // 当前选中病例类型
            CategoryActive: 0,
            // 请求需要的参数
            category: 9,
            // 病例类型
            CategoryListData: [],
            // 1是按发帖时间 0是按回复时间
            timeActive: 1,
            // 精选热门列表
            topListData: [],
            // 全部帖子列表
            allPostListData: [],
            // 获取列表的参数
            index: 0,
            count: 10,
            allCount: 0,
            // 顶部右侧公告数据
            informationListData: []
        }
    },
    created(){
        const that = this
        that.getPostCategory()
        that.getPostDepartmentList()
        that.getTopPost()
        that.getTopPostList()
        that.getPostList()
    },
    methods: {
        // 跳转发帖
        jumpPost(){
			this.$router.push({
				path: '/post',
			})
        },
        // 查看更多
        jumpAllPost(){
			this.$router.push({
				path: '/allPost',
			})
        },
        // 跳转帖子详情
        jumpPostDetail(item){
            this.$router.push({
                path: "/postHtmlDetail/" + item.ID
            })
        },
        // 跳转医讯
        jumpMedicalInformation(item){
            this.$router.push({
                path: "/medicalInformation/" + item.topno
            })
        },
        // 切换科室
        changeDepartment(item, index){
            if(index != this.departmentActive){
                this.departmentId = item.department_no
                this.departmentActive = index
                this.getPostList()
            }
        },
        // 切换帖子类型
        changeCategory(item, index){
            if(index != this.CategoryActive){
                this.category = item.pub_areacategory_no
                this.CategoryActive = index
                this.getPostList()
            }
        },
        // 切换帖子顺序
        changeTimeActive(item, index){
            if(index != this.timeActive){
                this.timeActive = index
                this.getPostList()
            }
        },
        // 翻页
        changeBtn(e){
            const that = this
            that.index = e-1
            that.getPostList()
        },
        // 获取帖子分类
        getPostCategory(){
			const that = this
			var data = {
				type: 'getPostCategory',
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
                    that.CategoryListData = result
				} else {
					console.log(res.data.message)
				}
			})
        },
        // 获取科室分类
        getPostDepartmentList(){
			const that = this
			var data = {
				type: 'getPostDepartmentList',
                count: 99,
                index: 0,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
                    that.departmentListData = result
				} else {
					console.log(res.data.message)
				}
			})
        },
        // 获取侧边公告栏
        getTopPost(){
			const that = this
			var data = {
				type: 'getTopPost',
                position: 0,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
                    console.log(result)
                    that.informationListData = result
				} else {
					console.log(res.data.message)
				}
			})
        },
        // 获取精选热门
        getTopPostList(){
			const that = this
			var data = {
				type: 'getPostList',
                position: 0,
                index: 0,
                count: 2,
                keyward: "",
                department: "",
                sort: 1,
                category: 4,
                summary: 2,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
                    that.topListData = result
				} else {
					console.log(res.data.message)
				}
			})
        },
        // 获取帖子列表
        getPostList(){
			const that = this
			var data = {
				type: 'getPostList',
                position: 0,
                index: that.index,
                count: that.count,
                keyward: "",
                department: that.departmentId,
                sort: that.timeActive,
                category: that.category,
                summary: 2,
			}
			this.get("/webhttp/mobileHandler", data).then((res) => {
				if (res.data.code == "success") {
					var result = res.data.result
                    that.allCount = res.data.count
                    that.allPostListData = result
				} else {
					console.log(res.data.message)
				}
			})
        },
    }
}
</script>

<style lang="scss" scoped>
.departmentListBox {
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
    .departmentList {
        display: flex;
        align-items: center;
        width: 1170px;
        padding: 15px 15px;
        box-sizing: border-box;
        .departmentItem {
            padding: 0.3rem 1.2rem;
            color: #464646;
            font-size: 14px;
            cursor: pointer;
        }
        .active {
            color: #fff;
            background-color: #1f6eaf;
        }
    }
}

.changePostListBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1170px;
    margin: 0 auto;
    color: #666;
    font-size: 14px;
    padding: 0 15px;
    box-sizing: border-box;
    .leftBox, .rightBox {
        display: flex;
        align-items: center;
        .btnItem {
            padding: 15px 0;
            margin-right: 15px;
            cursor: pointer;
        }
        .active {
            color: #1f6eaf;
        }
    }
    .rightBox {
        .btnItem {
            position: relative;
            margin-left: 15px;
            &:nth-child(1){
                &::before {
                    content: "";
                    position: absolute;
                    right: -15px;
                    width: 1px;
                    height: 20px;
                    background-color: #333;
                }
            }
        }
    }
}

.hotPostListBox {
    width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .text {
            color: #444;
            font-size: 1.6rem;
        }
        .postBtn {
            background: #1f6eaf;
            color: #fff;
            padding: 0.75rem 1.5rem;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .postList {
        display: flex;
        justify-content: space-between;
        .left {
            // width: 720px;
        }
        .right {
            width: 400px;
            padding-top: 20px;
            .information {
                position: relative;
                padding: 5px 0 10px 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                color: #444;
                cursor: pointer;
                &::before{
                    content: "";
                    position: absolute;
                    left: 5px;
                    top: 10px;
                    width: 8px;
                    height: 8px;
                    transform: rotate(45deg);
                    background-color: #1f6eaf;
                }
            }
        }
    }
}

.postItem {
    display: flex;
    padding: 24px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    &:hover {
        // background-color: #f6f6f6;
    }
    .postImg {
        border-radius: 4px;
        width: 200px;
        height: 138px;
        object-fit: cover;
        margin-right: 15px;
    }
    .postInfo {
        width: 920px;
        color: #444;
        .postTitle {
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .postText {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            font-size: 14px;
            -webkit-box-orient: vertical;
        }
        .iconBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            padding-top: 10px;
            .nameBox {
                display: flex;
                align-items: center;
                color: #666;
                .name {
                    padding-right: 15px;
                }
            }
            .icon {
                color: #999;
                i {
                    margin-left: 15px;
                }
            }
        }
    }
}
.allPostListBox {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .text {
            color: #444;
            font-size: 1.6rem;
        }
        .addBtn {
            font-size: 14px;
            color:#999;
            cursor: pointer;
        }
    }
    .postInfo {
        width: 920px;
    }
}
</style>