import CryptoJS from "../assets/js/crypto-js"
// 计算价格
export function videoprice(salePrice, saleVipPrice, saleIntegral, saleVipIntegral, item) {
    salePrice = parseFloat(salePrice);
    saleVipPrice = parseFloat(saleVipPrice);
    saleIntegral = parseInt(saleIntegral);
    saleVipIntegral = parseInt(saleVipIntegral);
    if (item && item.flag == 1) {
        var pricetext = '已购买';
        return pricetext
    } else {
        if (salePrice < 0.001 && saleVipPrice < 0.001 && saleIntegral == 0 && saleVipIntegral == 0) {
            var pricetext = '免费';
            var data = {
                pricetext,
            }
            return data
        } else {
            var pricetext
            var vipprice
            if (salePrice > 0.001) {
                if (saleIntegral > 0) {
                    pricetext = '金额:' + salePrice + '积分:' + saleIntegral;
                } else {
                    pricetext = '金额:' + salePrice;
                }
            } else { //积分课
                pricetext = '积分:' + saleIntegral;
            }

            if (saleVipPrice < 0.001 && saleVipIntegral == 0) {
                vipprice = '会员免费'
            } else {
                if (saleVipPrice > 0) {
                    if (saleVipIntegral > 0) {
                        vipprice = '会员价:' + saleVipPrice + '积分:' + saleVipIntegral
                    } else {
                        vipprice = '会员价:' + saleVipPrice;
                    }
                } else {
                    vipprice = '会员价:' + saleVipIntegral + "积分"
                }
            }
            var data = {
                pricetext,
                vipprice
            }
            return data
        }
    }
}

// 秒转时分秒
// export function secondsToTime(seconds) {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const remainingSeconds = seconds % 60;
//     return hours + ":" + minutes + ":" + remainingSeconds;
//   }

/**
 *获取当前时间
 */
export function getCurrentDate(format) {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth(); //得到月份
    var date = now.getDate(); //得到日期
    var day = now.getDay(); //得到周几
    var hour = now.getHours(); //得到小时
    var minu = now.getMinutes(); //得到分钟
    var sec = now.getSeconds(); //得到秒
    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    if (hour < 10) hour = "0" + hour;
    if (minu < 10) minu = "0" + minu;
    if (sec < 10) sec = "0" + sec;
    if (format == 1) {
        var datetime = year + "/" + month
        return datetime;
    }
    if (format == 2) {
        var datetime = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
        return datetime;
    }

}

// 日期转时间戳
export function customDateStringToTimestamp(str) {
    const [year, month, day, hour, minute, second] = str.split(/[- :]/);
    return new Date(year, month - 1, day, hour, minute, second).getTime(); // 注意月份减1
}


// AES加密
export function encrypt(content, keys) {
    if (!keys) {
        keys = 'huiyichongyikeyi'
    }
    var key = CryptoJS.enc.Utf8.parse(keys);
    var encryptResult = CryptoJS.AES.encrypt(content, key, {
        iv: CryptoJS.enc.Utf8.parse("0102030405060708"),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return String(encryptResult);
}

// AES解密
export function decrypt(content, keys) {
    if (!keys) {
        keys = 'huiyichongyikeyi'
    }
    var key = CryptoJS.enc.Utf8.parse(keys);
    var bytes = CryptoJS.AES.decrypt(content.toString(), key, {
        iv: CryptoJS.enc.Utf8.parse("0102030405060708"),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var decryptResult = bytes.toString(CryptoJS.enc.Utf8);
    //alert(decryptResult);
    //console.log(decryptResult)
    return decryptResult;
}