<!-- 会员购买页 -->
<template>
    <div>
        <div class="content">
            <div class="userInfoBox">
                <div class="userImg">
                    <img :src="HeadPortrait?imgSrc + HeadPortrait:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" alt="">
                </div>
                <div class="userName">
                    <div class="nameBox">{{ name }}</div>
                    <div class="timeBox">{{ vipTime }}</div>
                </div>
            </div>

            <div class="modelListBox">
                <div class="modelItem" v-for="(item, index) in ModelList" :key="index" @click="buyVip(item)">
                    <div class="modelTitle">
                        {{ item.vip_desc }}
                    </div>
                    <div class="modelMoney">
                        <span>￥</span>
                        <span class="rad">{{ item.vip_money }}</span>元
                    </div>
                    <div class="btn">续费</div>
                </div>
            </div>

            <div class="toastBox">
                温馨提示：<br>
                *网页端购买暂时只支持微信支付<br>
                *会员用户再次购买时长累加
            </div>

            <div class="showBox" v-show="dialogVisible"></div>
            <div class="erweimaBox" v-show="dialogVisible">
                <div class="title">请在24小时内支付</div>
                <div class="title">支付金额<span>￥{{ vip_money }}</span></div>
                <canvas ref="qrcodeCanvas"></canvas>
                <div style="color: red;padding-bottom:12px;">
                    *网页端购买暂时只支持微信支付
                </div>
                <div class="btn" @click="selectVipOrderPayType()">支付完成</div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
    name: 'QrCodeComponent',
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 会员到期时间
            vipTime: "",
            // 订单号
            orderNo: "",
            // 用户信息
            name: "",
            HeadPortrait: "",
            // 商品列表
            ModelList: [],
            // 二维码弹窗
            dialogVisible: false,
            // 订单号
            orderNo: "",
            // 订单价格
            vip_money: "",
            // 加载
            loading: ""
        }
    },
    created() {
        const that = this
        that.getUserVipTime()
        that.getUserInfo()
        that.getVipSingModelList()
    },
    methods: {
        // 生成二维码
        generateQRCode(str) {
            QRCode.toCanvas(this.$refs.qrcodeCanvas, str, function (error) {
                if (error) console.error(error)
                console.log('success!')
            })
        },
        // 获取会员信息
        getUserVipTime(){
            const that = this
            var data = {
                type: 'getUserVipTime',
            }
            this.get("/webhttp/mobileHandler", data,).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    if(result){
                        that.vipTime = result.vipTime
                    }
                } else {
                    console.log(res.data.message)
                }
            })
        },
        getUserInfo(){
            const that = this
            var data = {
                type: 'getUserInfo',
            }
            this.get("/3/mobileHandler", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.name = result.name
                    that.HeadPortrait = result.HeadPortrait
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取会员商品
        getVipSingModelList(){
            const that = this
            var data = {
                type: 'getVipSingModelList',
                vipSingType: 3
            }
            this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.ModelList = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 下单
        buyVip(item){
            const that = this
            that.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            var rndnum = that.RndNum(4);
            var now = that.gettime();
            var order_no = now + rndnum + JSON.parse(that.Cookies.get("loginInfo")).No;
            var text = {
                'order_no': order_no,
                'commodity_id': item.vip_id,
                'order_money': item.vip_money,
                'pay_discount': '0',
                'pay_intage': item.vip_integral,
                'pay_intage_money': '0',
                'pay_money': item.vip_money,
                'pay_money_no': ''
            };
            var etext = this.encrypt(JSON.stringify(text), 'wcycykbycksrcode');
            var from = '4'; //下单来源（1iOS、2安卓、3微信PC、4PC）
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                from = '3';
            }
            var data = {
                type: 'buyVip',
                from: from,
                text: etext
            }
            this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    var data = JSON.parse(that.decrypt(result,'recruitorder_app'))
                    that.getPayInfo(data.order_no, '2')
                    that.orderNo = data.order_no
                    that.vip_money = item.vip_money
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取支付信息
        getPayInfo(orderNo, payType){
            const that = this
            var data = {
                type: 'getPayInfo',
                orderNo: orderNo,
                payType: payType,
                orderType: "1",
                phtype: "WEB"
            }
            this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    var orderStr = result.orderStr
                    if(payType  == '2'){
                        var codeUrl = JSON.parse(that.decrypt(orderStr, "recruitorder_app")).codeUrl
                        that.generateQRCode(codeUrl)
                        that.dialogVisible = true
                        that.loading.close();
                    }
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 查询是否支付成功
        selectVipOrderPayType(){
            const that = this
            var data = {
                type: 'selectVipOrderPayType',
                orderNo: that.orderNo
            }
            this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    console.log(result);
                    if(result.pay_type == "1"){
                        that.$message({
                            message: '支付成功',
                            type: 'success'
                        });
                    }else {
                        that.$message({
                            message: '支付失败',
                            // type: 'warning'
                        });
                    }
                    that.dialogVisible = false
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取时间
        gettime() {
            var nowYear = new Date().getFullYear().toString()
            var nowMonth = (new Date().getMonth() + 1).toString()
            var nowDay = new Date().getDate().toString();
            var nowHours = new Date().getHours().toString(); //获取当前小时数(0-23)
            var nowMin = new Date().getMinutes().toString(); //获取当前分钟数(0-59)
            var nowSeconds = new Date().getSeconds().toString(); //获取当前秒数(0-59)
            function timeAdd0(str) {
                if (str.length <= 1) {
                    str = '0' + str;
                }
                return str
            }
            nowYear = timeAdd0(nowYear)
            nowMonth = timeAdd0(nowMonth)
            nowDay = timeAdd0(nowDay)
            nowHours = timeAdd0(nowHours)
            nowMin = timeAdd0(nowMin)
            nowSeconds = timeAdd0(nowSeconds)
            return nowYear + nowMonth + nowDay + nowHours + nowMin + nowSeconds;
        },
        // 生成随机函数
        RndNum(n) {
            var res = ''
            for (; res.length < n; res += Math.random().toString(36).substr(2).toUpperCase()) {}
            return res.substr(0, n)
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    // background-color: #f5f5f5;
    .userInfoBox {
        display: flex;
        align-items: center;
        height: 200px;
        padding: 0 50px;
        background-color: #3d4c58;
        background-image: url(../../assets/images/vippop_header_bg.png);
        background-size: 100% 100%;
        .userImg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
            border: 2px solid #fff;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .userName {
            color: #fff;
            font-size: 18px;
            line-height: 30px;
            .timeBox {
                font-size: 16px;
            }
        }
    }

    .modelListBox{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
        user-select: none;
        .modelItem {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 270px;
            height: 270px;
            margin: 0 30px 30px 0;
            box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
            border-radius: 4px;
            background-color: #fff;
            cursor: pointer;
            box-sizing: border-box;
            &:hover {
                border: 1px solid rgba(31, 110, 175, 0.85);
            }
            &:nth-child(4n){
                margin-right: 0;
            }

            .modelMoney {
                padding: 10px 0 40px ;
                .rad {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                span {
                    color: red;
                }
            }

            .btn {
                color: #fff;
                background: #1f6eaf;
                border-radius: 2px;
                padding: 5px 15px;
            }
        }
    }

    .toastBox {
        font-size: 14px;
        margin-top: 30px;
        color: red;
        padding: 20px;
        box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
    }

    .showBox {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100vw;
        height:100vh;
        background-color: rgba(0,0,0,.6);
    }
    .erweimaBox {
        position: fixed;
        top: 40%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 500px;
        padding: 30px 0;
        background-color: #fff;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
        user-select: none;
        .title {
            span {
                color: red;
            }
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 45px;
            color: #fff;
            border-radius: 5px;
            background-color: #4198e2;
            cursor: pointer;
        }
    }
}
</style>