<!-- 公共头部组件 -->
<template>
    <div class="header">
        <div class="left">
            <img class="logo" src="../assets/images/logo.png" alt="" @click="jumpRouter({router:'/home'})">
            <!-- <img class="logo" src="../public/images/logo.png" alt="" @click="getQueryString()"> -->
            <div class="tabList">
                <div :class="active == index ? 'tab active' : 'tab'"
                @click="jumpRouter(item)"
                v-on:mouseover="handleMouseover(index)"
                v-on:mouseout="handleMouseout(index)"
                v-for="(item, index) in tabList" :key="index">
                    {{ item.title }}
                    <i class="el-icon-caret-bottom" v-if="item.children"></i>
                    <div class="showBox" v-show="showActive == index" v-if="item.children">
                        <div class="jumpItem" v-for="(item1, index1) in item.children" :key="index1" @click="jumpRouter(item1)">
                            <img class="icon" :src="item1.iconSrc" alt="">
                            <div class="itemInfo">
                                <div class="itemTitle">{{ item1.title }}</div>
                                <div class="itemDefault">{{ item1.subhead }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <!-- <el-autocomplete popper-class="my-autocomplete" v-model="searchVal" :fetch-suggestions="querySearch"
                placeholder="视频、直播、医讯..." @select="handleSelect">
                <i class="el-icon-search el-input__icon" slot="suffix" @click="handleIconClick">
                </i>
                <template slot-scope="{ item }">
                    <div class="name">{{ item.value }}</div>
                </template>
            </el-autocomplete> -->
            <!-- <el-input
                @click="handleIconClick()"
                placeholder=""
                v-model="queryString">
                <i slot="prefix" class="el-input__icon el-icon-search" @click="handleIconClick()"></i>
            </el-input> -->
            <el-button  icon="el-icon-search" @click="handleIconClick()">视频、直播、医讯...</el-button>
            <el-popover
                placement="right"
                width="200"
                trigger="hover">
                <div class="changeUserInfo">
                    <div class="item iconBox" v-show="loginInfo.isLogin" @click="jumpMember()">
                        <img class="vipIcon" v-show="loginInfo.isVIP" src="../assets/images/vip02.png" alt="">
                        <img class="vipIcon" v-show="!loginInfo.isVIP" src="../assets/images/vip01.png" alt="">
                    </div>
                    <div class="item" v-show="!loginInfo.isLogin" @click="loginBoxShow()">登录</div>
                    <div class="item" @click="downloadAPP()">下载APP</div>
                    <el-popover
                        placement="left"
                        trigger="hover">
                        <img class="uqCode" src="../assets/images/code.png" alt="">
                        <div class="item" slot="reference">我的客服</div>
                    </el-popover>
                    <div class="item" v-show="loginInfo.isLogin" @click="loginOut()">退出登录</div>
                </div>
                <el-avatar class="userImg" slot="reference" size="large" :src="loginInfo.HeadPortrait"></el-avatar>
           </el-popover>
            <div class="username">{{loginInfo.name}}</div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        loginInfo: {}
    },
    data() {
        return {
            // 当前选中栏
            active: 0,
            // 搜索推荐
            restaurants: [],
            // 搜索框内容
            searchVal: "",
            // 子选项背景色显示
            showActive: 0,
            // 列表数据
            tabList: [
                {
                    title: "首页",
                    router: "/home"
                },{
                    title: "AI智答",
                    router: "/webAi"
                },{
                    title: "宠医客学苑",
                    children: [{
                        title: "线上证书课",
                        router: "/onlineTraining",
                        subhead: "巩固学习还能获得证书",
                        iconSrc: require("../assets/images/icon11.png")
                    },{
                        title: "直播课程",
                        router: "/livelist",
                        subhead: "课间互动答疑助你学好课",
                        iconSrc:  require("../assets/images/icon12.png")
                    },{
                        title: "视频课程",
                        router: "/videolist",
                        subhead: "1000+精品课立即学习",
                        iconSrc:  require("../assets/images/icon13.png")
                    },{
                        title: "合作伙伴",
                        router: "/home?text=qyhz",
                        subhead: "携手为医生提供优质学习资源",
                        iconSrc:  require("../assets/images/icon14.png")
                    },{
                        title: "线上讲师库",
                        router: "/lecturer",
                        subhead: "国内外专家讲师",
                        iconSrc:  require("../assets/images/icon17.png")
                    },],
                },{
                    title: "分级学习",
                    children: [],
                },{
                    title: "国际证书课",
                    children: [
                        {
                            title: "证书课",
                            router: "/offlineTraining",
                            subhead: "国内收个中文考核的国际认证证书",
                            iconSrc:  require("../assets/images/icon21.png")
                        },
                        {
                            title: "专题课",
                            router: "/detail/TL0000398",
                            subhead: "凌凤俊-超值软组织手术实操课程",
                            iconSrc:  require("../assets/images/icon22.png")
                        },
                    ],
                },{
                    title: "宠医圈",
                    router: "/postList",
                },{
                    title: "执兽课程",
                    router: "/trainVet",
                },
            ],
            queryString: ""
        }
    },
    created(){
        // 判断模块
        this.headerIsPosition(this.$route)
        // 获取分级学习
        this.getGradeStudyVipList()
    },
    watch: {
        $route(to, from){
            this.headerIsPosition(to)
        }
    },
    methods: {
        // 跳转页面
        jumpRouter(item){
            const that = this
            // 同地址不能跳转
            if(that.$route.path != item.router){
                // 没有子路由的可以直接跳转
                if(!item.children){
                    that.$router.push({
                        path: item.router,
                    })
                }
            }
        },
        // 跳转购买页
        jumpMember(){
            const that = this
            that.$router.push({
                path: '/member',
            })
        },
        // 打开下载APP页面
        downloadAPP(){
            window.open("https://sj.qq.com/appdetail/com.janlent.ytb")
        },
		// 分级学习
		getGradeStudyVipList(){
			const that = this
            var data = { 
                type: 'getGradeStudyVipList',
            }
            this.get("/3/gradeStudy",data).then((res)=>{
                if(res.data.code == "success"){
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].title = result[i].vip_name
                        result[i].router = "/gradeStudy/" + result[i].vip_id
                        result[i].subhead = result[i].vip_lab_name
                        result[i].iconSrc = "https://case.chongyike.com" + result[i].vip_img
                    }
                    var data = {
                        title: "分级学习",
                        children: result
                    }
					that.tabList[3] = data
                }else {
                    console.log(res.data.message)
                }
            })
		},
        // 判断当前在哪个模块
        headerIsPosition(to){
            const that = this
            if(to.path == "/home"
                || to.path == "/"
                || to.path.indexOf("/postDetail") > -1
                || to.path.indexOf("/medicalNews") > -1
                || to.path.indexOf("/search") > -1){
                that.active = 0
            }else if(to.path == "/onlineTraining"
                || to.path == "/livelist"
                || to.path == "/videolist"
                || to.path == "/lecturer"
                || to.path.indexOf("/onlineTrainingDetail") > -1
                || to.path.indexOf("/lecturerDetail") > -1
                || to.path.indexOf("/videolistInner") > -1
                || to.path.indexOf("/videoDetail") > -1
                || to.path.indexOf("/liveDetail") > -1){
                that.active = 2
            }else if(to.path.indexOf("/gradeStudy") > -1){
                that.active = 3
            }else if(to.path == "/offlineTraining"
                || to.path.indexOf("/detail") > -1){
                that.active = 4
            }else if(to.path == "/postList"
                || to.path.indexOf("/allPost") > -1
                || to.path.indexOf("/postHtmlDetail") > -1
                || to.path.indexOf("/medicalInformation") > -1
                || to.path == '/post'){
                that.active = 5
            }else if(to.path == "/trainVet"
                || to.path.indexOf("/trainVetInner") > -1
                || to.path.indexOf("/subjects") > -1
                || to.path.indexOf("/previousExamPapers") > -1
                || to.path.indexOf("/myMistake") > -1
                || to.path.indexOf("/myCollection") > -1
                || to.path.indexOf("/answer") > -1
                || to.path.indexOf("/pictureQuestion") > -1
                || to.path.indexOf("/myMistakeAnswer") > -1
                || to.path.indexOf("/testinCentre") > -1
                || to.path.indexOf("/myCollectionAnswer") > -1
                || to.path.indexOf("/trainVetVideoDetail") > -1
                || to.path.indexOf("/pictureQuestionAnswer") > -1
                || to.path.indexOf("/trainList") > -1
                ){
                that.active = 6
            }
        },
        // 打开登录框
        loginBoxShow(){
            this.$emit("loginBoxShow", 1)
        },
        loginOut(){
            this.$emit("loginOut")
        },
        // 鼠标移动标签位置显示子集合
        handleMouseover(type){
            const that = this
            that.showActive = type
        },
        // 鼠标移除消失
        handleMouseout(type){
            const that = this
            that.showActive = -1
        },
        // 搜索框方法
        querySearch(queryString, cb) {
            const that = this
            that.queryString = queryString
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        // 搜索框方法
        handleSelect(item) {
            console.log(item);
        },
        handleIconClick(ev) {
            const that = this
            that.$router.push({
                path: '/search',
            })
        }
    }
}
</script>
  
<style lang="scss" >
.changeUserInfo {
    .item {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 30px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background-color: #e5e4e4;
        }
        .vipIcon {
            width: 100px;
        }
    }
    .iconBox {
        padding: 0 10px;
    }
}

.uqCode {
    width: 200px;
}
.header {
    position: relative;
    width: 1170px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;

    .left {
        
        display: flex;
        align-items: center;

        .logo {
            width: 107px;
            height: 37.5px;
            cursor: pointer;
        }

        .tabList {
            display: flex;
            align-content: center;
            padding: 0 20px;

            .tab {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                padding: 0 12px;
                color: #666;
                cursor: pointer;
                -webkit-user-select: none;
            }

            .active {
                color: #fff;
                background-color: #4198e2;
            }
        }
    }


    .right {
        float: right;
        display: flex;
        align-items: center;

        .my-autocomplete {
            li {
                line-height: normal;
                padding: 7px;

                .name {
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .addr {
                    font-size: 12px;
                    color: #b4b4b4;
                }

                .highlighted .addr {
                    color: #ddd;
                }
            }
        }
        .userImg {
            margin-left: 20px;
            margin-right: 10px;  
            cursor: pointer;
        }
        .username {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 80px;
            font-size: 14px;
            cursor: pointer;
        }

    }


    .showBox {
        position: absolute;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        top: 80px;
        left: -20px;
        width: 100%;
        padding: 30px 20px;
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1002;
        box-shadow: 1px 1px 10px #e2e2e2;
        .jumpItem {
            width: 25%;
            display: flex;
            align-items: center;
            height: 70px;
            padding: 0 20px;
            box-sizing: border-box;
            border-radius: 5px;
            &:hover {
                background-color: #e5e4e4;
            }
            &:nth-child(n+5){
                margin-top: 20px;
            }
            .icon {
                width: 60px;
                height: 60px;
                margin-right: 5px;
                box-sizing: border-box;
            }
            .itemInfo {
                padding-left: 10px;
                .itemTitle {
                    font-size: 14px;
                    color: #333;
                }
                .itemDefault {
                    font-size: 12px;
                    color: #999;
                }
            }
        }
    }
}
</style>