<!-- 智答 -->
<template>
    <div class="content" :class="sidebarShow?'active':'active2'" :style="contentMargin?'margin-left:10vw;':''">
        <div class="titleBox" v-show="!startAnswer">
            <div class="nameBox">
                <img class="titleImg" src="../../assets/images/webAiTitleIcon.png" alt="">
                <div class="name">Hi 我是小智！</div>
            </div>
            <div class="tagline">
                宠物医生的专属智能助手，随时准备为你提供学习帮助和诊疗支持~
            </div>
        </div>
        <div class="textTitle" v-if="startAnswer && textDataBox.length > 0">
            {{ textDataBox[0].question.slice(0,20) }}
        </div>
        <div class="textBox" v-show="startAnswer" id="chatContainer">
            <div class="answerItem" v-for="(item, index) in textDataBox" :key="index">
                <div class="rightText">
                    {{ item.question }}
                </div>
                <div class="clear" style="clear:right"></div>
                <!-- 有答案或者正在思考 -->
                <div class="leftBox" v-if="item.replyText || item.textOver == 1">
                    <div class="leftImg">
                        <img class="logo" src="../../assets/images/webAiTitleIcon.png" alt="">
                        小智
                    </div>
                    <div class="leftText" v-if="item.replyText">
                        <div class="aiText" v-html="item.replyText"></div>
                        <div class="outputsBox" v-if="item.outputs.length > 0">
                            <div class="titles">以上基于{{item.outputs.length }}份相关资料作为参考</div>
                            <div class="outputsItem" v-show="item.extendOff || index1 < 3" v-for="(item1, index1) in item.outputs" :key="index1" @click="jumpOutputsItem(item1)">
                                <span :class="item1.dataset_name=='课程'?'dataName color1':'dataName color2'">{{ item1.dataset_name }}</span>
                                {{ item1.title }}
                            </div>
                            <div class="btn" v-show="item.outputs.length > 3" @click="item.extendOff = !item.extendOff">
                                <img class="extendBtn" :style="item.extendOff?'':'transform: rotate(180deg)'"  src="../../assets/images/extendBtn.png" alt="">
                                <span v-show="!item.extendOff">展开</span>
                                <span v-show="item.extendOff">收起</span>
                            </div>
                        </div>
                        <div class="btnList" v-show="item.textOver == 2">
                            <div class="btnItem" @click="copyData(item)"><i class="el-icon-copy-document"></i> 复制</div>
                            <div class="btnItem" @click="answersLikeOrTap(item, 1)">
                                <img class="icon" :src="item.action == 1?require('../../assets/images/likeIcon1.png'):require('../../assets/images/likeIcon.png')" alt="">赞
                            </div>
                            <div class="btnItem" @click="answersLikeOrTap(item, 2)">
                                <img class="icon bottom" :src="item.action == 2?require('../../assets/images/likeIcon1.png'):require('../../assets/images/likeIcon.png')" alt="">踩
                            </div>
                        </div>
                    </div>
                    <div class="leftText" style="padding-left:20px; display: flex;" v-else-if="item.textOver == 1">
                        思考中
                        <div class="loading-container">
                            <div class="dot dot-1"></div>
                            <div class="dot dot-2"></div>
                            <div class="dot dot-3"></div>
                        </div>
                    </div>
                </div>
                <div class="clear" style="clear:left"></div>
                <div class="videoList" v-show="index == textDataBox.length-1 && item.relatedreCommended.length > 0">
                    <div class="listTitle">推荐视频</div>
                    <div class="videoDataList">
                        <div class="videoItem" @click="jumpOutputsItem(item1)" v-for="(item1, index1) in item.relatedreCommended" :key="index1">
                            <img class="videoImg" :src="item1.list_image?imgSrc+item1.list_image:require('../../assets/images/default.jpg')" alt="">
                            <div class="videoName">{{ item1.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inputBox">
            <textarea class="inputBtn" v-model="textMsg" placeholder="有什么问题尽管问我，Shift+Enter换行"></textarea>
            <div class="btnBox">
                <div class="btnItem" @click="changeConnectedInternet()">
                    <img :src="require('../../assets/images/btnIcon1.png')" alt="">
                    深度思考
                </div>
                <div class="btnItem" @click="changeConnectedInternet(1)">
                    <img :src="require('../../assets/images/btnIcon2.png')" alt="">
                    联网搜索
                </div>
            </div>
            <img class="sendBtn" :src="Answering?require('../../assets/images/sendBtn2.png'):require('../../assets/images/sendBtn.png')" alt="" @click="submitQuestion()">
        </div>
        <div class="toast" :class="sidebarShow?'active3':'active4'" :style="contentMargin?'margin-left:5.5VW;':''">
            结果由AI大模型生成，不属于诊断行为，仅供参考;使用者应自行鉴别并对使用结果负责，禁止从事违法活动。
        </div>


        <!-- 侧边栏 -->
        <div class="sidebarBox" v-show="!sidebarShow">
            <div class="iconBox">
                <img class="icon logo" src="../../assets/images/logoIcon.png" alt="" @click="jumpIndex()">
                <img class="icon" src="../../assets/images/webAiIcon1.png" alt="" @click="zhankai()">
                <img class="icon" src="../../assets/images/webAiIcon2.png" alt="" @click="createdTextBox()">
            </div>
            <div class="iconBox">
                <el-popover
                    placement="top-start"
                    trigger="hover">
                    <img style="width: 200px;" src="../../assets/images/APPerweima.png" alt="">
                    <div class="text" style=" display: flex;justify-content: center;width: 200px;text-align: center;">
                        获取更多课程资讯<br>
                        移动端使用智答<br>
                        请扫码下载宠医客APP
                    </div>
                    <img slot="reference" class="icon phone" src="../../assets/images/webAiIcon3.png" alt="">
                </el-popover>
                <img class="icon userImg" :src="loginInfo.HeadPortrait?loginInfo.HeadPortrait:require('../../assets/images/webAiUserIcon.png')" alt="">
            </div>
        </div>
        <div class="sidebarBox2" v-show="sidebarShow">
            <div class="topBox">
                <div class="sidebarTitleBox">
                    <div class="sidebarTitle">宠医客智答</div>
                    <img class="offBtn" src="../../assets/images/webAiIcon4.png" alt="" @click="zhankai()">
                </div>
                <div class="addBtnBox" @click="createdTextBox()">
                    <img class="addImg" src="../../assets/images/webAiIcon5.png" alt="">
                    <div class="addTitle">开启新对话</div>
                </div>
            </div>
            <div class="historyList">
                <div class="historyListItem" v-for="(item, index) in historyDataList" :key="index">
                    <div class="historyListItemTitle">
                        {{ item.name }}
                    </div>
                    <div :class="item.name=='今天'?'historyItem bottom':'historyItem'" 
                        :style="item1.conversation_id == conversationId?'background-color: #dcebfe;':''"
                        v-show="item.cardList.length > 0"
                        v-for="(item1, index1) in item.cardList" :key="index1"
                        @click="getConversationHistory(item1)">
                        <div class="text">
                            {{ item1.question }}
                        </div>
                        <input :ref="'myInput'+index+index1" type="text" v-show="item1.inpOff" class="editInp" v-model="item1.question" @blur="updateConversationName(item1)">
                        <img class="openBtn" @click.stop="item1.editOff = true" src="../../assets/images/webAiIcon6.png" alt="" >
                        <div class="bgi" v-show="item1.editOff" @click.stop="item1.editOff = false"></div>
                        <div class="bgi" v-show="item1.inpOff" @click.stop="item1.inpOff = false"></div>
                        <div class="deleteBox" v-show="item1.editOff">
                            <div class="btnItem" @click.stop="onEdit(item1, index+''+index1)">
                                <img class="btnIcon" src="../../assets/images/editIcon.png" alt="">
                                重命名
                            </div>
                            <div class="btnItem" @click.stop="deleteConversationHistory(item1)">
                                <img class="btnIcon" src="../../assets/images/deleteIcon.png" alt="">
                                删除
                            </div>
                        </div>
                    </div>
                    <div class="" v-show="item.cardList.length == 0">
                        暂无搜索
                    </div>
                </div>
            </div>
            <div class="btmBox" style="height: 100px;padding-top: 20px;">
                <div class="appBtn" @click="downloadAPP()">
                    <img class="logoIcon" src="../../assets/images/logoIcon.png" alt="">
                    下载宠医客APP
                </div>
            </div>
            <div class="btmBox">
                <img class="userImg" :src="loginInfo.HeadPortrait?loginInfo.HeadPortrait:require('../../assets/images/webAiUserIcon.png')" alt="">
                {{ loginInfo.name }}
            </div>
        </div>
    </div>
</template>

<script>
    import Cookies from 'js-cookie'
    import hex_md5 from "../../assets/js/md5"
    import { marked } from 'marked';
    export default {
        data() {
            return {
			    imgSrc: "https://case.chongyike.com",
                textMsg: "",
                // 流式数据id
                conversationId: "",
                conversationType: "2",
                // 侧边栏是否展开
                sidebarShow: false,
                // 历史记录
                historyDataList: [],
                // 是否开始问答
                startAnswer: false,
                // 对话数据列表
                textDataBox: [],
                // 是否在回答中
                Answering: false,
                loginInfo: {},
                // 字符串长度
                markedTextlength: 0,
                // 聊天窗是否有外距离
                contentMargin: false,
                // 保存当前问答的ID
                atPresentConversationID: "",
                // 是否联网搜索
                connectedInternet: 0
            };
        },
        created() {
            const that = this
            if(sessionStorage.getItem("AiQuestion")){
                that.textMsg = JSON.parse(sessionStorage.getItem("AiQuestion")).question
                that.submitQuestion()
                // sessionStorage.removeItem("AiQuestion")
            }else if(sessionStorage.getItem("atPresentConversationID")){
                var text = JSON.parse(sessionStorage.getItem("atPresentConversationID"))
                that.sidebarShow = text.sidebarShow
                var data = {
                    conversation_id: text.atPresentConversationID
                }
                that.getConversationHistory(data)
                // sessionStorage.removeItem("atPresentConversationID")
            }
            that.getConversationList()
            window.addEventListener("keydown", this.keydownClick)
            // 已经有登录信息则直接取
            if(that.Cookies.get("loginInfo")){
                that.loginInfo = JSON.parse(that.Cookies.get("loginInfo"))
            }
            //禁止鼠标右击
            document.oncontextmenu = function () {
                event.returnValue = false;
            };
            //禁用开发者工具F12
            document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
                let e = event || window.event || arguments.callee.caller.arguments[0];
                if (e && e.keyCode == 123) {
                    e.returnValue = false;
                    return false;
                }
            };
            let userAgent = navigator.userAgent;
            if (userAgent.indexOf("Firefox") > -1) {
                let checkStatus;
                let devtools = /./;
                devtools.toString = function () {
                    checkStatus = "on";
                };
                setInterval(function () {
                    checkStatus = "off";
                    console.log(devtools);
                    console.log(checkStatus);
                    console.clear();
                    if (checkStatus === "on") {
                        let target = "";
                        try {
                            window.open("about:blank", (target = "_self"));
                        } catch (err) {
                            let a = document.createElement("button");
                            a.onclick = function () {
                                window.open("about:blank", (target = "_self"));
                            };
                            a.click();
                        }
                    }
                }, 200);
            } else {
                //禁用控制台
                let ConsoleManager = {
                    onOpen: function () {
                        alert("Console is opened");
                    },
                    onClose: function () {
                        alert("Console is closed");
                    },
                    init: function () {
                        let self = this;
                        let x = document.createElement("div");
                        let isOpening = false,
                            isOpened = false;
                        Object.defineProperty(x, "id", {
                            get: function () {
                                if (!isOpening) {
                                    self.onOpen();
                                    isOpening = true;
                                }
                                isOpened = true;
                                return true;
                            }
                        });
                        setInterval(function () {
                            isOpened = false;
                            console.info(x);
                            console.clear();
                            if (!isOpened && isOpening) {
                                self.onClose();
                                isOpening = false;
                            }
                        }, 200);
                    }
                };
                ConsoleManager.onOpen = function () {
                    //打开控制台，跳转
                    let target = "";
                    try {
                        window.open("about:blank", (target = "_self"));
                    } catch (err) {
                        let a = document.createElement("button");
                        a.onclick = function () {
                            window.open("about:blank", (target = "_self"));
                        };
                        a.click();
                    }
                };
                ConsoleManager.onClose = function () {
                    alert("Console is closed!!!!!");
                };
                ConsoleManager.init();
            }
        },
        watch: {
            // 回答时滚动条保持在底部
            markedTextlength() {
                this.$nextTick(() => {
                    var container = this.$el.querySelector("#chatContainer");
                    container.scrollTop = container.scrollHeight;
                })
            // document.getElementById('chatContainer').scrollTop = document.getElementById('chatContainer').scrollHeight+150;
            },
            // 监听侧边栏
            sidebarShow(newVal ){
                setTimeout(() => {
                    this.contentMargin = newVal
                }, 150);
            },
            // 正在回答中的问题在切换之后自动停止
            startAnswer(newVal){
                if(newVal == false && this.Answering){
                    this.stopAnswerts()
                }
            },
        },
        methods: {
            // handleKeydown(event) {
            //     //如果键盘敲击f12就阻止f12打开，然后弹框提醒
            //     if (event.key === "F12") {
            //         event.preventDefault(); // 阻止F12的默认行为
            //         this.$message("检测非法调试，F12被管理员禁用");
            //     }
            // },
            // 跳转首页
            jumpIndex(){
                this.startAnswer = false
                this.$router.push({
                    path: '/home',
                })
            },
            // 跳转视频列表
            jumpVideolistInner(item){
                return false
                this.$router.push({
                    path: '/videolistInner/' + item.id,
                })
            },
            // 参考资料跳转
            jumpOutputsItem(item){
                const that = this
                if(item.data_type == 15){
                    this.stopAnswerts()
                    var data = {
                        dataType: 0,
                        No: item.data_no,
                        name: item.title,
                        classType: 1,
                    }
                    that.$router.push({
                        path: '/videoDetail',
                        query: {
                            text: that.encrypt(JSON.stringify(data))
                        }
                    })
                }else if (item.data_type == 3){
                    this.stopAnswerts()
                    that.$router.push({
                        path: '/postDetail/' + item.data_no,
                    })
                }
                var data = {
                    atPresentConversationID: that.atPresentConversationID,
                    sidebarShow: that.sidebarShow
                }
                sessionStorage.setItem("atPresentConversationID", JSON.stringify(data))
            },
            // 打开下载APP页面
            downloadAPP(){
                window.open("https://sj.qq.com/appdetail/com.janlent.ytb")
            },
            // 开启新对话
            createdTextBox(){
                const that = this
                that.startAnswer = false
                that.textDataBox = []
                that.conversationId = ""
            },
            // 点击重命名
            onEdit(item, index){
                item.inpOff = true
                item.editOff = false
                var a = 'myInput'+index
                this.$nextTick(()=>{
                    if(this.$refs[a]){
                        this.$refs[a][0].focus();
                    }
                })
            },
            // 删除历史记录
            deleteConversationHistory(item){
                const that = this
                item.inpOff = false
                var data = {
                    type: 'deleteConversationHistory',
                    conversationId: item.conversation_id,
                }
                this.get("/ai/handler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.getConversationList()
                        that.$message({
                            showClose: true,
                            message: res.data.message,
                        });
                    }
                })
            },
            // 重命名方法
            updateConversationName(item){
                const that = this
                item.inpOff = false
                var data = {
                    type: 'updateConversationName',
                    conversationId: item.conversation_id,
                    newName: item.question
                }
                this.get("/ai/handler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.$message({
                            showClose: true,
                            message: res.data.message,
                        });
                    }
                })
            },
            // 展开侧边栏
            zhankai(){
                const that = this
                that.sidebarShow = !that.sidebarShow
            },
            // 键盘事件
            keydownClick(e){
                const that = this 
                var keyCode = e.keyCode
                if(keyCode == "13" && that.textMsg.trim().length > 0){
                    that.submitQuestion()
                }
            },
            // 打开历史提问
            getConversationHistory(item){
                const that = this
                var data = {
                    type: 'getConversationHistory',
                    conversationId: item.conversation_id
                }
                this.get("/ai/handler", data).then((res) => {
                    if (res.data.code == "success") {
                        if(sessionStorage.getItem("atPresentConversationID")){
                            sessionStorage.removeItem("atPresentConversationID")
                        }
                        document.title = item.question.slice(0,10) + "- 智答"
                        var result = res.data.result
                        that.textMsg = ""
                        that.atPresentConversationID = item.conversation_id// 保存当前问答ID
                        result.reverse()
                        that.markedTextlength++ // 保持滚动条在最低端
                        that.startAnswer = true
                        that.conversationId = item.conversation_id
                        for (let i = 0; i < result.length; i++) {
                            if(result[i].answer){
                                result[i].replyText = marked(JSON.parse(result[i].answer).content)
                                result[i].outputs = JSON.parse(result[i].answer).outputs
                                result[i].textOver = 2
                                result[i].relatedreCommended = JSON.parse(result[i].answer).relatedreCommended
                            }else {
                                result[i].replyText = ""
                                result[i].outputs = ""
                                result[i].relatedreCommended = []
                                result[i].textOver = 0
                            }
                            result[i].extendOff = false
                        }
                        that.textDataBox = result
                    }
                })
            },
            // 浏览记录
            aiPageBrowsingRecord(){
                const that = this
                var data = {
                    type: 'aiPageBrowsingRecord',
                    accessType: 7, // 7是官网
                    pageName: "vetChat1_1"
                }
                this.get("/ai/handler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        
                    }
                })
            },
            // 切换问题模式 深度思考 联网搜索
            changeConnectedInternet(){
                const that = this
                that.$message({
                    message: "敬请期待",
                });
                return false
                if(that.connectedInternet == 1){
                    that.connectedInternet = 0
                }else {
                    that.connectedInternet = 1
                }
            },
            // 发送问题
            submitQuestion(){
                const that = this
                // 是否正在回答
                if(that.Answering){
                    that.stopAnswerts()
                }else {
                    if(that.textMsg.trim() == ""){
                        that.$message({
                            message: "请输入问题",
                        });
                        return false
                    }
                    // 开始问答
                    that.startAnswer = true
                    that.Answering = true
                    var uploadFile = new FormData();
                    // 数组添加新提问
                    var data = {
                        question: that.textMsg,
                        replyText: "",
                        outputs: [],
                        extendOff: false,
                        relatedreCommended: [],
                    }
                    that.textDataBox.push(data)
                    uploadFile.append("question", that.textMsg);
                    var data = {
                        type: 'submitQuestion',
                        conversationId: that.conversationId,
                        conversationType: that.conversationType,
                        accessType: 7, // 7是官网
                        connectedInternet: that.connectedInternet
                    }
                    this.post("/ai/handler", data, uploadFile).then((res) => {
                        if (res.data.code == "success") {
                            var result = res.data.result
                            document.title = that.textMsg.slice(0,10) + "- 智答"
                            if(sessionStorage.getItem("AiQuestion")){
                                sessionStorage.removeItem("AiQuestion")
                            }
                            that.markedTextlength+=1
                            that.sessionId = result.session_id
                            // 建立流式回复
                            that.connectToServer(result.session_id)
                            that.textDataBox[that.textDataBox.length-1].textOver = 1
                            // 清空输入框
                            that.textMsg = ""
                        }else {
                            that.Answering = false
                            that.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'error'
                            });
                        }
                    })
                }
            },
            // 建立流式回复
            connectToServer(sessionId){
                const that = this
				let url = 'http://192.168.18.223:83/ai/handler?type=getAnswers&sessionId='+ sessionId + that.getConfigText()
				    url = 'https://appinterface.chongyike.com/ai/handler?type=getAnswers&sessionId='+ sessionId + that.getConfigText()
				// 建立连接
				let sourceInfo = new EventSource(url);
                // 保存原文参数
                var markedText = ""
                that.textDataBox[that.textDataBox.length-1].replyText = ""
				sourceInfo.onmessage = function(e){
                    var data = JSON.parse(e.data)
                    markedText += data.content
                    that.markedTextlength = markedText.length
                    that.textDataBox[that.textDataBox.length-1].replyText = marked(markedText)
					if (data.finishReason == 1) {
                        console.log(data)
                        that.conversationId = data.conversationID
                        that.atPresentConversationID = data.conversationID // 保存当前问答ID
                        that.textDataBox[that.textDataBox.length-1].outputs = data.outputs
                        that.textDataBox[that.textDataBox.length-1].textOver = 2
                        that.textDataBox[that.textDataBox.length-1].relatedreCommended = data.relatedreCommended
                        that.markedTextlength = 0
                        that.getConversationList()
                    }
				}
				sourceInfo.addEventListener('error', function(e) {
					console.log('内容已断开');
                    that.Answering = false
					sourceInfo.close();
				}, false);
            },
            // 停止流式回复
            stopAnswerts(){
                const that = this
                var data = {
                    type: 'stopAnswerts',
					sessionId: that.sessionId
                }
                this.get("/ai/handler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.Answering = false
                        if(that.textDataBox[that.textDataBox.length-1].replyText == "思考中......"){
                            that.textDataBox[that.textDataBox.length-1].replyText = ""
                        }
                        that.textDataBox[that.textDataBox.length-1].textOver = 2
                    }
                })
            },
            // 复制
            copyData(data) {
                const that = this
                data = that.stripHTML(data.replyText)
                var input = document.createElement('input') // 创建input元素
                document.body.appendChild(input) // 将input元素挂到body上
                input.setAttribute('value', data) //设置input的value，也就是想要复制的内容
                input.select() // 设置当前input元素被选中
                document.execCommand("Copy") //执行复制命令
                document.body.removeChild(input) // 清除input元素
                this.$message.success("复制成功")
            },
            // 富文本转文本
            stripHTML(html) {
                var tmp = document.createElement("DIV");
                tmp.innerHTML = html;
                return tmp.textContent || tmp.innerText || "";
            },
            // 点赞或踩
            answersLikeOrTap(item,type){
                const that = this
                var action = type
                if(item.action == type){
                    action = 0
                }
                var data = {
                    type: 'answersLikeOrTap',
                    action: action,
                    sessionId: item.session_id
                }
                this.get("/ai/handler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        item.action = action
                    }
                })
            },
            // 获取历史记录
			getConversationList() {
				const that = this;
                var data = {
                    type: 'getConversationList',
                }
                this.get("/ai/handler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.aiPageBrowsingRecord()
                        that.historyDataList = that.classifyData(result)
                    }
                })
			},
            getConfigText(){
                const that = this
                // 获取当前时间
                var time = (new Date()).getTime();
                var data = {
                    // 接口来源 安卓/IOS/WEB
                    client: "WEB",
                    // 版本号
                    device: "",
                    // 时间戳
                    requestTime: time,
                    // 版本号
                    versionCode: 251,
                }
                if(Cookies.get('device')){
                    data.device = Cookies.get('device')
                }else {
                    var lsnms = num();
                    var strs = time + "" + lsnms;
                    var device = hex_md5(strs).toUpperCase();
                    Cookies.set('device', device, {  expires: 7 })
                    data.device = device
                }
                data.sign = that.serSign(data)
                var texts = ""
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        texts +=  "&" +  key + "=" + data[key]
                    }
                }
                return texts
            },
            serSign(data){
                var text = ""
                for (let key in data) {
                    text = text + key + '=' + data[key] + "&"
                }
                return hex_md5(text + 'key=huiyichongyike')
            },
            // 划分时间
			classifyData(data) {
				const that = this;
				const today = new Date();
				const todayStartOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate())
					.getTime(); // 今天0点的时间戳
				const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
				const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

				const result = [{
						name: '今天',
						cardList: []
					},
					{
						name: '近7天',
						cardList: []
					},
					{
						name: '近30天',
						cardList: []
					},
					{
						name: '更早',
						cardList: []
					}
				];

				data.forEach(item => {
					const updateTime = new Date(item.update_time);
					item.formatted_time = that.formatTime(updateTime, today);
                    item.editOff = false
                    item.inpOff = false
					if (updateTime >= todayStartOfDay) {
						result[0].cardList.push(item); // 今天
					} else if (updateTime >= sevenDaysAgo.getTime()) {
						result[1].cardList.push(item); // 近7天
					} else if (updateTime >= thirtyDaysAgo.getTime()) {
						result[2].cardList.push(item); // 近30天
					} else {
						result[3].cardList.push(item); // 更早
					}
				});

				return result;
			},
			formatTime(date, today) {
				const that = this;
				const diff = Math.floor((today - date) / (24 * 60 * 60 * 1000));
				if (diff === 0) {
					return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`; // 今天
				} else if (diff < 7) {
					return that.getDayOfWeek(date); // 近7天
				} else if (diff < 30) {
					return `${date.getMonth() + 1}/${date.getDate()}`; // 近30天
				} else {
					return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`; // 更早
				}
			},
			getDayOfWeek(date) {
				const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
				return days[date.getDay()];
			},
        }
    }
</script>

<style lang="scss" scoped>
.content {
    width: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    border: none;

    .titleBox {
        padding-top: 22vh;
        .nameBox {
            display: flex;
            align-items: center;
            justify-content: center;
            .titleImg {
                width: 80px;
            }
            .name {
                font-size: 30px;
                padding-left: 35px;
            }
        }
        .tagline {
            padding-top: 30px;
            font-size: 18px;
            color: #777777;
        }
    }

    .textTitle {
        position: relative;
        font-size: 15px;
        color: #333;
        margin: 8vh 0 4vh;
        font-weight: 600;
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -5px;
            width: 100px;
            height: 2px;
            background-color: #333;
            transform: translateX(-50%);
        }
    }

    .textBox {
        overflow-x: hidden;
        overflow-y: scroll;
        width: 100%;
        height: 60vh;
        padding: 20px 0 0;
        box-sizing: border-box;
        line-height: 25px;
        .answerItem {
            width: 50vw;
            margin: 0 auto;
        }
        .rightText {
            float: right;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 20px;
            margin-left: 30px;
            font-size: 13px;
            background-color: #eef8ff;
        }
        .leftBox {
            float: left;
            .leftImg {
                width: 50vw;
                display: flex;
                align-items: center;
                color: #333;
                font-size: 18px;
                position: relative;
                left: -20px;
                margin-bottom: 10px;
                .logo {
                    width: 30px;
                    height: 30px;
                    padding: 5px;
                    border-radius: 50%;
                    // border: 1px solid #636363;
                    margin-right: 10px;
                }
            }
            .leftText {
                box-sizing: border-box;
                border-radius: 10px;
                margin-right: 30px;
                font-size: 13px;
                .outputsBox {
                    padding: 10px 20px;
                    margin-top: 10px;
                    color: #4b96ef;
                    border-left: 2px solid #c5e9ff;
                    .outputsItem {
                        width: 30vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        height: 30px;
                        line-height: 30px;
                        font-size: 13px;
                        cursor: pointer;
                        .dataName {
                            font-size: 12px;
                            padding: 2px 4px;
                            margin-right: 5px;
                            border-radius: 5px;
                            background-color: #ffefce;
                        }
                        .color1 {
                            color: #ff7b00;
                        }
                        .color2 {
                            color: #30c400;
                        }
                    }
                    .btn {
                        width: 100%;
                        text-align: center;
                        user-select: none;
                        cursor: pointer;
                        .extendBtn {
                            width: 18px;
                            padding: 0 5px;
                        }
                    }
                }

                .btnList {
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                    .btnItem {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        padding: 0px 10px;
                        color: #4488cc;
                        border-radius: 100px;
                        margin-right: 10px;
                        border: 1px solid #4488cc;
                        cursor: pointer;
                        .icon {
                            width: 15px;
                        }
                        .bottom {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .videoList {
            // padding-left: 20px;
            .listTitle {
                position: relative;
                padding-left: 10px;
                font-size: 18px;
                font-weight: 600;
                color: #000;
                padding-bottom: 5px;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 3px;
                    height: 20px;
                    background: linear-gradient(#659cff,#659cff,#fff);
                }
            }
            .videoDataList {
                overflow-x: scroll;
                display: flex;
                align-items: center;
                user-select: none;
                .videoItem {
                    margin-right: 15px;
                    cursor: pointer;
                    &:nth-last-child(1){
                        margin-right: 0;
                    }
                    .videoImg {
                        width: 130px;
                        height: 90px;
                        border-radius: 5px;
                    }
                    .videoName {
                        overflow: hidden;
                        text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
                        width: 130px;
                        height: 50px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .inputBox {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50vw;
        height: 15vh;
        margin-top: 3.5vh;
        background-color: #f3f4f6;
        border-radius: 20px;
        .inputBtn {
            width: 48vw;
            height: 15vh;
            font-size: 24px;
            padding: 20px 0;
            box-sizing: border-box;
            border: none;
            user-select: none;
            outline: none;
            resize: none;
            font-size: 18px;
            background-color: rgba(0,0,0,0);
        }
        .sendBtn {
            position: absolute;
            right: 30px;
            bottom: 20px;
            width: 30px;
            border-radius: 50%;
            cursor: pointer;
        }
        .btnBox {
            position: absolute;
            left: 20px;
            bottom: 15px;
            display: flex;
            .btnItem {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #a2a2a2;
                margin-right: 10px;
                border: 1px solid #a2a2a2;
                border-radius: 100px;
                padding: 3px 10px;
                cursor: pointer;
                user-select: none;
                img {
                    width: 16px;
                    margin-right: 5px;
                }
            }
        }
    }

    .toast {
        position: fixed;
        text-align: center;
        left: 50%;
        bottom: 10px;
        font-size: 12px;
        width: 100vw;
        color: #aaa;
        transform: translateX(-50%);
    }

    .sidebarBox, .sidebarBox2 {
        position: fixed;
        left: 0;
        top: 0;
        width: 60px;
        height: 100vh;
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(#ebf6ff,#f1f9ff, #f9fcff,#fff);

        .iconBox {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            .icon {
                width: 30px;
                margin: 15px 0;
                cursor: pointer;
            }
            .logo {
                width: 40px;
            }
            .phone {
                width: 20px;
            }
            .userImg {
                width: 40px;
                border-radius: 50%;
            }
        }
    }

    .sidebarBox2 {
        width: 240px;
        padding: 20px 0;
        box-sizing: border-box;
        background-color: #f2f3ff;
        .topBox {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .sidebarTitleBox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 30px;
                box-sizing: border-box;
                .sidebarTitle {
                    font-size: 22px;
                    font-weight: 600;
                }
                .offBtn {
                    width: 26px;
                    cursor: pointer;
                }
            }
            .addBtnBox {
                display: flex;
                align-items: center;
                padding: 20px 20px 10px;
                box-sizing: border-box;
                width: 100%;
                cursor: pointer;
                .addImg {
                    width: 35px;
                    padding-right: 5px;
                }
                .addTitle {
                    font-size: 18px;
                    color:#5ea4f7;
                }
            }
        }

        .historyList {
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0px 20px 20px;
            min-height: 75vh;
            box-sizing: border-box;
            .historyListItem {
                padding: 10px 0;         
                .historyListItemTitle {
                    font-size: 18px;
                    // font-weight: 600;
                }
                .historyItem {
                    position: relative;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    // border: 1px solid #f2f3ff;
                    cursor: pointer;
                    &:hover {
                        border-radius: 5px;
                        // border: 1px solid #d5dfff;
                        background-color: #eff6ff;
                        .openBtn {
                            display: block;
                        }
                    }
                    .text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        height: 28px;
                        line-height: 28px;
                        padding: 0 5px;
                        padding-right: 30px;
                        font-size: 14px;
                        box-sizing: border-box;
                    }
                    .editInp {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 30px;
                        padding: 0 5px;
                        z-index: 4;
                        box-sizing: border-box;
                        background-color: #fff;
                        border: none;
                        user-select: none;
                        outline: none;
                    }
                    .openBtn {
                        position: absolute;
                        display: none;
                        top: 3px;
                        right: 10px;
                        width: 20px;
                    }
                    .bgi {
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100vw;
                        height: 100vh;
                        z-index: 2;
                        background-color: rgba(0,0,0,.0);
                    }
                    .deleteBox {
                        position: absolute;
                        right:0;
                        bottom: 0;
                        padding: 10px;
                        transform: translateY(-50%);
                        border-radius: 5px;
                        box-shadow: 0 0 10px #ccc;
                        background-color: #fff;
                        z-index: 3;
                        .btnItem {
                            display: flex;
                            align-items: center;
                            color: #ff3642;
                            &:nth-child(1){
                                color: #636363;
                                margin-bottom: 10px;
                            }
                            .btnIcon {
                                width: 20px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .bottom {
                    .deleteBox {
                        transform: translateY(100%);
                    }
                }
            }
        }

        .btmBox {
            display: flex;
            align-items: center;
            // justify-content: center;
            // width: 300px;
            width: 100%;
            padding: 20px 15px 20px;
            font-size: 18px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .userImg {
                width: 35px;
                border-radius: 50%;
                margin-right: 5px;
            }
            .appBtn {
                display: flex;
                align-items: center;
                padding: 5px 8px;
                border-radius: 10px;
                font-size: 15px;
                color: #333;
                border: 1px solid #5ea4f7;
                user-select: none;
                cursor: pointer;
                .logoIcon {
                    width: 25px;
                    padding-right: 10px;
                }
            }
        }
    }
}


.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
}

.dot {
    width: 4px;
    height: 4px;
    margin: 0 2px;
    background-color: #007bff;
    border-radius: 50%;
    animation: blink 1s linear infinite;
}

.dot-1 {
    animation-delay: 0s;
}

.dot-2 {
    animation-delay: 0.33s;
}

.dot-3 {
    animation-delay: 0.66s;
}

@keyframes blink {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

// 修改滚动条样式
::-webkit-scrollbar {
	width: 8px; /* 垂直滚动条的宽度 */
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的背景颜色 */
  border-radius: 10px; /* 滚动条轨道的圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* 滚动条滑块的颜色 */
  border-radius: 10px; /* 滚动条滑块的圆角 */
}

/* 鼠标悬停在滑块上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1; /* 鼠标悬停时的颜色 */
}


// 动画效果
.active {
  animation: scaleUp .2s;
}

.active2 {
  animation: scaleUp2 .2s;
}
@keyframes scaleUp {
  from { margin-left:0; }
  to { margin-left:10vw; }
  
}
@keyframes scaleUp2 {
  from { margin-left:10vw; }
  to { margin-left:0; }
}

.active3 {
  animation: scaleUp3 .2s;
}

.active4 {
  animation: scaleUp4 .2s;
}


@keyframes scaleUp3 {
  from { margin-left:0; }
  to { margin-left: 5.5VW; }
  
}
@keyframes scaleUp4 {
  from { margin-left:5.5VW; }
  to { margin-left:0; }
}

</style>