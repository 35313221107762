<!-- 专题课 -->
<template>
    <div class="content">
        <div class="title">
            {{ title }}
        </div>
        <div class="time">{{ time }}</div>
        <div class="details" v-html="details"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            title: "",
            details: "",
            // 富文本no
            dataNo: "",
            // 日期
            time: "",
        }
    },
    created(){
        const that = this
        that.dataNo = this.$route.params.id
        that.getDataInfo()
    },
    methods: {
        getDataInfo(){
            const that = this
            var data = {
                type: 'getCertificateDetailInfoById',
                certificateCourseId: that.dataNo,
                dataType: 90,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.title = result.title
                    that.time = result.mader_time
                    that.details = result.introduce_h5.replace(
							/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
								return `<img src='${ p1.indexOf('https') > -1 ? p1 : that.imgSrc + p1}'  style='max-width: 100%;'/>`
							})
                            
                } 
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0;
    background-color: #f5f5f5;
}
.title {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    padding: 1rem 10px;
    margin: 0 15%;
    color: #444;
    background: #fff;
}
.time {
    text-align: right;
    color: #999;
    font-size: 16px;
    background: #fff;
    margin: 0 15%;
    padding: 10px 20px;
}
.details {
    margin: 0 15%;
    background-color: #fff;
}
</style>