<template>
    <div id="app">
        <div class="liveDetailtitle">
            <div class="textBox">
                <div class="text" @click="pause">
                    {{ liveInfo.roomName }}<span v-if="liveInfo.teacher">-{{ liveInfo.teacher }}</span>
                </div>
                <div class="titleBox" @click="quitChatRoom()">
                    聊天答疑
                </div>
            </div>
            <div class="bgi"></div>
        </div>
        <div class="content">
            <div class="contentBox">
                <vue-aliplayer-v2
                    v-if="ready"
                    v-show="videoShow == 1"
                    class="playBox"
                    :source="source"
                    ref="VueAliplayerV2"
                    @ready="readyFun"
                    @error="errorFun"
                    :options="options"
                    />
                <!-- <div class="playShowBox" v-if="!buyType && videoShow != 2" @click="changePlayStatus()">
                    <img class="playBtn" v-show="!playStatus" src="../../assets/images/play.png" alt="">
                </div> -->
                <div class="showImg" v-if="show">
                    <img class="bannerImg" v-if="liveInfo.smallmap" :src="imgSrc + liveInfo.smallmap" alt="">
                    <div class="toastBox" v-if="videoShow == 2">
                        <div class="startToast">
                            直播未开始
                        </div>
                        <el-statistic
                            ref="statistic"
                            @finish="reloadWeb()"
                            format="HH小时mm分钟ss秒"
                            :value="countDownNum"
                            time-indices
                            :value-style="{
                                'color': '#ff0000',
                                'font-size': '16px',}">
                            <template slot="prefix">
                                <span style="color: #f00;">倒计时: </span>
                            </template>
                        </el-statistic>
                        <div class="teacherBox" v-if="liveInfo.teacher">
                            主讲老师: {{ liveInfo.teacher }}
                        </div>
                        <div class="btnBox">
                            <div class="btnItem" @click="reloadWeb()">
                                刷新
                            </div>
                            <div class="btnItem" v-show="saleVipPrice < 0.01 && !isVip" @click="jumpMember()">
                                购买会员
                            </div>
                            <div class="btnItem" v-show="saleVipPrice >= 0.01" @click="goAppToast()">
                                购买课程
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="noVipBox" v-if="buyType">
                    <img class="coverBgi" src="../../assets/images/livebg.png" alt="">
                    <div class="toastBox">
                        <div class="titles">{{ liveInfo.roomName }}</div>
                        <div class="typeBox">
                            <span v-show="saleVipPrice < 0.01" >会员免费</span>
                            <span v-show="saleVipPrice >= 0.01">会员价:{{ saleVipPrice }}</span>
                        </div>
                        <div class="btnBox">
                            <el-button size="small" type="primary" @click="reload()">刷新</el-button>
                            <el-button v-show="saleVipPrice < 0.01 && !isVip" size="small" type="primary" @click="jumpMember()">购买会员</el-button>
                            <el-button v-show="saleVipPrice >= 0.01" size="small" type="primary" @click="goAppToast()">购买课程</el-button>
                        </div>
                    </div>
                </div>
                <div class="chatRoomBox">
                    <div class="chatRoomList">
                        <div class="chatRoomItem" v-for="(item, index) in messageList" :key="index">
                           <span class="name"> {{ item.senderUserInfo.user_name }}:</span><span class="msgText">{{ item.message_content }}</span>
                        </div>
                    </div>
                    <div class="inputBox">
                        <input class="sendInput" type="text" v-model="messageValue" placeholder="请输入..">
                        <div class="sendBtn" @click="sendRoomText()">
                            <span v-if="sendTime == 0">发送</span>
                            <span v-else>{{ sendTime }}秒</span>
                        </div>
                    </div>
                </div>

                
                <div class="playViewBox">
                    <div class="userName">
                        当前用户: {{ userName }}
                    </div>
                </div>
            </div>
        </div>

        <div class="videoDetail">
            <!-- <h1 class="videoDetailTitle">{{ videoDetail.name }}</h1>
            <img class="videoDetailImg" :src="imgSrc + videoDetail.img" alt=""> -->
        </div>
    </div>
</template>
<link rel="stylesheet" href="https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css" />
<script type="text/javascript" charset="utf-8" src="https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js"></script>
<script>
    import VueAliplayer from "../../components/AliPlayer";
    import VueAliplayerV2 from 'vue-aliplayer-v2';
    export default {
        name: 'Live-Demo',
        components:{ VueAliplayerV2: VueAliplayerV2.Player,"ali-player": VueAliplayer },
        data(){
            return {
			    imgSrc: "https://case.chongyike.com",
                options: {
                    // source:'//player.alicdn.com/video/aliyunmedia.mp4',
                    isLive: true,   //切换为直播流的时候必填（true-直播状态，false-普通模式，播放器普通视频）
                },
                // 直播流
                source: '',
                // 直播id
                dataNo: "",
                dataType: "1",
                // 是否显示图片
                show: true,
                // 是否显示播放器0不显示 1显示播放器 2显示未开始弹框
                videoShow: 0,
                // 直播详情
                liveInfo: {},
                // 距离直播时间的倒计时
                countDownNum: 0,
                // 用户昵称
                userName: "",
                // 直播间聊天室ID
                charroomid: "",
                // 聊天室数据
                messageList: [],
                // 输入框内容
                messageValue: "",
                // 0时可以发聊天
                sendTime: 0,
                // 是否在播放
                playStatus: false,
                // 播放时长
                timeNum: 0,
                // 播放状态
                playingStatus: false,
                // 开始播放时间
                startTime: "",
                // 课程价格
                saleVipPrice: 0,
                buyType: false,
                // 直播准备就绪
                ready: false,
                // 是否是会员
                isVip: false,
            }
        },
        created(){
            const that = this
            that.dataNo = this.$route.params.id
            that.getUserVipTime()
            window.addEventListener('pagehide', async function (event) {
                that.inserVipRoomTime()
                localStorage.setItem('hahahha', 'ccc');
            });
            //禁止鼠标右击
            document.oncontextmenu = function () {
                event.returnValue = false;
            };
            //禁用开发者工具F12
            document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
                let e = event || window.event || arguments.callee.caller.arguments[0];
                if (e && e.keyCode == 123) {
                    e.returnValue = false;
                    return false;
                }
            };
            let userAgent = navigator.userAgent;
            if (userAgent.indexOf("Firefox") > -1) {
                let checkStatus;
                let devtools = /./;
                devtools.toString = function () {
                    checkStatus = "on";
                };
                setInterval(function () {
                    checkStatus = "off";
                    console.log(devtools);
                    console.log(checkStatus);
                    console.clear();
                    if (checkStatus === "on") {
                        let target = "";
                        try {
                            window.open("about:blank", (target = "_self"));
                        } catch (err) {
                            let a = document.createElement("button");
                            a.onclick = function () {
                                window.open("about:blank", (target = "_self"));
                            };
                            a.click();
                        }
                    }
                }, 200);
            } else {
                //禁用控制台
                let ConsoleManager = {
                    onOpen: function () {
                        alert("Console is opened");
                    },
                    onClose: function () {
                        alert("Console is closed");
                    },
                    init: function () {
                        let self = this;
                        let x = document.createElement("div");
                        let isOpening = false,
                            isOpened = false;
                        Object.defineProperty(x, "id", {
                            get: function () {
                                if (!isOpening) {
                                    self.onOpen();
                                    isOpening = true;
                                }
                                isOpened = true;
                                return true;
                            }
                        });
                        setInterval(function () {
                            isOpened = false;
                            console.info(x);
                            console.clear();
                            if (!isOpened && isOpening) {
                                self.onClose();
                                isOpening = false;
                            }
                        }, 200);
                    }
                };
                ConsoleManager.onOpen = function () {
                    //打开控制台，跳转
                    let target = "";
                    try {
                        window.open("about:blank", (target = "_self"));
                    } catch (err) {
                        let a = document.createElement("button");
                        a.onclick = function () {
                            window.open("about:blank", (target = "_self"));
                        };
                        a.click();
                    }
                };
                ConsoleManager.onClose = function () {
                    alert("Console is closed!!!!!");
                };
                ConsoleManager.init();
            }
        },
        beforeDestroy() {
            window.removeEventListener('beforeunload', this.showLeaveConfirmation);
        },
        beforeRouteLeave(to, from, next) {
            this.inserVipRoomTime()
            next()
        },
        methods:{
            showLeaveConfirmation(event) {
                this.inserVipRoomTime()
                const message = '您确定要离开此网站吗？';
                event.returnValue = message; // 兼容性设置
                return message;
            },
            goAppToast(){
                this.$message({
                    showClose: true,
                    message: "请前往宠医客APP进行购买",
                    type: 'success',
                    duration: 2000
                });
            },
            // 刷新
            reload(){
                window.location.reload()
            },
            // 跳转购买页
            jumpMember(){
                const that = this
                that.$router.push({
                    path: '/member',
                })
            },
            // 直播流准备就绪
            readyFun(){
                const that = this
                console.log("准备就绪")
                that.videoShow = 1
            },
            errorFun(){
                this.$router.push({
                    path: "/home",
                })
            },
            // 获取直播流
            getPlayerCertificateForWebSite() {
                const that = this
                var data = {
                    type: 'getWebLiveInfo2',
                    dataType: that.dataType,
                    dataNo: that.dataNo,
                }
                this.get("/webhttp/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        if(result.buyType){
                            that.buyType = true
                            that.saleVipPrice = result.saleVipPrice
                        }else {
                            result = this.decrypt(res.data.result,'wcyaliyunlivekey')
                            var key = result.split("-")[0];
                            var youxiaotime = result.split("-")[1];
                            var now = (new Date()).getTime(); //现在时间 时间戳
                            //流畅
                            var roomidurlld = "/wenchongyi/" + that.dataNo + "_ld.m3u8-" + now + "-0-0-" + key;
                            var pathld = that.hex_md5(roomidurlld);
                            var sourceld = "https://livelist.wenchongyi.com/wenchongyi/" + that.dataNo + "_ld.m3u8" + "?auth_key=" + now + "-0-0-" + pathld;
                            //标清
                            var roomidurlsd = "/wenchongyi/" + that.dataNo + "_sd.m3u8-" + now + "-0-0-" + key;
                            var pathsd = that.hex_md5(roomidurlsd);
                            var sourcesd = "https://livelist.wenchongyi.com/wenchongyi/" + that.dataNo + "_sd.m3u8" + "?auth_key=" + now + "-0-0-" + pathsd;
                            //高清
                            var roomidurlhd = "/wenchongyi/" + that.dataNo + "_hd.m3u8-" + now + "-0-0-" + key;
                            var pathhd = that.hex_md5(roomidurlhd);
                            var sourcehd = "https://livelist.wenchongyi.com/wenchongyi/" + that.dataNo + "_hd.m3u8" + "?auth_key=" + now + "-0-0-" + pathhd;
                            that.source = '{"SD":"' + sourcesd + '","LD":"' + sourceld + '","HD":"' + sourcehd + '"}'
                            // 计时器
                            that.countDown()
                            setTimeout(() => {
                                that.pause()
                            }, 1000);
                        }
                    }
                })
            },
            // 获取直播详情
            getLiveRoomDetail(){
                const that = this
                var data = {
                    type: 'getLiveRoomDetail',
                    version: 1,
                    roomId: that.dataNo,
                }
                this.get("/webhttp/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
					    var time = that.customDateStringToTimestamp(that.getCurrentDate(2))
                        // 页面标题
                        document.title = result.roomName + '-宠医客'
                        if(that.Cookies.get('loginInfo')){
                            // 用户水印
                            that.userName = JSON.parse(that.Cookies.get('loginInfo')).name
                        }
                        // 是否正在直播
						if(that.customDateStringToTimestamp(result.starttime) > time){
                            that.videoShow = 2
                            that.countDownNum = that.customDateStringToTimestamp(result.starttime)
                            // 提前半个小时拉流
                            if(that.customDateStringToTimestamp(result.starttime) < time + 1800000){
                                that.ready = true
                                that.getPlayerCertificateForWebSite()
                            }
                        }else {
                            that.ready = true
                            that.getPlayerCertificateForWebSite()
                        }
                        // 讲师列表
                        result.teacher = ""
                        for (var i = 0; i < result.lecturerRecord.length; i++) {
                            result.teacher += result.lecturerRecord[i].lecturerName
                        }
                        that.liveInfo = result
                        window.addEventListener('beforeunload', this.showLeaveConfirmation);
                        that.startTime = new Date().getTime()
                        that.timer()
                    }
                })
            },
            // 获取直播间聊天室ID
            getDataInfoHX(){
                const that = this
                if(!that.Cookies.get('loginInfo')){
                    return false
                }
                var data = {
                    type: 'getDataInfo',
                    publicUserNo: JSON.parse(that.Cookies.get('loginInfo')).No,
                    dataNo: that.dataNo,
                    dataType: "17",
                }
                this.get("/webhttp/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.charroomid = result.charroomid;
                        that.linkUser()
                        that.getMessageForPcOrWeChat()
                    }
                })
            },
            // 获取聊天室数据
            getMessageForPcOrWeChat(){
                const that = this
                var data = {
                    type: 'getMessageForPcOrWeChat',
                    orderByType: 1,
                    serder:  'app' + JSON.parse(that.Cookies.get('loginInfo')).No,
                    recipient: that.charroomid,
                    chatType: "2",
                    index: "0",
                    count: 20,
                }
                this.get("/webhttp/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.messageList = result
                    }
                })
            },
            // IM链接
            linkUser(){
                const that = this
                var data = JSON.parse(that.Cookies.get('loginInfo'))
                this.$login.user = "app" + data.No
                this.$login.pwd = that.hex_md5("app" + data.No)
                this.$conn.open(this.$login)
                localStorage.setItem("charroomid", that.charroomid)
                this.$conn.listen({
                    onOpened: function ( message ) { //连接成功回调
                        that.$conn.joinChatRoom({
                            roomId: that.charroomid
                        });
                    },
                    onClosed: function (msg){
                        console.log("退出聊天室")
                    },
                    onTextMessage: function (msg) { //收到文本消息
                        if(msg.type == "chatroom" && msg.to == that.charroomid){
                            var sourceMsg = msg.sourceMsg;
                            let from = msg.from;
                            let senderno = from.substr(3, from.length - 3);
                            //展示消息
                            if (!sessionStorage.getItem(senderno + 'name')) { //本地没有
                                that.getDoctorInfo(senderno).then((res) => {
                                    var chatname = res.name;
                                    if (!chatname) {
                                        chatname = '宠医客匿名用户'
                                    }
                                    sessionStorage.setItem(senderno + 'name', chatname);
                                    var data = {
                                        senderUserInfo: {
                                            user_name: chatname
                                        },
                                        message_content: sourceMsg
                                    }
                                    that.messageList.unshift(data)
                                })
                            } else {
                                var chatname = sessionStorage.getItem(senderno + 'name');
                                var data = {
                                    senderUserInfo: {
                                        user_name: chatname
                                    },
                                    message_content: sourceMsg
                                }
                                that.messageList.unshift(data)
                            }
                        }
                    },
                    onError: function(e) {
                        if(e.type == 206){
                            console.log("其他位置登录")
                            that.$confirm('该账号已在其他设备登录\n是否重新登录？', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                that.quitChatRoom()
                                that.Cookies.remove("loginInfo")
                                that.Cookies.remove("token")
                                window.location.reload()
                            }).catch(() => { 
                                that.Cookies.remove("loginInfo")
                                that.Cookies.remove("token")
                                window.location.reload()
                            });
                        }
                    }
                })
            },
            // 退出直播间
            quitChatRoom(){
                const that = this
                this.$conn.close()
                // 退出IM
                console.log("退出")
            },
            // 获取用户信息
            getDoctorInfo(doctorNo){
                const that = this
                return new Promise(function(resolve, reject) {
                    var data = {
                        type: 'getDoctorInfo',
                        doctorNo: doctorNo,
                    }
                    that.get("/webhttp/mobileHandler", data).then((res) => {
                        if (res.data.code == "success") {
                            var result = res.data.result
                            resolve(res.data.result)
                        }
                    })
                });
            },
            // 发送消息
            sendRoomText(){
                const that  = this
                if(that.sendTime > 0){
                    return false
                }
                if(that.messageValue.trim()){
                    var id = this.$conn.getUniqueId(); // 生成本地消息id
                    var msg = new WebIM.message('txt', id); // 创建文本消息
                    var option = {
                        msg: that.messageValue, // 消息内容
                        to: that.charroomid, // 接收消息对象(聊天室id)
                        roomType: true,
                        chatType: 'chatRoom',
                        success: function(e) {
                            //展示消息
                            var data = {
                                senderUserInfo: {
                                    user_name: JSON.parse(that.Cookies.get('loginInfo')).name,
                                },
                                message_content: that.messageValue
                            }
                            that.messageList.unshift(data)
                            that.messageBackup(0);
                            that.messageValue = ""
                            that.sendTime = 5
                            that.daojishi()
                        },
                        fail: function(e) {
                            that.messageBackup(1);
                        }
                    };
                    msg.set(option);
                    msg.setGroup('groupchat');
                    this.$conn.send(msg.body);
                }else {
                    that.$messgae({
                        showClose: true,
                        message: "发送内容不可为空",
                        type: 'error',
                        duration: 1000
                    })
                }
            },
            // 消息五秒发一条
            daojishi(){
                const that = this
                if(that.sendTime > 0){
                    setTimeout(() => {
                        that.sendTime--
                        that.daojishi()
                    }, 1000);
                }
            },
            messageBackup(wtype){
                const that = this
                var data = {
                    type: 'messageBackup',
                    userNo: JSON.parse(that.Cookies.get('loginInfo')).No,
                    serder: 'app' + JSON.parse(that.Cookies.get('loginInfo')).No,
                    recipient: that.charroomid,
                    chatType: "2", //聊天类型0=私聊、1=群聊、2=聊天室
                    wtype: wtype,
                    messageType: "0", //消息类型 0:文本 1:图片 2:语音消息
                    logType: "3", //0=后台、1=IOS、2=安卓 3=web
                    messageContent: that.messageValue
                }
                that.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result

                    }
                })
            },
            // 注册IM
            accountIM(){
                const that = this
                var data = {
                    type: 'accountIM',
                }
                that.get("/3/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                    }
                })
            },
            // 加载完成之后隐藏图片
            countDown(){
                const that = this
                if(that.getStatus() == "playing"){
                    that.show = false
                }else {
                    setTimeout(() => {
                        that.countDown()
                    }, 100);
                }
            },
            // 刷新按钮
            reloadWeb(){
                const that = this
                window.location.reload()
            },
            play(){
                const that = this
                that.startTime = new Date().getTime()
                that.timer()
                this.$refs.VueAliplayerV2.play()
            },
            pause(){
                const that = this
                this.$refs.VueAliplayerV2.pause();
            },
            replay(){
                this.$refs.VueAliplayerV2.replay();
            },
            getCurrentTime(){
                // this.$refs.VueAliplayerV2.getCurrentTime();
            },
            getStatus(){
                const status =  this.$refs.VueAliplayerV2.getStatus();
                return status;
            },
            // 暂停或播放
            changePlayStatus(){
                const that = this
                if(that.playStatus){
                    that.playStatus = false
                    that.pause()
                }else {
                    that.playStatus = true
                    that.play()
                }
            },
            // 会员进入直播间时长记录
            inserVipRoomTime(){
                const that = this
                if(that.timeNum < 5){
                    return false
                }
                var times =  new Date().getTime()
                var data = {
                    type: 'liveRoomDurationRecord',
                    // 聊天室ID
                    roomId: that.dataNo,
                    startTime: that.startTime,
                    duration: that.timeNum,
                }
                that.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.startTime = new Date().getTime()
                        localStorage.setItem("num", that.timeNum)
                        that.timeNum = 0
                    }
                })
            },
            // 计时器
            timer(){
                const that = this
                setTimeout(() => {
                    that.timeNum+=1
                    if(!that.playStatus){
                        that.inserVipRoomTime()
                    }else {
                        that.timer()
                    }
                }, 1000);
            },
            // 获取会员信息
            getUserVipTime(){
                const that = this
                var data = {
                    type: 'getUserVipTime',
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        if(result){
                            that.isVip = true
                        }
                        console.log(that.dataNo.indexOf('VE'))
                        if(that.dataNo.indexOf('VE') > -1){
                            that.getVideoDetailInfo()
                        }else {
                            that.getLiveRoomDetail()
                            that.getDataInfoHX()
                        }
                    }
                })
            },
            // 获取视频详情
            getVideoDetailInfo(){
                const that = this
                var data = {
                    type: 'getExaminationDetailInfo',
                    dataNo: that.dataNo,
                }
                this.get("/webhttp/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result.info
                        if(that.Cookies.get('loginInfo')){
                            // 用户水印
                            that.userName = JSON.parse(that.Cookies.get('loginInfo')).name
                        }
                        document.title = result.courseName + '-宠医客'
                        that.charroomid = result.charRoomId;
                        that.linkUser()
                        that.getMessageForPcOrWeChat()
                        that.getLivePlayUrl2()
                    } else {
                        console.log(res.data.message)
                    }
                })
            },
            // 获取执兽直播
            getLivePlayUrl2(){
                const that = this
                var data = {
                    type: 'getLivePlayUrl2',
                    liveRoomId: that.dataNo,
                    dataType: "9",
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result  = this.decrypt(res.data.result,'wcyaliyunlivekey')
                        console.log(result)
                        that.startTime = new Date().getTime()
                        that.timer()
                        that.ready = true
                        setTimeout(() => {
                            that.source = result
                        }, 500);
                        // 计时器
                        setTimeout(() => {
                            that.countDown()
                            that.pause()
                        }, 1000);
                    }
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}

@keyframes example {
    0%  {
        left: 0;
    }
    100% {
        left: 100%;
        transform: translateX(0);
    }
}

.liveDetailtitle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 88px;
    background-color: #4198e2;
    .textBox {
        position: relative;
        width: 1170px;
        padding-left: 10px;
        font-size: 1.75rem;
        font-weight: normal;
        color: #fff;
        box-sizing: border-box;
        margin: 0 auto;
        height: 88px;
        display: flex;
        align-items: center;
        .text {
            width: 878px;
        }
        .titleBox {
            display: flex;
            align-items: center;
            width: 282px;
            height: 88px;
            padding-left: 10px;
            box-sizing: border-box;
            background-color: #1f6eaf;
        }
    }
    
    .bgi {
        position: absolute;
        left: 0;
        bottom: -0px;
        width: 100vw;
        height: 550px;
        background-color: #474747;
    }
}
.content {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    border: none !important;
    .contentBox {
        position: relative;
        width: 1170px !important;
        height: 550px;
        // display: flex;
        // justify-content: center;
        .playBox {
            overflow: hidden;
            width: 888px !important;
            height: 550px !important;
            video {
                width: auto !important;
            }
        }
        .playShowBox {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 888px !important;
            height: 500px !important;
            user-select: none;
            cursor: pointer;
            z-index: 1002;
            .playBtn {
                width: 90px;
                background-color: #fff;
                border-radius: 50%;
            }
        }
        .chatRoomBox {
            position: absolute;
            right: 0;
            top: 0;
            width: 282px;
            height: 550px;
            background-color: #fff;
            z-index: 10;
            .chatRoomList {
                overflow-y: scroll;
                width: 282px;
                height: 510px;
                padding: 10px 10px;
                box-sizing: border-box;
                border-bottom: 1px solid #eee;
                .chatRoomItem {
                    padding: 5px 0;
                    color: #333;
                    .name {
                        color: #666;
                        user-select: none;
                    }
                }
            }
            .inputBox {
                display: flex;
                align-items: center;
                width: 282px;
                height: 40px;
                .sendInput {
                    width: 202px;
                    height: 40px;
                    border: none;
                    outline: none;
                    padding-left: 10px;
                    box-sizing: border-box;
                }
                .sendBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80px;
                    height: 40px;
                    color: #fff;
                    background-color: #4198e2;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
        .showImg {
            position: absolute;
            left: 0;
            top: 0;
            width: 889px;
            height: 550px;
            background-color: #000;
            .bannerImg  {
                width: 100%;
                height: 100%;
            }
            .toastBox {
                position: absolute;
                left: 50%;
                top: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 20px 50px;
                border-radius: 10px;
                background-color: #fff;
                transform: translate(-50%, -50%);
                .startToast {
                    padding-bottom: 20px;
                    color: #00AA80;
                    text-shadow: 0px 2px 7px #b1afaf;
                }
                .teacherBox {
                    padding-top: 20px;
                    color: #1f6eaf;
                    text-shadow: 0px 2px 7px #b1afaf;
                }
                .btnBox {
                    padding-top: 20px;
                    display: flex;
                    align-items: center;
                    .btnItem {
                        padding: 6px 10px;
                        color: #fff;
                        background-color: #79b9ed;
                        border-radius: 4px;
                        cursor: pointer;
                        &:nth-child(1){
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .playViewBox {
            position: absolute;
            bottom: 0;
            left: 0px;
            width: 1170px;
            height: 40px;
            overflow: hidden;
            .userName {
                position: absolute;
                bottom: 20px;
                left: 0px;
                color: #d2d2d2;
                font-size: 16px;
                transform: translateX(-100%);
                animation-name: example;
                animation-duration: 30s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                z-index: 9;
                user-select: none;
            }
        }

    }
}

.noVipBox {
    position: absolute;
    z-index: 1001;
    top: 0;
    .coverBgi {
        width: 888px;
        height: 550px;
    }
    .toastBox {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px 50px;
        border-radius: 10px;
        background-color: #fff;
        user-select: none;
        .titles {
            color: #4198e2;
            text-shadow: 0px 2px 7px #b1afaf;
        }
        .typeBox {
            color: #c3911c;
            padding: 15px 0;
        }
    }
} 
.remove-text {
    text-align: center;
    padding: 20px;
    font-size: 24px;
}

.show-multiple {

    display: flex;

    .multiple-player {

        width: calc(100% / 4);
        margin: 20px;
    }
}

.player-btns {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    span {

        margin: 0 auto;
        display: inline-block;
        padding: 5px 10px;
        width: 150px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #eee;
        background: #e1e1e1;
        border-radius: 10px;
        text-align: center;
        margin: 5px;
        cursor: pointer;
    }
}

.source-box {

    padding: 5px 10px;
    margin-bottom: 20px;

    .source-label {

        margin-right: 20px;
        font-size: 16px;
        display: block;
    }

    #source {

        margin-top: 10px;
    }

    .source-input {

        margin-top: 10px;
        padding: 5px 10px;
        width: 80%;
        border: 1px solid #ccc;
    }
}

.prism-big-play-btn {
    display: none !important;
}
</style>