import Vue from 'vue';
import ElementUI from 'element-ui';
import router from "./router";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css';
import hex_md5 from "./assets/js/md5"
import App from './App.vue';
import { get, post } from "./utils/request";
import { videoprice, getCurrentDate, encrypt,  decrypt, customDateStringToTimestamp} from "./api/config";
import Cookies from 'js-cookie'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 全局挂载
Vue.use(VueAwesomeSwiper)

import websdk from "easemob-websdk"

const config = {
  xmppURL: '//im-api.easemob.com',       // xmpp Server地址，对于在console.easemob.com创建的appKey，固定为该值
  apiURL: 'http://a1.easemob.com',       // rest Server地址，对于在console.easemob.com创建的appkey，固定为该值
  appkey: '1121171025115869#com-huiyiapp-cyk-wcy',    // App key
  https: false,                          // 是否使用https
  isHttpDNS: true,                       // 防止DNS劫持从服务端获取XMPPUrl、restUrl
  isMultiLoginSessions: false,           // 是否开启多页面同步收消息，注意，需要先联系商务开通此功能
  isDebug: false,                        // 打开调试，会自动打印log，在控制台的console中查看log
  autoReconnectNumMax: 2,                // 断线重连最大次数
  autoReconnectInterval: 3,              // 断线重连每次尝试连接的间隔
  heartBeatWait: 30000,                  // 使用webrtc（视频聊天）时发送心跳包的时间间隔，单位ms
  delivery: true,                        // 是否发送已读回执
} 
var conn = {};
WebIM.config = config;
conn = WebIM.conn = new WebIM.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    url: WebIM.config.socketServer,
    apiUrl: WebIM.config.restServer,
    isAutoLogin: WebIM.config.isAutoLogin,
    heartBeatWait: WebIM.config.heartBeatWait,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    delivery: WebIM.config.delivery,
    useOwnUploadFun: WebIM.config.useOwnUploadFun
}) 
conn.listen({
  onOpened: function ( message ) {
    // console.log('连接成功1');
  },         //连接成功回调
  onClosed: function ( message ) {},         //连接关闭回调
  onTextMessage: function ( message ) {
    // console.log(message)
  },    //收到文本消息
  onEmojiMessage: function ( message ) {},   //收到表情消息
  onPictureMessage: function ( message ) {}, //收到图片消息
  onCmdMessage: function ( message ) {},     //收到命令消息
  onAudioMessage: function ( message ) {},   //收到音频消息
  onLocationMessage: function ( message ) {},//收到位置消息
  onFileMessage: function ( message ) {},    //收到文件消息
  onVideoMessage: function (message) {
      // var node = document.getElementById('privateVideo');
      // var option = {
      //     url: message.url,
      //     headers: {
      //       'Accept': 'audio/mp4'
      //     },
      //     onFileDownloadComplete: function (response) {
      //         var objectURL = WebIM.utils.parseDownloadResponse.call(conn, response);
      //         node.src = objectURL;
      //     },
      //     onFileDownloadError: function () {
      //         console.log('File down load error.')
      //     }
      // };
      // WebIM.utils.download.call(conn, option);
  },   //收到视频消息
  onPresence: function ( message ) {},       //处理“广播”或“发布-订阅”消息，如联系人订阅请求、处理群组、聊天室被踢解散等消息
  onRoster: function ( message ) {},         //处理好友申请
  onInviteMessage: function ( message ) {},  //处理群组邀请
  onOnline: function () {},                  //本机网络连接成功
  onOffline: function () {},                 //本机网络掉线
  onError: function ( message ) {},          //失败回调
  onBlacklistUpdate: function (list) {       //黑名单变动
      // 查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息
      // console.log(list);
  },
  onRecallMessage: function(message){},      //收到撤回消息回调
  onReceivedMessage: function(message){},    //收到消息送达服务器回执
  onDeliveredMessage: function(message){},   //收到消息送达客户端回执
  onReadMessage: function(message){},        //收到消息已读回执
  onCreateGroup: function(message){},        //创建群组成功回执（需调用createGroupNew）
  onMutedMessage: function(message){}        //如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
});
var options = {
  apiUrl: WebIM.config.apiURL,
  user: '',
  pwd: '',
  appKey: WebIM.config.appkey,
  success: function (res) {
    // console.log(res)
  },
  error: function (err) {
    console.log(err)
  }
};
Vue.prototype.$conn = conn;
Vue.prototype.$login = options; //登录

// 加密
Vue.prototype.hex_md5 = hex_md5;
// 存cookie方法
Vue.prototype.Cookies = Cookies;
// get请求
Vue.prototype.get = get;
// post请求
Vue.prototype.post = post;
// 判断课程价格
Vue.prototype.videoprice = videoprice;
// 获取当前时间
Vue.prototype.getCurrentDate = getCurrentDate;
// 获取当前时间
Vue.prototype.customDateStringToTimestamp = customDateStringToTimestamp;
// AES加密
Vue.prototype.encrypt = encrypt;
// 解密
Vue.prototype.decrypt = decrypt;
Vue.use(ElementUI);


new Vue({
  router,
  el: '#app',
  // mounted() {
  //   document.addEventListener('keydown', this.handleKeyDown);
  //   document.addEventListener('contextmenu', this.handleContextMenu);
  // },
  // beforeDestroy() {
  //   document.removeEventListener('keydown', this.handleKeyDown);
  //   document.removeEventListener('contextmenu', this.handleContextMenu);
  // },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'F12' || event.keyCode === 123) {
        event.preventDefault();
        // alert('调试功能已被禁用！');
      }
    },
    handleContextMenu(event) {
      event.preventDefault();
    }
  },
  render: h => h(App)
});