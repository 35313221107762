<template>
    <div>
        <div class="content">
            <div class="historyBox">
                <div class="historyTitle">
                    搜索历史
                    <el-button size="small" type="danger" icon="el-icon-delete" @click="deleteUserSearchHistory()">清空</el-button>
                </div>
                <div class="historyList">
                    <el-button size="small" v-for="(item,index) in historyListData" :key="index" @click="changeKeyWord(item)">
                        {{ item.keyword }}
                    </el-button>
                </div>
            </div>
            <div class="inputBox">
                <el-input
                    :autofocus="true"
                    ref="input"
                    placeholder="视频、直播、医讯..."
                    v-model="keyword">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-button type="primary" style="margin-left: 30px;" @click="searchDataForWebSite()">搜索</el-button>
            </div>

            <!-- 搜索到的内容 -->
            <el-tabs class="searchContent" v-model="dataType" @tab-click="handleClick">
                <el-tab-pane label="视频" name="15">
                    <div class="toast" v-show="searchContentData.length == 0">
                        <span v-if="keyword && searchOff">没有找到相关内容，请尝试其他关键字</span>
                        <span v-else>请输入你要搜索的关键字</span>
                    </div>
                    <div class="livecont videocont" v-show="searchContentData.length > 0">
                        <div class="liveitem" v-for="(item, index) in searchContentData" :key="index" @click="jumpNewVideoDetail(item)">
                            <div class="themeimg">
                                <img class="viodeimgitem default" :src="item.image?imgSrc + item.image:require('../../assets/images/default.jpg')">
                            </div>
                            <div class="videoinfo">
                                <div class="name">{{ item.title }}</div>
                                <div class="livetime">{{ item.marder_time.slice(0,10) }}</div>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="count"
                        style="margin: 0 0 20px;"
                        v-show="searchContentData.length > 0"
                        @current-change="changeBtn">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="直播" name="17">
                    <div class="toast" v-show="searchContentData.length == 0">
                        <span v-if="keyword && searchOff">没有找到相关内容，请尝试其他关键字</span>
                        <span v-else>请输入你要搜索的关键字</span>
                    </div>
                    <div class="livecont videocont" v-show="searchContentData.length > 0">
                        <div class="liveitem" v-for="(item, index) in searchContentData" :key="index" @click="jumpLiveDetail(item)">
                            <div class="themeimg">
                                <img class="viodeimgitem default" :src="item.image?imgSrc + item.image:require('../../assets/images/default.jpg')">
                            </div>
                            <div class="videoinfo">
                                <div class="name">{{ item.title }}</div>
                                <div class="livetime">{{ item.marder_time.slice(0,10) }}</div>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="count"
                        style="margin: 0 0 20px;"
                        v-show="searchContentData.length > 0"
                        @current-change="changeBtn">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="执兽" name="18">
                    <div class="toast" v-show="searchContentData.length == 0">
                        <span v-if="keyword && searchOff">没有找到相关内容，请尝试其他关键字</span>
                        <span v-else>请输入你要搜索的关键字</span>
                    </div>
                    <div class="livecont videocont" v-show="searchContentData.length > 0">
                        <div class="liveitem" v-for="(item, index) in searchContentData" :key="index" @click="jumpVideoDetail(item)">
                            <div class="themeimg">
                                <img class="viodeimgitem default" :src="item.image?imgSrc + item.image:require('../../assets/images/default.jpg')">
                            </div>
                            <div class="videoinfo">
                                <div class="name">{{ item.title }}</div>
                                <div class="livetime">{{ item.marder_time.slice(0,10) }}</div>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="count"
                        style="margin: 0 0 20px;"
                        v-show="searchContentData.length > 0"
                        @current-change="changeBtn">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="医讯" name="3">
                    <div class="toast" v-show="searchContentData.length == 0">
                        <span v-if="keyword && searchOff">没有找到相关内容，请尝试其他关键字</span>
                        <span v-else>请输入你要搜索的关键字</span>
                    </div>
                    <div class="newsbox">
                        <div class="newsItem" v-for="(item, index) in searchContentData" :key="index" @click="jumpPostDetail(item)">
                            <img class="newsImg default" :src="item.image?imgSrc + item.image:require('../../assets/images/default.jpg')">
                            {{ item.title }}
                        </div>
                    </div>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="count"
                        style="margin: 0 0 20px;"
                        v-show="searchContentData.length > 0"
                        @current-change="changeBtn">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="线下培训" name="29">
                    <div class="toast">
                        <span v-if="keyword">没有找到相关内容，请尝试其他关键字</span>
                        <span v-else>请输入你要搜索的关键字</span>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            index: 0,
            count: 10,
            keyword: "",
            dataType: "15",
            // 历史记录
            historyListData: [],
            // 搜索内容展示
            searchContentData: [],
            // 搜索到的总数量
            total: 0,
            // 点过搜索按钮
            searchOff: false,
            // 设置回车键防抖
            loadingTime: {}
        }
    },
    created(){
        const that = this
        // var data = JSON.parse(that.decrypt(this.$route.query.text))
        // that.keyword = data.queryString
        that.getUserSearchHistory()
        // that.searchDataForWebSite()
    },
    mounted() {
        window.addEventListener("keydown", this.keydownClick)
        this.$nextTick(() => {
            if (this.$refs.input) {
                this.$refs.input.focus();
            }
        });
    },
    methods: {
        keydownClick(e){
            const that = this 
            var keyCode = e.keyCode
            if(keyCode == "13"){
                that.loadingTime = ""
                // 回车键搜索
                that.loadingTime = setTimeout(() => {
                    that.searchDataForWebSite()
                }, 100);
            }
        },
        // 获取历史记录
        getUserSearchHistory(){
			const that = this
            var data = { 
                type: 'getUserSearchHistory',
            }
            this.get("/webhttp/mobileHandler",data).then((res)=>{
                if(res.data.code == "success"){
                    var result = res.data.result
                    that.historyListData = result
                }else {
                    console.log(res.data.message)
                }
            })
        },
        // 搜索方法
        searchDataForWebSite(){
			const that = this
            console.log(that.keyword)
            if(that.keyword.trim() == ""){
                this.$message({
                    showClose: true,
                    message: '请输入搜索内容~',
                    type: 'error'
                });
                return false
            }
            var data = { 
                type: 'searchDataForWebSite',
                dataType: that.dataType,
                index: that.index,
                count: that.count,
                keyword: that.keyword,
            }
            this.get("/webhttp/mobileHandler",data ,1).then((res)=>{
                if(res.data.code == "success"){
                    var result = res.data.result
                    that.searchOff = true
                    that.total = res.data.count
                    that.searchContentData = result
                }
            })
        },
        // 清空历史记录
        deleteUserSearchHistory(){
            const that = this
            var data = { 
                type: 'deleteUserSearchHistory',
            }
            this.get("/webhttp/mobileHandler",data ,1).then((res)=>{
                if(res.data.code == "success"){
                    var result = res.data.result
                    console.log(result)
                    that.historyListData = []
                    that.$message('清空成功');
                }
            })
        },
        // 切换标签页
        handleClick(tab, event) {
            const that = this
            that.index = 0
            that.dataType = tab.name
            that.searchDataForWebSite()
        },
        // 点击历史记录
        changeKeyWord(item){
            const that = this
            that.keyword = item.keyword
            that.searchDataForWebSite()
        },
        // 翻页
        changeBtn(e){
            const that = this
            that.index = e-1
            that.searchDataForWebSite()
        },
        // 视频跳转
		jumpNewVideoDetail(item){
            const that = this
            if(item.dataType == "15"){
                // 15跳转视频
                var data = {
                    dataType: 0,
                    No: item.dataNo,
                    name: item.title,
                    classType: 1,
                }
                that.$router.push({
                    path: '/videoDetail',
                    query: {
                        text: that.encrypt(JSON.stringify(data))
                    }
                })
            }else if(item.dataType == "A97"){
                // A97跳转系列
                that.$router.push({
                    path: '/videolistInner/' + item.dataNo,
                })
            }
		},
        // 跳转直播详情
        jumpLiveDetail(item){
            const that = this
            that.$router.push({
                path: '/liveDetail/' + item.dataNo,
            })
        },
        // 跳转执兽视频
        jumpVideoDetail(item){
            const that = this
            var data = {
                dataType: 9,
                No: item.dataNo,
				name: item.title,
                classType: 1,
            }
            that.$router.push({
                path: '/trainVetVideoDetail',
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
		// 跳转宠医资讯
        jumpPostDetail(item){
            this.$router.push({
                path: "/postDetail/" + item.dataNo,
            })
        },
    },
}
</script>

<style lang="scss" scoped>

    .historyBox {
        .historyTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
        }
        .historyList {
            display: flex;
            align-items: center;
            .historyItem {
                padding: 3px 10px;
                border-radius: 3px;
                border: 1px solid #ccc;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
            }
        }
    }
    .inputBox {
        display: flex;
        align-items: center;
        // padding: 0 30px;
        margin-top: 20px;
    }

    .searchContent {
        margin-top: 10px;
        
		.livecont {
			display: flex;
            padding: 10px 0;
            flex-wrap: wrap;
			cursor: pointer;

			.liveitem {
				width: 215px;
				border-radius: 5px;
                margin-right: 23.5px;
                margin-bottom: 20px;
				box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
                &:nth-child(5n){
                    margin: 0;
                    margin-bottom: 20px;
                }
				&:hover {
					box-shadow: 2px 7px 11px rgb(217, 232, 247);
				}

				.price {
					color: #00AA80;
					.vipprice {
						margin-left: 0.875rem;
						color: #C3911C;
					}
				}
				.themeimg {
					position: relative;
					border-radius: 5px;
					width: 215px;
					height: 160px;
					overflow: hidden;

					.viodeimgitem {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						border-radius: 5px;
						object-fit: cover;
                        // background-image: url("../../assets/images/default.png");
					}

					.absolutbox {
						position: absolute;
						display: flex;
						justify-content: space-between;
						align-items: center;
						left: 0;
						bottom: 0;
						width: 100%;
						padding: 3px 10px;
						box-sizing: border-box;
						color: #fff;
						font-size: 14px;
						border-radius: 5px;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
						cursor: pointer;

						i {
							font-size: 18px;
						}
					}

					.livepricebox {
						position: absolute;
						right: 0;
						top: -2px;
						font-size: 14px;

						.forfree {
							padding: 0 3px;
							color: #6aac29;
							border-radius: 2px;
							background: rgba(231, 249, 213, 0.9);
						}

						.vipprice {
							padding: 0 3px;
							border-radius: 2px;
							color: #e09119;
							background: rgba(255, 243, 226, 0.7);
						}
					}
				}

				.videoinfo {
					padding: 10px;

					.name {
						color: rgba(68, 68, 68, 1);
						font-weight: 600;
						font-size: 18px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					.teacher {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						color: #444444;
						font-size: 14px;
						font-weight: 400;
					}

					.livetime {
						font-size: 14px;
						font-weight: 400;
                        padding-top: 5px;
					}
				}
			}
		}

        .newsbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 5px;
            .newsItem {
                display: flex;
                align-items: center;
                width: 49%;
                height: 100px;
                padding: 0 10px;
                box-sizing: border-box;
                margin-bottom: 20px;
                border-radius: 6px;
				box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
                cursor: pointer;
                .newsImg {
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                    border-radius: 6px;
                }
            }
        }
    }
</style>