<!-- 线下证书课 -->
<template>
    <div class="content">
        <!-- <el-breadcrumb separator="/" style="margin-top: 20px;">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <div class="titles" style="color:#1F6EAF; cursor: pointer;">线上证书课</div>
            </el-breadcrumb-item>
        </el-breadcrumb> -->
        <div class="cousecontent">
            <div class="couseitem" v-for="(item, index) in onlinecouse" :key="index" @click="jumpDetail(item)">
                <div class="couseimg">
                    <img class="viodeimgitem default"  :src="item.smallmap?imgSrc + item.smallmap:require('../../assets/images/default.jpg')">
                </div>
                <div class="couseinfo">
                    <div class="title">{{ item.train_name }}</div>
                    <div class="teacher">讲师：{{ item.teacher }}</div>
                    <div class="price">
                        {{ item.line_start.slice(0,10) }}至{{ item.line_end.slice(0,10) }}
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="12"
            @current-change="changeBtn">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 线上证书课
            onlinecouse: [],
            index: 0,
            total: 0
        }
    },
    created() {
        const that = this
        that.getTrainingLineInfo()
    },
    methods: {
        // 跳转详情页
        jumpDetail(item){
            console.log(item)
            this.$router.push({
                path: "/detail/" + item.No,
            })
        },
        // 获取线上课
        getTrainingLineInfo() {
            const that = this
            var data = {
                type: 'getTrainingLineInfo',
                index: that.index,
                count: 12,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.total = res.data.count
					for (let i = 0; i < result.length; i++) {
						result[i].text = that.videoprice(result[i].member_price,result[i].member_price ,result[i].standard_points ,result[i].member_points)
					}
                    that.onlinecouse = []
                    that.onlinecouse = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 翻页
        changeBtn(e){
            const that = this
            that.index = e-1
            that.getTrainingLineInfo()
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-bottom: 30px;
    border-top: 1px solid #ccc;
    .cousecontent {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .couseitem {
            position: relative;
            width: 50%;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            cursor: pointer;
            font-size: 0.875rem;
            padding-bottom: 2rem;
            padding-top: 2rem;
            border-bottom: 1px solid #eee;
            .couseimg {
                position: relative;
                flex: 1;
                margin-right: 1.25rem;
                height: 0;
                padding-bottom: 26%;
                .viodeimgitem {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }
            .couseinfo {
                flex: 1.8;
                height: 100%;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title {
                    color: #444444;
                    font-size: 1.05rem;
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .teacher {
                    color: #444444;
                }
                .price {
                    color: #444444;
                }
            }
        }
    }
}
</style>