import { render, staticRenderFns } from "./liveDetail.vue?vue&type=template&id=43bbabee&scoped=true&"
import script from "./liveDetail.vue?vue&type=script&lang=js&"
export * from "./liveDetail.vue?vue&type=script&lang=js&"
import style0 from "./liveDetail.vue?vue&type=style&index=0&id=43bbabee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43bbabee",
  null
  
)

/* custom blocks */
import block0 from "./liveDetail.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Fg.alicdn.com%2Fde%2Fprismplayer%2F2.9.3%2Fskins%2Fdefault%2Faliplayer-min.css"
if (typeof block0 === 'function') block0(component)

export default component.exports