<template>
    <div class="content">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/trainVet' }">执兽课程</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/myCollection' }">我的收藏</el-breadcrumb-item>
            <el-breadcrumb-item>题目详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="WebTitle">
            <div class="title">{{ subjectName }}/第{{sort + 1}}题</div>
        </div>
        <!-- <div class="changeBtnBox">
            <div :class="pattern == 0?'btnItem active': 'btnItem'" @click="changeType(0)">
                答题
            </div>
            <div :class="pattern == 1?'btnItem active': 'btnItem'" @click="changeType(1)">
                背题
            </div>
        </div> -->
        <div class="mainBox">
            <!-- <div class="answerSheet">
                <div class="answerSheetTitle">
                    注意事项
                </div>
                <div class="answerSheetToast">
                    <div>1、题号右方的符号代表该小题尚末选择答案。</div>
                    <div>2、题号右方的“ ？”符号代表该小题做过标记。</div>
                    <div>3、选择选项的同时自动保存答案。</div>
                </div>
                <div class="tanswerSheetBtnList">
                    <div v-for="(item, index) in btnList" :key="index"
                        :style="mainBox.dataId == item.data_id?'border: 2px solid #000; color: #000;background-color: #edf8ff;':''"
                        :class="'answerSheetBtnItem '+ 'answerSheetBtnItem'+item.state" @click="changeBtn(index)">
                        {{ index +1 }}
                    </div>
                </div>
            </div> -->
            <div class="questionStem">
                <div class="answerSheetTitle">
                    每一道考题下面有A、B、C、D、E五个备选答案，请从中选择一个最佳答案
                </div>
                <div class="questionStemMain">
                    <div class="questionStemMainTitle">
                        {{ mainBox.question_title }}
                    </div>
                    <div class="questionStemMainImg" v-if="mainBox.question_title_img">
                        <img :src="imgSrc + mainBox.question_title_img" alt="">
                    </div>
                    <div class="changeAnswerList">
                        <div class="changeAnswerItem" v-for="(item, index) in mainBox.question_answer_list" :key="index">
                            {{ item.optionItem + "." + item.optionDetail }}
                        </div>
                    </div>
                    <div class="rightAnswers" style="margin-top: 20px;" v-if="mainBox.answer">
                        <div class="topbox">
                            正确答案<span style="color: #00DEA3; padding: 0 8px;">{{ mainBox.answer }}</span>
                            你的答案<span style="color: #5C99E5; padding: 0 8px;">{{ mainBox.my_answer }}</span>
                            <!-- 易错答案<span style="color: #D82B29; padding: 0 8px;">{{ mainBox.error_prone }}</span> -->
                        </div>
                        <div class="">
                            全站正确率{{ Math.ceil(mainBox.right_rate*100) }}%,难易程度:
                            <span style="color: #00DEA3;" v-if="((mainBox.right_rate* 100 + '').substring(0,(mainBox.right_rate* 100 + '').lastIndexOf('.') + 3))/1 >= 66">简单</span>
                            <span style="color: #ffcc01;" v-else-if="((mainBox.right_rate* 100 + '').substring(0,(mainBox.right_rate* 100 + '').lastIndexOf('.') + 3))/1 < 66 && ((mainBox.right_rate* 100 + '').substring(0,(mainBox.right_rate* 100 + '').lastIndexOf('.') + 3))/1 > 33">一般</span>
                            <span style="color: #ff625d;" v-else>困难</span>
                        </div>
                    </div>
                    <div class="rightAnswers" style="font-size: 14px;" v-html="mainBox.analysis_text" v-if="mainBox.analysis_text"></div>
                    <div class="rightAnswers" style="font-size: 14px;" v-if="mainBox.answer">
                        考点: {{ mainBox.examination_point }}
                    </div>
                </div>
                <div class="answerList">
                    <div class="upBtn" v-show="sort!=0" @click="changeBtn(sort-1)">上一题</div>
                    <!-- <div class="answerItem" @click="submitErrorAnswer(item.optionItem)" v-for="(item, index) in mainBox.question_answer_list" :key="index">
                        <div class="btn" :style="mainBox.my_answer == item.optionItem? 'background-color: #5c99e5;': ''"></div>{{ item.optionItem }}
                    </div> -->
                    <div class="upBtn pgBtn" v-show="sort+1 < count" @click="changeBtn(sort+1)">下一题</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: "https://case.chongyike.com",
            // 答题卡
            btnList: [],
            // 参数
            subjectId: "",
            subjectName: "",
            // 0答题 1背题
            pattern: 0,
            // 题目内容
            mainBox: {},
            // 当前答题号
            sort: 0,
            // 当前考点总题数
            count: 0,
        }
    },
    created(){
        const that = this
        var data = JSON.parse(that.decrypt(this.$route.query.text))
        that.count = data.count
        that.subjectId = data.subjectId
        that.subjectName = data.subjectName
        that.myCollectionQuestionRecord()
    },
    methods: {
        // 切换模式0=答题，1=背题
        changeType(type){
            const that = this
            that.pattern = type
            that.getExaminationPointList(that.btnList[that.sort].data_id)
        },
        // 切换题目
        changeBtn(index){
            const that = this
            that.sort = index
            that.myCollectionQuestionRecord()
        },
        // 获取题目
        myCollectionQuestionRecord(){
            const that = this
            var data = {
                type: 'myCollectionQuestionRecord',
                subjectId: that.subjectId,
                index: that.sort,
                count: 1
            }
            this.get("/3/veterinaryYear", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    console.log(JSON.parse(that.decrypt(result))[0])
                    that.mainBox = JSON.parse(that.decrypt(result))[0]
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 提交题目
        submitErrorAnswer(answer){
            const that = this
            // 答过则不请求
            if(that.mainBox.answer){
                return false
            }
            var data = {
                type: 'submitErrorAnswer',
                recordNo: that.record_no,
                answer: answer,
                dataId: that.mainBox.data_id,
            }
            this.get("/3/veterinaryYear", data, 1).then((res) => {
                if (res.data.code == "success") {
                    var result = JSON.parse(that.decrypt(res.data.result))
                    that.$set(that.mainBox, 'analysis_text', result.analysis_text)
                    that.$set(that.mainBox, 'answer', result.answer)
                    that.$set(that.mainBox, 'my_answer', result.my_answer)
                    that.$set(that.mainBox,'error_prone',result.error_prone)
                    that.$set(that.mainBox, 'right_rate', result.right_rate)
                    // if(result.my_answer == result.answer){
                    //     that.btnList[that.sort].state = 1
                    // }else {
                    //     that.btnList[that.sort].state = 0
                    // }
                } else {
                    console.log(res.data.message)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .content {
        user-select: none;
        .WebTitle {
            color: #1F6EAF;
            font-size: 24px;
            padding-top: 10px;
        }
        .changeBtnBox {
            margin-top: 20px;
            overflow: hidden;
            display: flex;
            align-items: center;
            width: 380px;
            border-radius: 10px;
            border: 1px solid #ccc;
            .btnItem {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                font-size: 14px;
                color: #323233;
                cursor: pointer;
                &:nth-child(1){
                    border-right: 1px solid #ccc;
                }
            }
            .active {
                color: #155BD4;
            }
        }
        .mainBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .answerSheet {
                overflow: hidden;
                width: 390px;
                height: 640px;
                border-radius: 10px;
                border: 1px solid #e3e4e4;
                .answerSheetTitle {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    font-size: 14px;
                    color: #fff;
                    padding-left: 20px;
                    background-color: #5C99E5;
                }
                .answerSheetToast {
                    height: 90px;
                    padding: 15px 20px ;
                    font-size: 14px;
                    color: #333;
                    box-sizing: border-box;
                }
                .tanswerSheetBtnList {
                    overflow-y: scroll;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0 20px;
                    max-height: 500px;
                    box-sizing: border-box;
                    .answerSheetBtnItem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 28px;
                        height: 28px;
                        box-sizing: border-box;
                        margin-right: 7px;
                        margin-bottom: 7px;
                        font-size: 13px;
                        border: 2px solid #5c99e5;
                        border-radius: 3px;
                        cursor: pointer;
                        &:nth-child(10n){
                            margin-right: 0;
                        }
                    }

                    .answerSheetBtnItem-1 {
                        color:#9a9a9a;
                       
                        // background-color: #ededed;
                    }
                    .answerSheetBtnItem0 {
                        color: #f4593c;
                        border: 2px solid #f4593c;
                        background-color: #fceeee;
                    }
                    .answerSheetBtnItem1 {
                        color: #00DEA3;
                        border: 2px solid #00DEA3;
                        background-color: #d6f7e9;
                    }
                }
            }
            .questionStem {
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 640px;
                border-radius: 10px;
                border: 1px solid #e3e4e4;
                .answerSheetTitle {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    font-size: 14px;
                    color: #fff;
                    padding-left: 20px;
                    background-color: #5C99E5;
                }
                .questionStemMain {
                    overflow-y: scroll;
                    height: 520px;
                    padding: 20px;
                    box-sizing: border-box;
                    .questionStemMainTitle {
                        font-size: 16px;
                        color: #000;
                    }
                    .changeAnswerList {
                        font-size: 16px;
                        color: #000;
                        margin-top: 20px;
                        .changeAnswerItem {
                            margin-top: 10px;
                        }
                    }
                    .rightAnswers {
                        padding: 15px 0;
                        font-size: 16px;
                        color: #333;
                        border-top: 2px solid #eeeeee;
                        .topbox {
                            padding-bottom: 10px;
                        }
                    }
                }
                .answerList {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    border-top: 2px solid #eee;
                    box-sizing: border-box;
                    background-color: #fff;
                    .answerItem {
                        display: flex;
                        align-items: center;
                        margin-right: 20px;
                        cursor: pointer;
                        .btn {
                            width:16px;
                            height: 16px;
                            border-radius: 50%;
                            color: #edf8ff;
                            border: 1px solid #5c99e5;
                            box-sizing: border-box;
                            margin-right: 10px;
                        }
                    }
                    .upBtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 98px;
                        height: 40px;
                        font-size: 14px;
                        margin-right: 20px;
                        border-radius: 5px;
                        box-sizing: border-box;
                        border: 1px solid #aaa;
                        cursor: pointer;
                    }
                    .pgBtn {
                        border: none;
                        color: #fff;
                        background-color: #00ce64;
                    }
                }
            }
        }
    }
</style>