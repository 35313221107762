<!-- 执兽课程 -->
<template>
    <div class="content">
        <div class="titlelabel">
            <!-- <div class="title">热门视频</div> -->
            <!-- <div class="more moreclass" @click="jumppracticeTest()">
                前往执兽模拟
                <i class="el-icon-caret-right"></i>
            </div> -->
        </div>
        <div class="classListBox" v-for="(item, index) in videoclass" :key="index">
            <div class="titlelabel">
                <div class="title">{{ item.class_name }}</div>
                <div class="moreclass" @click="changeOff(item)">
                    更多{{ item.class_name }}
                    <i :class="item.openOff?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                </div>
            </div>
            <div class="videocont">
                <div class="videoitem" @click="jumpVideoListInner(item1)" v-for="(item1, index1) in item.threeLevelClassRecord" :key="index1" v-show="item.openOff || index1 < 5">
                    <div class="themeimg">
                        <img class="videoimgitem default" :src="item1.class_image?imgSrc + item1.class_image:require('../../assets/images/default.jpg')"></div>
                    <div class="videoinfo">
                        <div class="name">{{ item1.class_name }}</div>
                        <div class="teacher">讲师:{{ item1.lecturer_name_set.join(',') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 数据
            videoclass: []
        }
    },
    created() {
        const that = this
        that.getWebSiteClassList()
    },
    methods: {
        // 跳转执兽模拟考试
        jumppracticeTest(){
            window.open("https://www.chongyike.com/practiceTest/index.html#/")
        },
        // 跳转视频详情
        jumpVideoListInner(item){
            const that = this
            that.$router.push({
                path: '/videolistInner/' + item.class_id,
            })
        },
        // 获取视频系列列表
        getWebSiteClassList() {
            const that = this
            var data = {
                type: 'getWebSiteClassList',
                oneLevelClassId: 1
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].openOff = false
                    }
                    that.videoclass = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 展开收起
        changeOff(item){
            const that = this
            item.openOff = !item.openOff
        },
    }
}
</script>

<style lang="scss" scoped>
    .videocont {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        justify-content: flex-start;
        .videoitem {
            width: 18.8%;
            margin-right: 1.5%;
            position: relative;
            cursor: pointer;
            background: #fff;
            border-radius: 4px;
            box-shadow: 1px 5px 7px rgba(187, 187, 187, 0.65);
            display: flex;
            flex-direction: column;
            margin-bottom: 0.875rem;
            &:nth-child(5n){
                margin-right: 0;
            }
            &:hover {
                box-shadow: 2px 7px 11px rgb(217, 232, 247);
            }
            .themeimg {
                border-radius: 4px;
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 75%;
                overflow: hidden;
                .videoimgitem {
                    border-radius: 4px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .videoinfo {
                padding: 10px;
                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: rgba(68, 68, 68, 1);
                    font-size: 1.05rem;
                    font-weight: 600;
                }
                .teacher {
                    padding-top: 5px;
                    color: #444444;
                    font-size: 0.875rem;
                    font-weight: 400;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
</style>