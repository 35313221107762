import Vue from "vue"
import VueRouter from "vue-router"
import WxLogin from "../views/wxlogin.vue"
// 首页
import Home from "../views/home.vue"
import postDetail from "../views/home/postDetail.vue"
import medicalNews from "../views/home/medicalNews.vue"
import member from "../views/home/member.vue"
import search from "../views/home/search.vue"
import webAi from "../views/home/webAi.vue"
// 宠医客学苑
import onlineTraining from "../views/college/onlineTraining.vue"
import onlineTrainingDetail from "../views/college/onlineTrainingDetail.vue"
import livelist from "../views/college/livelist.vue"
import liveDetail from "../views/college/liveDetail.vue"
import videolist from "../views/college/videolist.vue"
import lecturer from "../views/college/lecturer.vue"
import lecturerDetail from "../views/college/lecturerDetail.vue"
import videolistInner from "../views/college/videolistInner.vue"
import videoDetail from "../views/college/videoDetail.vue"
// 分级学习
import gradeStudy from "../views/gradeStudy/gradeStudy.vue"
// 国际证书课
import offlineTraining from "../views/isvps/offlineTraining.vue"
import detail from "../views/isvps/detail.vue"
// 宠医圈
import postList from "../views/postHtml/postList.vue"
import allPost from "../views/postHtml/allPost.vue"
import postHtmlDetail from "../views/postHtml/postHtmlDetail.vue"
import medicalInformation from "../views/postHtml/medicalInformation.vue"
import post from "../views/postHtml/post.vue"
// 执兽课程
import trainVet from "../views/trainVet/trainVet.vue"
import trainVetInner from "../views/trainVet/trainVetInner.vue"
import subjects from "../views/trainVet/subjects.vue"
import previousExamPapers from "../views/trainVet/previousExamPapers.vue"
import myMistake from "../views/trainVet/myMistake.vue"
import myCollection from "../views/trainVet/myCollection.vue"
import pictureQuestion from "../views/trainVet/pictureQuestion.vue"
import answer from "../views/trainVet/answer.vue"
import myMistakeAnswer from "../views/trainVet/myMistakeAnswer.vue"
import testinCentre from "../views/trainVet/testinCentre.vue"
import myCollectionAnswer from "../views/trainVet/myCollectionAnswer.vue"
import pictureQuestionAnswer from "../views/trainVet/pictureQuestionAnswer.vue"
import trainVetVideoDetail from "../views/trainVet/trainVetVideoDetail.vue"
import trainList from "../views/trainVet/trainList.vue"



const routes = [
    { path: '/', component: Home, meta: { title: '宠医客' }, },
    { path: '/Home', component: Home ,meta: { title: '宠医客' }, },
    { path: '/member', component: member ,meta: { title: '宠医客-会员购买' }, },
    { path: '/postDetail/:id', component: postDetail ,meta: { title: '宠医客-资讯详情' } },
    { path: '/medicalNews', component: medicalNews ,meta: { title: '宠医资讯列表' }},
    { path: '/search', component: search ,meta: { title: '搜索' }},
    { path: '/webAi', component: webAi ,meta: { title: '宠医客-智答' }},
    { path: '/WxLogin', component: WxLogin },
    { path: '/onlineTraining', component: onlineTraining ,meta: { title: '宠医客-线上证书课' }},
    { path: '/onlineTrainingDetail/:id/', component: onlineTrainingDetail ,meta: { title: '宠医客-证书课详情' }},
    { path: '/liveDetail/:id/', component: liveDetail ,meta: { title: '宠医客-直播课' }},
    { path: '/livelist', component: livelist ,meta: { title: '宠医客-直播课程列表' }},
    { path: '/videolist', component: videolist ,meta: { title: '宠医客-视频课程列表' }},
    { path: '/lecturer', component: lecturer ,meta: { title: '宠医客-线上讲师列表' }},
    { path: '/lecturerDetail/:id/', component: lecturerDetail ,meta: { title: '宠医客-讲师详情' }},
    { path: '/videolistInner/:id/', component: videolistInner ,meta: { title: '宠医客-系列课列表' }},
    { path: '/videoDetail', component: videoDetail ,meta: { title: '宠医客-课程视频详情' }},
    { path: '/gradeStudy/:id/', component: gradeStudy ,meta: { title: '宠医客-分级学习' }},
    { path: '/offlineTraining', component: offlineTraining ,meta: { title: '宠医客-证书课' }},
    { path: '/detail/:id', component: detail ,meta: { title: '宠医客-专题课' }},
    { path: '/postList', component: postList ,meta: { title: '宠医客-宠医圈' }},
    { path: '/allPost', component: allPost ,meta: { title: '宠医客-全部病例' }},
    { path: '/postHtmlDetail/:id', component: postHtmlDetail ,meta: { title: '宠医客-帖子详情' }},
    { path: '/medicalInformation/:id', component: medicalInformation ,meta: { title: '宠医客-宠医圈医讯' }},
    { path: '/post', component: post ,meta: { title: '宠医客-发帖' }},
    { path: '/trainVet', component: trainVet ,meta: { title: '宠医客-执兽课程' }},
    { path: '/trainVetInner/:id/', component: trainVetInner ,meta: { title: '宠医客-执兽课程列表' }},
    { path: '/subjects', component: subjects ,meta: { title: '宠医客-科目练习' }},
    { path: '/previousExamPapers', component: previousExamPapers ,meta: { title: '宠医客-历年真题' }},
    { path: '/myMistake', component: myMistake ,meta: { title: '宠医客-我的错题' }},
    { path: '/myCollection', component: myCollection ,meta: { title: '宠医客-我的收藏' }},
    { path: '/pictureQuestion', component: pictureQuestion ,meta: { title: '宠医客-图文题型' }},
    { path: '/answer', component: answer ,meta: { title: '宠医客-答题' }},
    { path: '/myMistakeAnswer', component: myMistakeAnswer ,meta: { title: '宠医客-我的错题' }},
    { path: '/testinCentre', component: testinCentre ,meta: { title: '宠医客-考点练习' }},
    { path: '/myCollectionAnswer', component: myCollectionAnswer ,meta: { title: '宠医客-我的收藏' }},
    { path: '/pictureQuestionAnswer', component: pictureQuestionAnswer ,meta: { title: '宠医客-图文题型' }},
    { path: '/trainVetVideoDetail', component: trainVetVideoDetail ,meta: { title: '宠医客-视频详情' }},
    { path: '/trainList', component: trainList ,meta: { title: '宠医客-执兽视频' }},
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    // mode: 'history',
    routes // (缩写) 相当于 routes: routes
})

// 路由守卫跳转之后
router.afterEach((to, from ,next)=>{
    document.title = to.meta.title
    window.scrollTo(0,0);
})

// 4. 创建和挂载根实例。
// 记得要通过 router 配置参数注入路由，
// 从而让整个应用都有路由功能
Vue.use(VueRouter)
export default router