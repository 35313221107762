<template>
    <div>
        <div class="title">
            <div class="text">
                {{ videoInfo.name }}
            </div>
        </div>
        <div class="content">
            <!--阿里云播放器-->
            <!-- <ali-player :encryptType="1"
                v-if="showVideo"
                :vid="vid"
                :source="source" 
                ref="player"
                :autoplay="false"
                :isLive="false"
                :cover="cover"
                width="1170px"
                :height="1170/(16/9)+'px'"
                showBarTime="500"
                controlBarVisibility="always"
                @startPlay="startPlay"
                @pausePlay="pausePlay"
                @startSeek="startSeek"
                @error="error"
                @inserVideEndRecord="inserVideEndRecord"
                >
            </ali-player> -->
            
            <div class="volBox">
                <vue-aliplayer-v2
                    :encryptType="1"
                    v-if="showVideo"
                    :vid="vid"
                    :source="source" 
                    ref="player"
                    :isLive="false"
                    :cover="cover"
                    :options="options"
                    @ready="readyPlay" 
                    @play="startPlay"
                    @pause="pausePlay"
                    @error="error"
                    @ended="inserVideEndRecord"
                    @snapshoted="snapshoted"
                />

                <div class="bannerVideoBox" @click="bannerVideoPlay()" v-if="baneerShow">
                    <video id="bannerVideo" class="video" :controls="false" playsinline :src="bannerVideo" v-if="bannerVideo"></video>
                    <div class="closeBannerBtn" v-show="bannerTime > 0" @click="closeBanner()">
                        {{ bannerTime }}秒会员关闭广告
                    </div>
                    <img class="bannerPlayBtn" v-show="playBtnShow" src="../../assets/images/play.png" alt="">
                </div>

                <div class="noVipBox" v-if="playType <= 0 && !showVideo">
                    <img class="coverBgi" src="../../assets/images/livebg.png" alt="">
                    <div class="toastBox">
                        <div class="titles">{{ videoInfo.name }}</div>
                        <div class="typeBox">
                            <span v-show="saleVipPrice < 0.01" >会员免费</span>
                            <span v-show="saleVipPrice >= 0.01">会员价:{{ saleVipPrice }}</span>
                        </div>
                        <div class="btnBox">
                            <el-button size="small" type="primary" @click="reload()">刷新</el-button>
                            <el-button v-show="saleVipPrice < 0.01" size="small" type="primary" @click="jumpMember()">购买会员</el-button>
                            <el-button v-show="saleVipPrice >= 0.01" size="small" type="primary" @click="goAppToast()">购买课程</el-button>
                        </div>
                    </div>
                </div>

                <transition name="el-fade-in-linear">
                        <div v-show="addShow" class="transitionBox">
                            <img class="voiceIcon" src="../../assets/images/voiceIcon.png" alt="">{{ volumeNum }}%
                        </div>
                </transition>
            </div>
        </div>
        <div class="videoDetail">
            <h1 class="videoDetailTitle">{{ videoDetail.name }}</h1>
            <!-- <img class="videoDetailImg" :src="imgSrc + videoDetail.videoImage" alt=""> -->
            <div class="videoDetailContent" v-html="videoDetailContent"></div>
        </div>
    </div>
  </template>
  <script>
    import VueAliplayer from "../../components/AliPlayer";
    import VueAliplayerV2 from 'vue-aliplayer-v2';
    export default {
        components:{ VueAliplayerV2: VueAliplayerV2.Player,"ali-player": VueAliplayer },
        data() {
        return {
			imgSrc: "https://case.chongyike.com",
            cover: '', //视频封面
            vid: '', //视频vid
            playAuth: '', //鉴权地址
            videoInfo: {},
            source: "",
            // 视频详情
            videoDetail: {},
            // 第一次调用视频详情地址
            playOff: true,
            // 视频详情内容
            videoDetailContent: "",
            // 观看时长秒数
            timeNum: 0,
            // 播放状态ture为播放中，false为暂停
            playingStatus: false,
            // 展示播放器
            showVideo: false,
            // 广告视频地址
            bannerVideo: "",
            // 显示隐藏广告
            baneerShow: false,
            // 广告时长
            bannerTime: "",
            // 是否是会员
            isVip: false,
            // 播放按钮显示
            playBtnShow: false,
            // 开始播放时间
            startTime: "",
            // 观看状态
            playType: 0,
            // 课程价格
            saleVipPrice: 0,
            // 当前音量
            volumeNum: 100,
            // 防抖函数
            volumeFun: {},
            // 音量框显示
            addShow: false,
            // 配置参数
            options: {
                width: "1170px",
                height: 1170/(16/9)+"px",
                autoplay: false,
                isLive: false,
                // 配置按钮
                skinLayout: [{
                        "name": "bigPlayButton",
                        "align": "cc"
                    },
                    {
                        "name": "H5Loading",
                        "align": "cc"
                    },
                    {
                        "name": "errorDisplay",
                        "align": "tlabs",
                        "x": 0,
                        "y": 0
                    },
                    {
                        "name": "infoDisplay"
                    },
                    {
                        "name": "tooltip",
                        "align": "blabs",
                        "x": 0,
                        "y": 56
                    },
                    {
                        "name": "thumbnail"
                    },
                    {
                        "name": "controlBar",
                        "align": "blabs",
                        "x": 0,
                        "y": 0,
                        "children": [{
                            "name": "progress",
                            "align": "blabs",
                            "x": 0,
                            "y": 44
                        },
                        {
                            "name": "playButton",
                            "align": "tl",
                            "x": 15,
                            "y": 12
                        },
                        {
                            "name": "timeDisplay",
                            "align": "tl",
                            "x": 10,
                            "y": 7
                        },
                        {
                            "name": "fullScreenButton",
                            "align": "tr",
                            "x": 10,
                            "y": 12
                        },
                        {
                            "name": "setting",
                            "align": "tr",
                            "x": 15,
                            "y": 12
                        },
                        {
                            "name": "volume",
                            "align": "tr",
                            "x": 5,
                            "y": 10
                        },
                        {
                            "name": "snapshot",
                            "align": "tr",
                            "x": 10,
                            "y": 12
                        }
                    ]
                }
            ]
            }
        }
      },
        created() {
            const that = this
            // 因为第一次进入时播放器加载有问题 所以刷新一次
            // if(!window.localStorage.getItem("videoDetail")){
            //     window.localStorage.setItem("videoDetail", 1)
            //     window.location.reload()
            // }else {
                // window.localSinstancetorage.removeItem("videoDetail")
                that.videoInfo = JSON.parse(that.decrypt(this.$route.query.text))
                that.getUserVipTime()
                that.getVideoDetailInfo()
            // }
            window.addEventListener('pagehide', async function (event) {
                that.inserVipVideTime2()
            });
            //禁止鼠标右击
            document.oncontextmenu = function () {
                event.returnValue = false;
            };
            //禁用开发者工具F12
            document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
                let e = event || window.event || arguments.callee.caller.arguments[0];
                if (e && e.keyCode == 123) {
                    e.returnValue = false;
                    return false;
                }
            };
            let userAgent = navigator.userAgent;
            if (userAgent.indexOf("Firefox") > -1) {
                let checkStatus;
                let devtools = /./;
                devtools.toString = function () {
                    checkStatus = "on";
                };
                setInterval(function () {
                    checkStatus = "off";
                    console.log(devtools);
                    console.log(checkStatus);
                    console.clear();
                    if (checkStatus === "on") {
                        let target = "";
                        try {
                            window.open("about:blank", (target = "_self"));
                        } catch (err) {
                            let a = document.createElement("button");
                            a.onclick = function () {
                                window.open("about:blank", (target = "_self"));
                            };
                            a.click();
                        }
                    }
                }, 200);
            } else {
                //禁用控制台
                let ConsoleManager = {
                    onOpen: function () {
                        alert("Console is opened");
                    },
                    onClose: function () {
                        alert("Console is closed");
                    },
                    init: function () {
                        let self = this;
                        let x = document.createElement("div");
                        let isOpening = false,
                            isOpened = false;
                        Object.defineProperty(x, "id", {
                            get: function () {
                                if (!isOpening) {
                                    self.onOpen();
                                    isOpening = true;
                                }
                                isOpened = true;
                                return true;
                            }
                        });
                        setInterval(function () {
                            isOpened = false;
                            console.info(x);
                            console.clear();
                            if (!isOpened && isOpening) {
                                self.onClose();
                                isOpening = false;
                            }
                        }, 200);
                    }
                };
                ConsoleManager.onOpen = function () {
                    //打开控制台，跳转
                    let target = "";
                    try {
                        window.open("about:blank", (target = "_self"));
                    } catch (err) {
                        let a = document.createElement("button");
                        a.onclick = function () {
                            window.open("about:blank", (target = "_self"));
                        };
                        a.click();
                    }
                };
                ConsoleManager.onClose = function () {
                    alert("Console is closed!!!!!");
                };
                ConsoleManager.init();
            }
        },
        // mounted(){
        //     window.addEventListener("keydown", this.keydownClick)
        // },
        beforeDestroy() {
            window.removeEventListener('beforeunload', this.showLeaveConfirmation);
        },
        beforeRouteLeave(to, from, next) {
            this.inserVipVideTime2()
            next()
        },
        methods: {
            // 键盘按下事件
            keydownClick(e){
                const that = this 
                var keyCode = e.keyCode
                if(keyCode == "32"){
                    // 禁用默认按键方法
                    e.preventDefault()
                    // 空格切换暂停播放
                    if(that.playingStatus){
                        that.$refs.player.pause()
                    }else {
                        that.$refs.player.play()
                    }
                }else if(keyCode == "37"){
                    // 左箭头
                    var positionTime = Math.floor(that.$refs.player.getCurrentTime())
                    that.$refs.player.seek(positionTime - 10)
                }else if(keyCode == "39"){
                    // 右箭头
                    var positionTime = Math.floor(that.$refs.player.getCurrentTime())
                    that.$refs.player.seek(positionTime + 10)
                }else if(keyCode == "38"){
                    // 上箭头
                    e.preventDefault()
                    that.addShow = true
                    var vol = that.$refs.player.getVolume()*100
                    var timeNum = 0
                    if(vol < 100){
                        // 超过一百设置为一百
                        if(vol + 5 > 100){
                            that.$refs.player.setVolume(1)
                        }else{
                            timeNum = 5
                            that.$refs.player.setVolume(Math.floor(vol+timeNum)/100)
                        }
                    }
                    that.volumeNum = Math.floor(vol+timeNum)
                    if(that.volumeFun){
                        clearTimeout(that.volumeFun);
                    }
                    that.volumeFun = setTimeout(() => {
                        that.addShow = false
                    }, 1000);
                }else if(keyCode == "40"){
                    // 下箭头
                    e.preventDefault()
                    that.addShow = true
                    var vol = that.$refs.player.getVolume()*100
                    var timeNum = 0
                    if(vol > 5){
                        timeNum = 5
                        that.$refs.player.setVolume(Math.floor(vol-timeNum)/100)
                    }
                    that.volumeNum = Math.floor(vol-timeNum)
                    if(that.volumeFun){
                        clearTimeout(that.volumeFun);
                    }
                    that.volumeFun = setTimeout(() => {
                        that.addShow = false
                    }, 2000);
                }
            },
            // 提示购买
            goAppToast(){
                this.$message({
                    showClose: true,
                    message: "请前往宠医客APP进行购买",
                    type: 'success',
                    duration: 2000
                });
            },
            // 跳转购买会员
            jumpMember(){
                this.$router.push({
                    path: '/member',
                })
            },
            // 刷新
            reload(){
                window.location.reload()
            },
            startSeek(e){
                const that = this
            },
            showLeaveConfirmation(event) {
                this.inserVipVideTime2()
                const message = '您确定要离开此网站吗？';
                event.returnValue = message; // 兼容性设置
                return message;
            },
            // 请求视频地址
            getVideoPlayParameters(){
                const that = this
                var data = {
                    type: 'getVideoPlayParameters',
                    dataType: that.videoInfo.dataType,
                    dataNo: that.videoInfo.No,
                    classType: that.videoInfo.classType,
                    classId: that.videoInfo.classId
                }
                this.get("/4/handler", data).then((res) => {
                    if (res.data.code == "success") {
                        that.getVideoAd()
                        var result = JSON.parse(this.decrypt(res.data.result))
                        var mdNo = that.hex_md5(that.videoInfo.No).substring(3, 19)
                        var oneData = JSON.parse(that.decrypt(result.playInfoStr, mdNo))
                        var mdTowNo = that.hex_md5(that.videoInfo.No).substring(2, 18);
                        var sourceBox = that.decrypt(oneData.source, mdTowNo);
                        var data = {
                            SD: JSON.parse(sourceBox).SD
                        }
                        console.log(JSON.parse(sourceBox))
                        that.playType = result.playType
                        if(result.playType > 0){
                            that.showVideo = true
                            that.cover = that.imgSrc + result.videoImage
                            that.source = JSON.stringify(data)
                            that.vid = oneData.aliVid
                            setTimeout(() => {
                                if(result.historyPosition){
                                    that.$refs.player.seek(result.historyPosition)
                                }
                            }, 1500);
                            that.playRecord(result.historyPosition)
                        }else {
                            var saleVipPrice = result.saleVipPrice
                            that.saleVipPrice = saleVipPrice
                        }
                    }
                })
            },
            // 获取视频详情
            getVideoDetailInfo(){
                const that = this
                var data = {
                    type: 'getVideoDetailInfo',
                    videoNo: that.videoInfo.No,
                }
                this.get("/webhttp/mobileHandler", data).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        document.title = result.video.videoName + '-宠医客'
                        that.videoDetail = result.video
                        that.getVideoPlayParameters()
                        that.getDataInfo()
                    }
                })
            },
            // 播放记录上传
            playRecord(historyPosition){
                const that = this
                var playPosition = 0
                if(historyPosition){
                    playPosition = historyPosition
                }
                if(!that.playOff){
                    playPosition = Math.floor(that.$refs.player.getCurrentTime())
                }
                var data = {
                    type: 'playRecord',
                    videoNo: that.videoInfo.No,
                    playPosition: playPosition
                }
                this.get("/3/handler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.playOff = false
                        setTimeout(() => {
                            that.playRecord()
                        }, 20000);
                    }
                })
            },
            // 视频介绍详情
            getDataInfo(){
                const that = this
                var data = {
                    type: 'getDataInfo',
                    dataNo: that.videoInfo.No,
                    dataType: "15" // 视频15  直播17  执兽83
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.videoDetailContent = result.Demandxq.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (
                            match, p1) => {
                            return `<img style="width:100%;display:block;" src='${'https://case.chongyike.com' + p1}' />`
                        })
                    }
                })
            },
            // 记录播放时长
            inserVipVideTime2(){
                const that = this
                if(that.timeNum < 5){
                    return false
                }
                localStorage.setItem('lookTime', that.timeNum);
                var data = {
                    type: 'videoDurationRecord',
                    videoNo: that.videoInfo.No,
                    startTime: that.startTime,
                    duration: that.timeNum,
                    videoPosition: Math.floor(that.$refs.player.getCurrentTime())
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        that.startTime = new Date().getTime()
                        that.timeNum = 0
                    }
                })
            },
            // 计时器
            timer(){
                const that = this
                setTimeout(() => {
                    if(that.playingStatus){
                        that.timeNum+=1
                        that.timer()
                    }
                }, 1000);
            },
            // 准备完成
            readyPlay(){
                const that = this
                window.addEventListener("keydown", this.keydownClick)
            },
            // 开始播放
            startPlay(){
                const that = this
                that.playingStatus = true
                that.startTime = new Date().getTime()
                that.timer()
                window.addEventListener('beforeunload', this.showLeaveConfirmation);
            },
            // 暂停播放
            pausePlay(){
                const that = this
                that.playingStatus = false
                that.inserVipVideTime2()
            },
            // 报错
            error(e){
                const that = this
                console.log("报错了 ")
                console.log(e)
            },
            // 播放完成
            inserVideEndRecord(){
                const that = this
                var data = {
                    type: 'inserVideEndRecord',
                    videoNo: that.videoInfo.No,
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                    }
                })
            },
            // 加载广告
            getVideoAd(){
                const that = this
                var data = {
                    type: 'getVideoAd',
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        if(result){
                            that.bannerVideo = result.video_url
                            that.baneerShow = true
                            that.playBtnShow = true
                        }
                    }
                })
            },
            // 广告播放开始
            bannerVideoPlay(){
                const that = this
                var video = document.querySelector('#bannerVideo')
                that.playBtnShow = false
                video.play()
                that.bannerTime = Math.ceil(video.duration)
                that.bannerVideoTime()
            },
            // 广告播放倒计时
            bannerVideoTime(){
                const that = this
                if(that.bannerTime <= 0){
                    that.baneerShow = false
                }else {
                    setTimeout(() => {
                        that.bannerTime--
                        that.bannerVideoTime()
                    }, 1000);
                }
            },
            // 会员关闭广告
            closeBanner(){
                const that = this
                if(that.isVip){
                    that.bannerTime = 0
                    that.baneerShow = false
                }else {
                    this.$confirm('是否前往购买会员?', {
                        confirmButtonText: '前往购买',
                        cancelButtonText: '取消',
                    }).then(() => {
                        that.$router.push({
                            path: '/member',
                        })
                    })
                }
            },
            // 获取会员信息
            getUserVipTime(){
                const that = this
                var data = {
                    type: 'getUserVipTime',
                }
                this.get("/webhttp/mobileHandler", data, 1).then((res) => {
                    if (res.data.code == "success") {
                        var result = res.data.result
                        if(result){
                            that.isVip = true
                        }
                    }
                })
            },
            snapshoted(data){
                var pictureData = data.paramData.base64
                var base64 = pictureData.toString(); // img 就是base64
                var byteCharacters = atob(
                base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
                );
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], {
                type: undefined,
                });
                var aLink = document.createElement("a");
                aLink.download = data.paramData.time + ".jpg"; //这里写保存时的图片名称
                aLink.href = URL.createObjectURL(blob);
                aLink.click();

            },
        }
    }
  </script>
  <style scoped lang="scss">
    .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 88px;
        background-color: #4198e2;
        .text {
            width: 1170px;
            padding-left: 10px;
            font-size: 1.75rem;
            font-weight: normal;
            color: #fff;
            box-sizing: border-box;
            margin: 0 auto;
        }
    }
    .content {
        overflow: hidden;
        width: 100% !important;
        display: flex;
        justify-content: center;
        padding: 0;
        border: none;
        background-color: #474747;
        .bannerVideoBox {
            position: absolute;
            z-index: 1000;
            cursor: pointer;
            .video {
                width: 1170px;
            }
            .closeBannerBtn {
                font-size: 14px;
                color: #fff;
                background-color: #666;
                position: absolute;
                right: 20px;
                top: 20px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                padding: 0 10px;
                cursor: pointer;
            }

            .bannerPlayBtn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                background-color: #fff;
                border-radius: 50%;
            }
        }

        .noVipBox {
            position: absolute;
            z-index: 1001;
            .coverBgi {
                width: 1170px;
                height: 660px;
            }
            .toastBox {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 30px 50px;
                border-radius: 10px;
                background-color: #fff;
                user-select: none;
                .titles {
                    color: #4198e2;
                    text-shadow: 0px 2px 7px #b1afaf;
                }
                .typeBox {
                    color: #c3911c;
                    padding: 15px 0;
                }
            }
        }
    }
    .videoDetail {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 1170px;
        margin: 0 auto;
        .videoDetailTitle {
            padding: 30px 0;
            font-size: 32px;
            color: #444444 ;
        }
        .videoDetailContent {
            padding:  0 30px;
        }
        .videoDetailImg {
            width: 1170px;
        }
    }
    
    .volBox {
        position: relative;
        width: 1170px;
        height: 660px;
    }
    .transitionBox {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120px;
        height: 120px;
        z-index: 10000;
        transform: translate(-50%, -50%);
        background-color: rgba(0,0,0,0.5);
        border-radius: 5px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .voiceIcon {
            width: 50px;
            margin-right: 10px;
        }
    }
  </style>
   