<!-- 分级学习 -->
<template>
    <div class="content">
        <div class="videoInfoBox">
            <div class="imgBox">
                <img class="videoImg" :src="imgSrc + gradeInfo.image" alt="">
            </div>
            <div class="videoInfo">
                <div class="videoTitle">{{ gradeInfo.name }}</div>
                <div class="videoDetails">{{ gradeInfo.vip_desc2 }}</div>
                <div class="priceBox">
                    价格：
                    <span class="price">¥{{ gradeInfo.vip_money }}/年</span>
                    <span class="isVip">会员免费</span>
                </div>
                <div class="toastBox">每个分级及专题都有配套考核，立即前往app考核</div>
            </div>
        </div>
        <div class="mainBox">
            <div class="leftBox" id="leftBox">
                <div class="courseListItem" v-for="(item, index) in courseTitleList" :key="index" @click="getSkillVideoList2(item,index)">
                    <div class="courseListTitle">
                        {{ item.skill_name }}
                        <i class="number">已观看{{ item.percentage }}%&nbsp;&nbsp;&nbsp;{{ item.video_count }}节</i>
                        <img class="titleIcon" src="../../assets/images/gradeStudyTitleIcon.png" alt="">
                    </div>
                    <div class="courseitemList" v-show="item.off">
                        <div class="courseitem" v-for="(item1, index1) in item.videoList" :key="index1" @click.stop="jumpVideoDetail(item1)">
                            <div class="courseTitle">
                                {{item1.videoName}}
                            </div>
                            <div class="hospital">
                                {{item1.teachers}}
                            </div>
                            <div class="courseTime">
                                {{item1.times}}分钟
                            </div>
                            <div class="watchSchedule">
                                已观看{{item1.jindu}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightBox">
                <div class="scheduleBox">
                    <div class="time" id="lookTimes">
                        今日已学习{{ userInfo.today_look_time/60 }}分钟
                    </div>
                    <div class="jdt">
                        <div class="overjtd" :style="'width:' + Math.ceil(userInfo.look_time_length/userInfo.video_length*100)*2.9  + 'px;'" id="overjtd"></div>
                    </div>
                    <div class="schedule" id="schedule">去学习</div>
                </div>
                <div class="titleBox">
                    分级学习
                </div>
                <div class="bannerBox">
                    <div class="boxItem">
                        <div class="item1">
                            <img class="icon" src="../../assets/images/gradeStudyIcon1.png" alt="">
                            <div class="detailsBox">
                                <div class="tops">六大成长阶段必备技能学习</div>
                                <div class="btms">初中高级助理｜门诊医生｜主治医生｜主任医生</div>
                            </div>
                        </div>
                        <div class="item1">
                            <img class="icon" src="../../assets/images/gradeStudyIcon2.png" alt="">
                            <div class="detailsBox">
                                <div class="tops">系统学习有考核</div>
                                <div class="btms">每阶段必学技能点｜配套考核测试</div>
                            </div>
                        </div>
                        <div class="item1">
                            <img class="icon" src="../../assets/images/gradeStudyIcon1.png" alt="">
                            <div class="detailsBox">
                                <div class="tops">中国兽医协会审核通过课程</div>
                                <div class="btms">获得小专题证书或分级证书可申请继续教育学分</div>
                            </div>
                        </div>
                    </div>
                    <div class="boxItem">
                        <div class="t1">目前仅APP端支持考核功能</div>
                        <div class="t2">每个阶段学习包含</div>
                        <div class="tagList">
                            <img class="tagItem" src="../../assets/images/gradeStudyTag1.png" alt="">
                            <img class="tagItem" src="../../assets/images/gradeStudyTag2.png" alt="">
                            <img class="tagItem" src="../../assets/images/gradeStudyTag3.png" alt="">
                            <img class="tagItem" src="../../assets/images/gradeStudyTag4.png" alt="">
                        </div>
                    </div>
                    <div class="boxItem">
                        <div class="t1">适合人群</div>
                        <div class="t2">终身学习的宠物医疗行业从业者，包括：<br>即将进入临床工作的学生、宠医助理、各阶段宠物医生、院长等</div>
                    </div>
                    <div class="boxItem">
                        <div class="t1">分级学习特色</div>
                        <img class="ladder" src="../../assets/images/gradeStudyLadder.png" alt="">
                    </div>
                    <div class="boxItem">
                        <div class="t1">来学习 实现三大突破</div>
                        <div class="tagList">
                            <img class="tagItem1" src="../../assets/images/gradeStudyIcon4.png" alt="">
                            <img class="tagItem1" src="../../assets/images/gradeStudyIcon5.png" alt="">
                            <img class="tagItem1" src="../../assets/images/gradeStudyIcon6.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 分级id
            gradeId: "",
            // 分级介绍
            gradeInfo: {},
            // 课程标题列表
            courseTitleList: [],
            // 课程视频列表
            videoList: [],
            // 学习数据
            userInfo: {
                today_look_time: 0,
                video_length: 0
            }
        }
    },
    created(){
        const that = this
        that.gradeId = this.$route.params.id
        that.getGradeInfo()
    },
    watch: {
        $route(to, from){
            const that = this
            that.gradeId = to.params.id
            that.getGradeInfo()
        }
    },
    methods: {
        // 跳转视频详情
        jumpVideoDetail(item){
            const that = this
            var data = {
                dataType: 0,
                No: item.videoNo,
				name: item.videoName,
                classType: 1,
            }
            that.$router.push({
                path: '/videoDetail',
                query: {
                    text: that.encrypt(JSON.stringify(data))
                }
            })
        },
        // 获取分级学习简介
        getGradeInfo(){
            const that = this
            var data = {
                type: 'getGradeInfo',
                gradeId: that.gradeId,
            }
            this.get("/3/gradeStudy", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.gradeInfo = result
                    that.getGradeSkill()
                    that.getGradeStudyStatistics()
                }
            })
        },
        // 获取课程标题列表
        getGradeSkill(){
            const that = this
            var data = {
                type: 'getGradeSkill',
                gradeId: that.gradeId,
                index: 0,
                count: 100,
            }
            this.get("/3/gradeStudy", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].index = i
                        result[i].off = false
                        result[i].videoList = []
                        if(result[i].look_time_length == null){
                            result[i].look_time_length = 0
                        }
                        result[i].percentage = Math.ceil(result[i].look_time_length/result[i].video_length*100)
                    }
                    that.courseTitleList = result
                }
            })
        },
        // 获取课程列表
        getSkillVideoList2(item, index){
            const that = this
            item.off = !item.off
            if(item.videoList.length > 0){
                return false
            }
            var data = {
                type: 'getSkillVideoList2',
                skillId: item.skill_id,
                index: 0,
                count: 100,
            }
            this.get("/3/gradeStudy", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    for (let i = 0; i < result.length; i++) {
                        result[i].times = Math.floor(result[i].videoLength/60)
                        if(!result[i].look_length){
                            result[i].look_length = 0
                        }
                        result[i].jindu = Math.floor(result[i].look_length/result[i].videoLength*100)
                    }
                    that.courseTitleList[index].videoList = result
                } 
            })
        },
        // 获取学习时长
        getGradeStudyStatistics(){
            const that = this
            var data = {
                type: 'getGradeStudyStatistics',
                gradeId: that.gradeId,
            }
            this.get("/3/gradeStudy", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    console.log(result)
                    that.userInfo = result
                } 
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    .row {
        height: 60px;
        line-height: 60px;
        .crumbs {
            display: flex;
            align-items: center;
            color: #888;
            a {
                color: #888;
            }
            .at {
                color: #1F6EAF;
            }
        }
    }
    .videoInfoBox {
        width: 1110px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .imgBox {
            width: 190px;
            height: 135px;
            .videoImg {
                width: 190px;
                height: 135px;
            }
        }
        .videoInfo {
            width: 900px;
            height: 135px;
            .videoTitle {
                font-size: 20px;
                color: #444;
                margin-bottom: 5px;
            }
            .videoDetails {
                font-size: 14px;
                color: #444;
                height: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                margin-bottom: 5px;
            }
            .priceBox {
                font-size: 16px;
                color: #666;
                margin-bottom: 3px;
                .price {
                    color: #E02020;
                }
                .isVip {
                    color: #0091FF;
                }
            }
            .toastBox {
                font-size: 12px;
                color: #AAAAAA;
            }
        }
    }
    .mainBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // margin-bottom: 30px;
        padding-bottom: 50px;
        .leftBox {
            width: 760px;
            .courseListItem {
                .courseListTitle {
                    position: relative;
                    height: 42px;
                    line-height: 42px;
                    font-size: 15px;
                    padding: 0 15px;
                    box-sizing: border-box;
                    color: #fff;
                    background-color: #1F6EAF;
                    cursor: pointer;
                    .number {
                        position: absolute;
                        right: 50px;
                        font-size: 15px;
                        color: #fff;
                        font-style: normal;
                    }
                    .titleIcon {
                        position: absolute;
                        right: 20px;
                        top: 14px;
                        width: 14px;
                        height: 14px;
                    }
                }
                .courseitem {
                    display: flex;
                    align-items: center;
                    height: 48px;
                    font-size: 13px;
                    color: #464646;
                    padding: 0 15px;
                    background-color: #f5f9fc;
                    cursor: pointer;
                    .courseTitle {
                        width: 300px;
                    }
                    .hospital {
                        width: 240px;
                    }
                    .courseTime {
                        width: 100px;
                    }
                    .watchSchedule {
                        width: 100px;
                    }
                    .max {
                        color: #aaa;
                    }
                }
            }
        }
        .rightBox {
            width: 330px;
            .scheduleBox {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 330px;
                height: 108px;
                border: 1px solid #d1e2ea;
                border-radius: 3px;
                background-color: #f5f9fb;
                box-sizing: border-box;
                padding: 15px 20px;
                .time {
                    font-size: 22px;
                    color: #333;
                }
                .jdt {
                    width: 290px;
                    height: 10px;
                    margin: 5px 0;
                    border-radius: 100px;
                    background-color: #ddeef7;
                    .overjtd {
                        height: 10px;
                        background-color: #1f6eaf;
                        border-radius: 100px;
                    }
                }
            }

            .titleBox {
                height: 70px;
                line-height: 70px;
                font-size: 20px;
                color: #444;
            }

            .bannerBox {
                padding: 0 14px;
                box-shadow: 0 0 5px 5px #eee;
                .boxItem {
                    padding: 20px 0;
                    border-bottom: 2px solid #f4f4f4;
                    .item1 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 37px;
                        margin-bottom: 15px;
                        &:nth-child(3){
                            margin: 0;
                        }
                        .detailsBox {
                            width: 253px;
                            .tops {
                                font-size: 14px;
                            }
                            .btms {
                                font-size: 12px;
                            }
                        }
                        .icon {
                            width: 32px;
                        }
                    }

                    .t1 {
                        font-size: 14px;
                        line-height: 19px;
                        margin-bottom: 8px;
                    }
                    .t2 {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    .tagList {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        padding-top: 10px;
                        .tagItem {
                            width: 144px;
                            height: 54px;
                            margin-bottom: 8px;
                            &:nth-child(n+3){
                                margin:0;
                            }
                        }
                        .tagItem1 {
                            width: 96px;
                            height: 129px;
                        }
                    }

                    .ladder {
                        width: 286px;
                        height: 230px;
                    }
                }
            }
        }
    }
}
</style>