<!-- 帖子详情 -->
<template>
    <div class="content">
        <div class="userInfoBox">
            <img class="userImg" :src="postInfo.userHeadPortrait?imgSrc + postInfo.userHeadPortrait: require('../../assets/images/user.png')" alt="">
            <div class="infoBox">
                <div class="userName">{{ postInfo.userName }}</div>
                <div class="time" v-if="postInfo.marder_time">{{ postInfo.marder_time.slice(0, 10) }}</div>
                <div class="iconList">
                    <i class="el-icon-view"></i>
                    <span>{{ postInfo.looknumber }}</span>
                    <i class="el-icon-chat-dot-square"></i>
                    <span>{{ postInfo.replynumber }}</span>
                    <i class="el-icon-thumb"></i>
                    <span>{{ postInfo.pub_clickalike }}</span>
                    <i class="el-icon-star-off collect"></i>
                    <span class="collect">收藏</span>
                </div>
            </div>
        </div>
        <div class="postTitle">
            <span v-show="Department==0">[内科]</span>
            <span v-show="Department==1">[外科]</span>
            <span v-show="Department==2">[其他]</span>
            <span v-show="Department==3">[皮肤]</span>
            <span v-show="Department==4">[肿瘤]</span>
            <span v-show="Department==5">[猫科]</span>
            <span v-show="Department==6">[影像学]</span>
            <span v-show="Department==7">[特种动物]</span>
            <span v-show="Department==8">[中兽医]</span>
            <span v-show="Department==9">[眼科]</span>
            <span v-show="Department==10">[心脏病]</span>
            <span v-show="Department==11">[寄生虫]</span>
            <span>{{ Title }}</span>
        </div>
        <div class="description">
            <div class="descriptionTitle">
                详情描述
            </div>
            <div class="descriptionText">
                {{ Description }}
            </div>
            <div class="imgList">
                <div class="imgItem" v-for="(item, index) in postImgListData" :key="index" @click="openBigImg(item)">
                    <img :src="imgSrc + item.t_Small_imgurl" alt="">
                </div>
            </div>
        </div>
        <div class="bigImg" v-show="bigImgShow" @click="bigImgShow = false">
            <img class="imgUrl" :src="bigImgUrl" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			imgSrc: "https://case.chongyike.com",
            // 帖子ID
            dataNo: "",
            // 标题
            Title: "",
            // 科室
            Department: 0,
            // 文字描述
            Description: "",
            // 帖子全部信息
            postInfo: {},
            // 图片地址
            postImgListData: [],
            // 放大图片
            bigImgUrl: "",
            // 大图开关
            bigImgShow: false
        }
    },
    created(){
        const that = this
        that.dataNo = this.$route.params.id
        that.getDataInfo()
        that.getPostImages()
    },
    methods: {
        // 获取帖子详情
        getDataInfo(){
            const that = this
            var data = {
                type: 'getDataInfo',
                dataNo: that.dataNo,
                dataType: 9
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    if(res.data.result){
                        var result = res.data.result
                        that.postInfo = result
                        that.Title = result.Title
                        that.Department = result.Department
                        that.Description = result.Description
                    }else {
                        this.$message({
                            showClose: true,
                            message: "帖子已删除,即将返回上一页",
                            type: 'error',
                            duration: 2000
                        });
                        // 
                        setTimeout(() => {
                            that.$router.back()
                        }, 1000);
                    }
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 获取图片内容
        getPostImages(){
            const that = this
            var data = {
                type: 'getPostImages',
                postId: that.dataNo,
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                    that.postImgListData = result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 收藏
        uploadCollection(){
            const that = this
            var data = {
                type: 'getDataInfo',
                dataNo: that.dataNo,
                dataType: 9,
                dataTitle: that.Title
            }
            this.get("/webhttp/mobileHandler", data).then((res) => {
                if (res.data.code == "success") {
                    var result = res.data.result
                } else {
                    console.log(res.data.message)
                }
            })
        },
        // 查看大图
        openBigImg(item){
            const that = this
            that.bigImgUrl = that.imgSrc + item.t_Big_imgurl
            that.bigImgShow = true
        },
    },
}
</script>

<style lang="scss" scoped>

.userInfoBox {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    .userImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .infoBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80px;
        font-size: 14px;
        color: #444;
        .iconList {
            display: flex;
            align-items: center;
            i {
                font-size: 20px;
                margin-right: 5px;
            }
            span {
                margin-right: 25px;
            }
            .collect {
                cursor: pointer;
            }
        }
    }
}
.postTitle {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    padding: 10px 20px;
    span {
        margin-right: 10px;
    }
}

.description {
    font-size: 14px;
    color: #444;
    padding: 0 30px;

    .descriptionTitle {
        font-size: 16px;
        font-weight: 600;
        color: #58a8e9;
        padding-bottom: 5px;
    }
    .descriptionText {
        word-break: break-all;
        word-wrap: break-word;
    }
    .imgList {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        .imgItem {
            width: 270px;
            height: 270px;
            margin-left: 10px;
            margin-bottom: 10px;
            &:nth-child(4n+1){
                margin-left: 0;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
}

.bigImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    .imgUrl {
        height: 100vh;
    }
}
</style>