<template>
    <div>
        <div class="loginBox"  @click="show()">
            <div id="login_container"></div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    created() {
        this.loadSDK();
    },
    methods: {
        show(){ 
		    console.log(this.$route.query)
        },
        loadSDK() {
            const script = document.createElement('script');
            script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
            script.onload = this.initSDK;
            document.body.appendChild(script);
        },
        initSDK() {
            var url = encodeURIComponent(this.$route.query.href)
            console.log(url)
            const obj = new WxLogin({
                self_redirect: false,
                id: "login_container",
                appid: "wxe3eda6b26146195b",
                scope: "snsapi_login",
                redirect_uri: url,
                // state: "",
                style: "white",
                href: "",
                // href: "data:text/css;base64,LmxvZ2luUGFuZWwubm9ybWFsUGFuZWwgLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5xcmNvZGUubGlnaHRCb3JkZXIgew0KICB3aWR0aDogMTc0cHg7DQogIGhlaWdodDogMTc0cHg7DQogIG1hcmdpbi10b3A6IDA7DQogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7DQp9DQouaW1wb3dlckJveCAuaW5mbyB7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoud2ViX3FyY29kZV90eXBlX2lmcmFtZSB7DQogIHdpZHRoOiAxNzRweDsNCn0NCg=="
            });
        },
        checkLoginStatus() {
            // 发送请求检查登录状态
            axios.get('/api/checkLoginStatus')
                .then(response => {
                    // 处理登录状态检查结果
                })
                .catch(error => {
                    // 处理异常情况
                });
        },
    }
};
</script>

<style lang="scss" scoped>
.loginBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #33d0ff;
    overflow: hidden;
}
    #login_container {
        width: 500px;
        height: 500px;
    }


</style>
  